import React, { useState, useEffect } from 'react'
import {
    Users,
    Search,
    CheckCircle,
    Calendar,
    Star,
    UserPlus
} from 'lucide-react'

const Dashboard = () => {
    const [data, setData] = useState([
        { title: "Professionnels recrutés", value: 0, icon: <Users size={24} /> },
        { title: "Professionnels repérés", value: 0, icon: <Search size={24} /> },
        { title: "Recrutements confirmés", value: 0, icon: <CheckCircle size={24} /> },
        { title: "Entretiens planifiés", value: 0, icon: <Calendar size={24} /> },
        { title: "Note moyenne", value: "0/5", icon: <Star size={24} /> },
        { title: "Nouveaux inscrits", value: 0, icon: <UserPlus size={24} /> }
    ])

    return (
        <div className="md:p-8 h-screen bg-gray-100">
            <h1 className="pb-4 border-b text-2xl font-semibold">Tableau de bord</h1>
            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {data.map((d, index) => (
                    <div key={index} className="flex items-center p-4 bg-white rounded-lg shadow-md border hover:shadow-lg transition-shadow">
                        <div className="p-3 bg-[#bd193b] text-white rounded-lg">
                            {d.icon}
                        </div>
                        <div className="ml-4">
                            <h3 className="text-sm text-gray-600">{d.title}</h3>
                            <span className="text-xl font-semibold">{d.value}</span>
                        </div>
                    </div>
                ))}
            </section>
        </div>
    )
}

export default Dashboard