// Search.js - Version adaptée
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Helmet } from "react-helmet-async";
import { useSearchParams } from 'react-router-dom';
import FiltersColumn from '../../components/search/FiltersColumn';
import ResultsColumn from '../../components/search/ResultsColumn';
import MapColumn from '../../components/search/MapColumn';
import { filterBeauticians } from '../../data/searchUtils';
import { SearchContext } from '../../context/SearchContext';
import { AppContext } from '../../contexts/AppContext';
import './Search.css';
import { ChevronUp, ChevronDown } from 'lucide-react';

const Search = () => {
    const { beauticians, serviceCategories, priceRanges, isLoading } = useContext(SearchContext);
    const [searchParams] = useSearchParams();

    const [filteredBeauticians, setFilteredBeauticians] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedPrices, setSelectedPrices] = useState([]);
    const [minimumRating, setMinimumRating] = useState(0);
    const [radius, setRadius] = useState(5000);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedBeautician, setSelectedBeautician] = useState(null);

    const { setActivePageForHeader } = useContext(AppContext)

    const [filtersVisible, setFiltersVisible] = useState(window.innerWidth > 768);

    // Dans Search.js, au début du composant
    useEffect(() => {
        document.body.setAttribute('data-page', 'search');
        setActivePageForHeader("search");

        return () => {
            document.body.removeAttribute('data-page');
            setActivePageForHeader("");
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        setActivePageForHeader("search")

        return () => {
            setActivePageForHeader("")
        }
    }, [])

    // Initialisation des filtres depuis l'URL
    useEffect(() => {
        // Récupération de tous les paramètres possibles
        const query = searchParams.get('q');
        const categoryId = searchParams.get('categoryId');
        const subcategoryId = searchParams.get('subcategoryId');
        const lat = searchParams.get('lat');
        const lng = searchParams.get('lng');
        const beauticianId = searchParams.get('beautician');

        // Réinitialisation des filtres
        if (query) setSearchQuery(query);

        // Gestion des catégories et sous-catégories
        if (subcategoryId) {
            // Si on a une sous-catégorie, on utilise uniquement celle-ci
            setSelectedServices([parseInt(subcategoryId)]);
        } else if (categoryId && serviceCategories.length > 0) {
            // Sinon on utilise la catégorie principale
            setSelectedServices([parseInt(categoryId)]);
        }

        // Gestion de la localisation
        if (lat && lng) {
            setSelectedLocation({ lat: parseFloat(lat), lng: parseFloat(lng) });
        }

        // Gestion d'un beautician spécifique
        if (beauticianId) {
            const selectedBeautician = beauticians.find(b => b.id === parseInt(beauticianId));
            if (selectedBeautician) {
                setSelectedBeautician(selectedBeautician);
                if (selectedBeautician.latitude && selectedBeautician.longitude) {
                    setSelectedLocation({
                        lat: parseFloat(selectedBeautician.latitude),
                        lng: parseFloat(selectedBeautician.longitude)
                    });
                }
            }
        }
    }, [searchParams, serviceCategories, beauticians]);

    // Mise à jour des filtres
    useEffect(() => {
        // console.log('Raw beauticians:', beauticians);
        // console.log('Current filters:', {
        //     searchQuery,
        //     selectedServices,
        //     selectedPrices,
        //     minimumRating,
        //     radius,
        //     selectedLocation
        // });

        const filtered = filterBeauticians({
            searchTerm: searchQuery,
            services: selectedServices,
            priceRange: selectedPrices,
            rating: minimumRating,
            radius,
            location: selectedLocation,
            beauticians
        });

        // console.log('Filtered beauticians:', filtered);
        setFilteredBeauticians(filtered);
    }, [
        beauticians,
        searchQuery,
        selectedServices,
        selectedPrices,
        minimumRating,
        radius,
        selectedLocation
    ]);

    // Gestion du clic sur un marqueur
    const handleMarkerClick = useCallback((beautician) => {
        setSelectedBeautician(beautician);
    }, []);

    // Gérer le redimensionnement de la fenêtre
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setFiltersVisible(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleFilters = () => {
        setFiltersVisible(!filtersVisible);
    };    

    return (
        <>
            <Helmet>
                <title>Rechercher des prestataires beauté | PlaniBeauty</title>
                <meta name="description" content="Trouvez les meilleurs prestataires beauté près de chez vous. Filtrez par service, localisation et disponibilité." />
                <link rel="canonical" href="https://www.planibeauty.fr/search" />
                <meta property="og:title" content="Recherche de prestataires | PlaniBeauty" />
                <meta property="og:description" content="Découvrez les meilleurs professionnels de la beauté dans votre région." />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
                {/* Ajout du script JSON-LD */}
                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "url": "https://www.planibeauty.fr",
                            "potentialAction": {
                                "@type": "SearchAction",
                                "target": "https://www.planibeauty.fr/search?q={search_term_string}",
                                "query-input": "required name=search_term_string"
                            }
                        }
                    `}
                </script>
            </Helmet>
            <div className="search-page">
                <div className="search-container">
                    <FiltersColumn
                        className={`filters-column ${!filtersVisible ? 'hidden' : ''}`}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                        selectedPrices={selectedPrices}
                        setSelectedPrices={setSelectedPrices}
                        minimumRating={minimumRating}
                        setMinimumRating={setMinimumRating}
                        radius={radius}
                        setRadius={setRadius}
                        onLocationChange={setSelectedLocation}
                        serviceCategories={serviceCategories}
                        priceRanges={priceRanges}
                    />
                    <ResultsColumn
                        beauticians={filteredBeauticians}
                        selectedBeautician={selectedBeautician}
                        isLoading={isLoading}
                    />
                    <MapColumn
                        beauticians={filteredBeauticians}
                        selectedLocation={selectedLocation}
                        radius={radius}
                        onMarkerClick={handleMarkerClick}
                        selectedBeautician={selectedBeautician}
                    />
                </div>
                <button 
                className="toggle-filters-btn"
                onClick={toggleFilters}
                aria-label={filtersVisible ? "Masquer les filtres" : "Afficher les filtres"}
            >
                {filtersVisible ? (
                    <>
                        <ChevronUp size={20} />
                        <span>Masquer les filtres</span>
                    </>
                ) : (
                    <>
                        <ChevronDown size={20} />
                        <span>Afficher les filtres</span>
                    </>
                )}
            </button>
            </div>
        </>
    );
};

export default Search;