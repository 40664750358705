import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { BeauticianPortalContext } from "../../../../context/BeauticianPortalContext";
import appointmentApi from "../../../../api/appointement.api";

const ClientSelect = ({ action }) => {
    const [clients, setClients] = useState([]);
    const [oclients, setOclients] = useState([]);
    const { profileData } = useContext(BeauticianPortalContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (profileData) {
            fetchClients();
        }
    }, [profileData]);

    const fetchClients = async () => {
        setLoading(true);
        try {
            const res = await appointmentApi.getBeauticianClients(
                localStorage.getItem("token"),
                profileData.beautician_id
            );

            setOclients(res || []);

            // Transformer les clients en format pour react-select
            const formattedClients = res.map(client => ({
                value: client.unique_email || client.phone, // Identifiant unique
                label: `${client.first_name || "N/A"} ${client.last_name || "N/A"} - ${client.unique_email || client.phone || "N/A"}`
            }));

            setClients(formattedClients);
        } catch (error) {
            console.error("Erreur lors du chargement des clients :", error);
        }
        setLoading(false);
    };

    const handleChange = (e) => {
        let value = e.value;
        const c = oclients.find((o) => (o.unique_email === value || o.phone === value));
        action(c);
    }

    return (
        <Select
            options={clients}
            onChange={handleChange}
            isLoading={loading}
            placeholder="Sélectionner un client..."
            isSearchable
            noOptionsMessage={() => "Aucun client trouvé"}
        />
    );
};

export default ClientSelect;
