import { motion } from 'motion/react'
import React, { useEffect, useState } from 'react'
import MySpinner from "../../loader/Spinner";
import {
    Edit,
    Key,
    UserCircle,
    Mail,
    Phone,
    Cog,
    // XCircle,
    // Home,
    Eye,
} from "lucide-react"
import EditModal from './EditModal'
import userApi from '../../../api/user.api';
import ChangePasswordModal from './ChangePasswordModal';
import ChangeEmailModal from './ChangeEmailModal';
import NoAppointmentMessage from './NoAppointmentMessage';
// import classNames from 'classnames';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { formatTime } from '../../../utils/functions';
import appointmentApi from '../../../api/appointement.api';
import { 
    // Button, 
    // Form, 
    // Modal, 
    Table 
} from 'react-bootstrap';
// import { showErrorMessage, showSuccessMessage } from '../../../utils/messageHelper';
// import { sendEmailBookingStatusToBeautician } from '../../../utils/mailServices';
import ShowHistoryModal from './historique/ShowHistoryModal';

const Profile = () => {
    const [user, setUser] = useState({ email: "", first_name: "", last_name: "", phone: "" });
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    // const [showCancelModal, setShowCancelModal] = useState(false); // Gère la modale d'annulation
    // const [cancelReason, setCancelReason] = useState(''); // Stocke la raison de l'annulation
    // const [isSubmitting, setIsSubmitting] = useState(false); // Gère l'état de soumission
    // const [validationError, setValidationError] = useState(''); // Stocke les erreurs de validation

    const getUserInfo = async () => {
        setIsLoading(true);
        const user = await userApi.getProfile(localStorage.getItem('token'));
        if (user) {
            setUser(user);
        }

        setIsLoading(false);
    }

    const getAppointments = async () => {
        setIsLoading(true);
        const appointments = await appointmentApi.listAppointmentsValidByUserIdJoinBeauticianAndServices(localStorage.getItem('token'), localStorage.getItem('userId'));
        if (appointments) {
            setData(appointments);
        }
    }

    useEffect(() => {
        getUserInfo();
        getAppointments();
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [reloadTrigger]);

    // const cancelConfirmedAppointment = async (
    //     appointment_id,
    //     beautician_id,
    //     client_name,
    //     appointment_date,
    //     start_time,
    //     end_time,
    //     is_planified_appointment,
    //     user_id,
    //     services,
    //     payment_type,
    //     payment_intent,
    //     old_appointment_id
    // ) => {
    //     //pour l'instant on notifie l'utilisateur que le foncionnalité est en cours de développement
    //     // showInformationMessage("Cette fonctionnalité est en cours de développement! merci pour votre comprehension...");
    //     // return;

    //     if (!cancelReason.trim()) {
    //         setValidationError('Veuillez saisir une raison pour l\'annulation.');
    //         return;
    //     }

    //     setIsSubmitting(true);
    //     setValidationError('');

    //     try {

    //         const request = await appointmentApi.updateAppointmentStatus(
    //             localStorage.getItem('token'),
    //             appointment_id,
    //             {
    //                 status: 'cancelled',
    //                 reason: cancelReason,
    //                 // params pour savoir le type du rendez vous à annuler
    //                 is_planified_appointment: is_planified_appointment,
    //                 user_id: user_id,
    //                 services: services,
    //                 payment_type: payment_type,
    //                 payment_intent: payment_intent,
    //                 appointment_date: appointment_date,
    //                 parent_id: old_appointment_id,
    //                 is_from_client: true,
    //             }
    //         );

    //         sendEmailBookingStatusToBeautician(
    //             beautician_id,
    //             client_name,
    //             appointment_date,
    //             formatTime(start_time),
    //             formatTime(end_time),
    //             'cancel',
    //             true,
    //             cancelReason,
    //         );
    //         showSuccessMessage("Le rendez-vous a été annulé avec succès.");
    //         setReloadTrigger(prev => !prev);

    //     }
    //     catch (error) {
    //         showErrorMessage("Une erreur s'est produite lors de l'annulation du rendez-vous.");
    //     } finally {
    //         setIsSubmitting(false);
    //         setCancelReason('');
    //         setShowCancelModal(false);
    //     }
    // }

    const handleItemClick = async (appointment) => {
        setSelectedAppointment(appointment);
    }

    // const handleRedirect = (e, beautician_slug) => {
    //     if (!e.target.classList.contains("action"))
    //         window.location = window.location.origin + "/beauticians/" + beautician_slug
    // }

    // const statusLabels = {
    //     pending: "En attente",
    //     cancelled: "Annulé",
    //     confirmed: "Confirmé",
    //     completed: "Terminé",
    // };

    // const getStatusClass = (status) =>
    //     classNames("px-3 py-1 w-full h-full rounded border-2 text-sm", {
    //         "bg-gray-100 text-gray-400 border-gray-300": status === "completed",
    //         "bg-orange-100 text-orange-400 border-orange-300": status === "pending",
    //         "bg-green-100 text-green-400 border-green-300": status === "confirmed",
    //         "bg-red-100 text-red-400 border-red-300": status === "cancelled",
    //     });


    return (<React.Fragment>
        {isLoading ? <MySpinner height={"50vh"} /> :
            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: .25 }}
                className="w-full flex items-center justify-center sm:p-4 p-0"
            >
                <aside className="bg-white/80 backdrop-blur-sm p-2 sm:p-8 w-full flex flex-wrap rounded-none sm:rounded-2xl shadow-none sm:shadow-lg">

                    <div className="flex flex-col w-full md:w-1/2 items-center gap-2">

                        <div className="bg-gradient-to-r from-[#bd193b] to-pink-500 p-1 rounded-full">
                            <div className="bg-white p-2 rounded-full">
                                <UserCircle size={80} className="mb-2 text-[#bd193b] size-20 md:size-100" />
                            </div>
                        </div>

                        <h2 className="text-gray-800 text-2xl sm:text-3xl font-semibold tracking-wide text-center">{`${user.last_name} ${user.first_name}`}</h2>
                        <div className="space-y-4 w-full max-w-sm">
                            <div className="flex items-center justify-center gap-3">
                                <Mail className="w-5 h-5 text-[#bd193b]" />
                                <span className="text-gray-600 text-lg sm:text-xl font-light">{user.email}</span>
                            </div>
                            <div className="flex items-center justify-center gap-3">
                                <Phone className="w-5 h-5 text-[#bd193b]" />
                                <span className="text-gray-600 text-lg sm:text-xl font-light">{user.phone}</span>
                            </div>
                        </div>

                        <div className="w-full border-t border-gray-100 my-4"></div>

                        <div className="flex flex-col gap-4 w-full max-w-sm">
                            <button
                                onClick={() => setOpenModal(true)}
                                className="flex items-center justify-center gap-3 px-6 py-3 bg-white text-[#bd193b] rounded-xl shadow-sm border border-gray-100 transition-all hover:shadow-md hover:scale-[1.02]"
                            >
                                <Edit className="w-5 h-5" /> Modifier mes informations
                            </button>
                            <button
                                onClick={() => setOpenChangeEmailModal(true)}
                                className="flex items-center justify-center gap-3 px-6 py-3 bg-white text-[#bd193b] rounded-xl shadow-sm border border-gray-100 transition-all hover:shadow-md hover:scale-[1.02]"
                            >
                                <Cog className="w-5 h-5" /> Modifier mon adresse email
                            </button>
                            <button
                                onClick={() => setOpenChangePasswordModal(true)}
                                className="flex items-center justify-center gap-3 px-6 py-3 bg-white text-[#bd193b] rounded-xl shadow-sm border border-gray-100 transition-all hover:shadow-md hover:scale-[1.02]"
                            >
                                <Key className="w-5 h-5" /> Modifier mon mot de passe
                            </button>
                        </div>
                    </div>

                    <div className='w-full md:w-1/2 max-h-[80vh] mt-5 sm:mt-0 min-h-[60vh] sm:min-h-[80vh] overflow-y-scroll'>
                        <h3 className='text-center'>Mes prochains rendez-vous</h3>
                        {data.length > 0 ? (
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th className="text-center">Pro</th>
                                        <th className="text-center">Date/heure</th>
                                        {/* <th className="text-center">Prestation</th> */}
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="overflow-auto">
                                    {data.map((appointment, index) => {
                                        if (appointment.status !== "confirmed") return null;

                                        return (
                                            <tr key={index}>
                                                <td>{appointment.business_name || `${appointment.first_name} ${appointment.last_name}`}</td>
                                                <td>
                                                    {format(
                                                        new Date(appointment.appointment_date),
                                                        "EEEE d MMMM yyyy",
                                                        { locale: fr }
                                                    )} à {formatTime(appointment.start_time)}
                                                </td>
                                                {/* <td>{appointment.services.map((service, index) => (<span key={index}>{service.subcategory_name}, </span>))}</td> */}
                                                <td>
                                                    <div className="action flex justify-center items-center gap-2">
                                                        <button
                                                            onClick={() => handleItemClick(appointment)}
                                                            title="Voir détails"
                                                            className="bg-blue-600 cursor-pointer text-gray-100 text-sm hover:bg-blue-700 px-2 py-1 rounded flex items-center gap-1"
                                                        >
                                                            <Eye size={16} /> Détails
                                                        </button>

                                                        {/* <button
                                                            onClick={(e) => handleRedirect(e, appointment.slug)}
                                                            title="Voir l'établissement"
                                                            className="bg-gray-700 cursor-pointer text-white text-sm hover:bg-gray-800 px-2 py-1 rounded flex items-center gap-1"
                                                        >
                                                            <Home size={16} /> Établissement
                                                        </button>

                                                        {appointment.status === "confirmed" && (
                                                            <button
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowCancelModal(true);
                                                                }}
                                                                title="Annuler le rendez-vous"
                                                                className="bg-red-500 text-white text-sm cursor-pointer hover:bg-red-700 px-2 py-1 rounded flex items-center gap-1"
                                                            >
                                                                <XCircle size={16} /> Annuler
                                                            </button>
                                                        )} */}
                                                    </div>
                                                </td>
                                                {/* Modale d'annulation */}
                                                {/* <Modal
                                                    show={showCancelModal}
                                                    onHide={() => {
                                                        setCancelReason('');
                                                        setShowCancelModal(false);
                                                    }}
                                                    centered
                                                    backdropClassName="secondary-backdrop"
                                                    style={{ zIndex: 1051 }}
                                                >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Annuler le rendez-vous</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <h5>{!appointment.is_planified_appointment ?
                                                            "Rendez vous classique" :
                                                            (appointment.user_id ?
                                                                "Rendez-vous multiple planifié" :
                                                                "Rendez-vous planifié manuellement")
                                                        }</h5>
                                                        {!appointment.is_planified_appointment ?
                                                            <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous classique, si il y a un service à multiple rendez-vous, tout les prochains rendez-vous planifiés seront annulés aussi!</p> :
                                                            (appointment.user_id ?
                                                                <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous multiple planifié, tous les prochains rendez-vous y compris le rendez-vous en question seront annulés!</p> :
                                                                <p className="text-yellow-500">NB: Pour l'annulation d'un rendez-vous manuel, le rendez-vous sera supprimé de votre agenda!</p>
                                                            )
                                                        }
                                                        {appointment.payment_type !== "onsite" && <p className="text-yellow-500">Vous serez remboursé à 90% de la somme!</p>}
                                                        <p>L'action est irréversible. Veuillez préciser la raison de l'annulation :</p>
                                                        <Form>
                                                            <Form.Group controlId="cancelReason">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={4}
                                                                    style={{ resize: 'none', minHeight: '100px' }}
                                                                    placeholder="Saisissez la raison ici..."
                                                                    value={cancelReason}
                                                                    onChange={(e) => setCancelReason(e.target.value)}
                                                                    disabled={isSubmitting}
                                                                />
                                                            </Form.Group>
                                                            {validationError && (
                                                                <div style={{ color: 'red', marginTop: '10px', fontSize: '14px' }}>
                                                                    {validationError}
                                                                </div>
                                                            )}
                                                        </Form>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            variant="danger"
                                                            onClick={() => {
                                                                cancelConfirmedAppointment(
                                                                    appointment.id,
                                                                    appointment.beautician_id,
                                                                    `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`,
                                                                    appointment.appointment_date,
                                                                    appointment.start_time,
                                                                    appointment.end_time,
                                                                    appointment.is_planified_appointment,
                                                                    appointment.user_id,
                                                                    appointment.services,
                                                                    appointment.payment_type,
                                                                    appointment.payment_intent,
                                                                    appointment.appointment_id
                                                                );
                                                            }}
                                                            disabled={isSubmitting}
                                                        >
                                                            {isSubmitting ? 'Annulation...' : 'Confirmer l\'annulation'}
                                                        </Button>
                                                        <Button
                                                            variant="secondary"
                                                            onClick={() => {
                                                                setCancelReason('');
                                                                setShowCancelModal(false);
                                                            }}
                                                            disabled={isSubmitting}
                                                        >
                                                            Annuler
                                                        </Button>
                                                    </Modal.Footer>
                                                </Modal> */}
                                            </tr>
                                        );
                                    })}

                                    {!data.some((o) => o.status === 'confirmed') && (
                                        <tr>
                                            <td colSpan="3" className="text-center">Aucun rendez-vous à venir</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        ) : (
                            <NoAppointmentMessage />
                        )}
                    </div>
                </aside>

                <EditModal
                    openModal={openModal}
                    user={user}
                    setOpenModal={setOpenModal}
                    setReloadTrigger={setReloadTrigger}
                />

                <ChangePasswordModal
                    openModal={openChangePasswordModal}
                    setOpenModal={setOpenChangePasswordModal}
                />

                <ChangeEmailModal
                    openModal={openChangeEmailModal}
                    setOpenModal={setOpenChangeEmailModal}
                    setReloadTrigger={setReloadTrigger}
                />

                {/* Modal */}
                {selectedAppointment && (
                    <ShowHistoryModal
                        appointment={selectedAppointment}
                        onClose={() => setSelectedAppointment(null)}
                    />
                )}

            </motion.section>}
    </React.Fragment>
    )
}

export default Profile
