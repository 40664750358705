import React, { useContext } from "react";
import BlockedDay from "../../../dashboard/siteInfoAgenda/BlockedDay";
import OpenTime from "../../../dashboard/siteInfoAgenda/OpenTime";
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';
import ListeCollaborateurs from "./availability/ListeCollaborateurs";


const AvailabilitySettings = () => {

    const { profileData } = useContext(BeauticianPortalContext);

    return <div className="p-2 sm:p-4 full">
        {profileData.type_beautician === 'établissement' && <>
            <ListeCollaborateurs beautician_id={profileData.beautician_id} />
            <hr />
        </>}
        <OpenTime activeID={profileData.beautician_id} />
        <hr />
        <BlockedDay activeID={profileData.beautician_id} />
    </div>
}

export default AvailabilitySettings;