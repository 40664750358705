// src/api/user.api.js
import { API_URL } from "../utils/constants";


const userApi = {
    register: async (userData) => {
        try {
            const response = await fetch(`${API_URL}/users/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de l\'inscription');
        }
    },

    login: async (credentials) => {
        try {
            const response = await fetch(`${API_URL}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(credentials)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la connexion');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur de connexion:', error);
            throw error;
        }
    },

    getProfile: async (token) => {
        try {
            const response = await fetch(`${API_URL}/users/profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du profil');
        }
    },

    getUserById: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/${user_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'utilisateur');
        }
    },

    getUserPreferences: async(token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/preferences/${user_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors du récupération du préférence de l\'utilisateur');
        }
    },

    createUserPreferences: async(token, user_id, beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/users/preferences`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id,
                    beautician_id
                })
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de l\'enregistrement du préférence');
        }
    },

    deleteUserPreferences: async(token, user_id, beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/users/preferences`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id,
                    beautician_id
                })
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la supression du préférence');
        }
    },

    updateProfile: async (token, userData) => {
        try {
            const response = await fetch(`${API_URL}/users/profile`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du profil');
        }
    },

    changeEmailRequest: async (token, emailData) => {
        const response = await fetch(`${API_URL}/users/change-email-request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(emailData),
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Une erreur s\'est produite');
        }

        return result;
    },
    
    verifyChangeEmailCode: async (token, userId, code) => {
        const response = await fetch(`${API_URL}/users/verify-change-email-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({userId: userId, code: code}),
        });
        const result = await response.json();
        if (!response.ok) {
            throw new Error(result.message || 'Une erreur s\'est produite');
        }
        return result;
    },

    changePassword: async (token, passwordData) => {
        try {
            const response = await fetch(`${API_URL}/users/change-password`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(passwordData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors du changement de mot de passe');
        }
    },

    deleteAccount: async (user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/${user_id}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression du compte');
        }
    },

    createBeauticianUser: async (userData) => {
        try {
            const response = await fetch(`${API_URL}/users/create-beautician`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création de l\'utilisateur beautician');
        }
    },
    
    getAllUsers: async (token) => {
        try {
            const response = await fetch(`${API_URL}/users/all`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des utilisateurs');
        }
    },

    selectUsersByType: async (token, type) => {
        try {
            const response = await fetch(`${API_URL}/users/get-by-type?type=${encodeURIComponent(type)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des utilisateurs');
        }
    },

    countUserByType: async (token) => {
        try {
            const response = await fetch(`${API_URL}/users/count-user-by-type`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des nombres utilisateurs');
        }
    },

    fetchBeauticianUserByUserId: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/${user_id}/beautician`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'utilisateur');
        }
    },

    fetchTeleConseillerUserByUserId: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/${user_id}/tele-conseiller`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'utilisateur');
        }
    },

    registerTemporaryUser: async (userData) => {
        try {
            const response = await fetch(`${API_URL}/users/create-temporary-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de l\'inscription');
        }
    },

    changeAccountStatus: async (token, status, user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/${user_id}/update-account-status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({status: status})
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du profil');
        }
    }, 

    acceptTerms: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/users/${user_id}/accept-terms`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du profil');
        }
    }, 
};

export default userApi;