import { Pencil, Trash } from "lucide-react";
import React, {useState, useEffect} from "react";
import employeeApi from '../../../../../api/employees.api.js';
import CreateEditCollaboratorModal from "./CreateEditCollaboratorModal.jsx";
import { showErrorMessage, showSuccessMessage } from "../../../../../utils/messageHelper.js";
import Swal from "sweetalert2";


const ListeCollaborateurs = ({beautician_id}) => {

    const [collaborateurs, setCollaborateurs] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCollaboarator, setSelectedCollaboarator] = useState(null);

    useEffect(() => {
        const fetchCollaborateurs = async () => {
            try {
                const res = await employeeApi.getAllEmployeesByBeauticianId(beautician_id);
                setCollaborateurs(res.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des collaborateurs : ', error);
                showErrorMessage('Erreur lors de la récupération des collaborateurs');
            }
        };
        fetchCollaborateurs();
    }, []);

    const handleSave = async (collaborator, isCreate) => {
        try {
            if(isCreate) {
                const res = await employeeApi.create(localStorage.getItem('token'), collaborator);
                setCollaborateurs([...collaborateurs, res.data]);
                showSuccessMessage('Collaborateur créé avec succès');
            } else {
                const res = await employeeApi.update(localStorage.getItem('token'), collaborator.id, collaborator);
                const updatedCollaborateurs = collaborateurs.map(c => c.id === res.data.id ? res.data : c);
                setCollaborateurs(updatedCollaborateurs);
                showSuccessMessage('Collaborateur mis à jour avec succès');
            }
        }
        catch (error) {
            console.error('Erreur lors de la sauvegarde du collaborateur : ', error);
            showErrorMessage('Erreur lors de la sauvegarde du collaborateur');
        }
    }

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Vous ne pourrez pas revenir en arrière!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await employeeApi.delete(localStorage.getItem('token'), id);
                    const updatedCollaborateurs = collaborateurs.filter(c => c.id !== id);
                    setCollaborateurs(updatedCollaborateurs);
                    showSuccessMessage('Collaborateur supprimé avec succès');
                } catch (error) {
                    console.error('Erreur lors de la suppression du collaborateur : ', error);
                    showErrorMessage('Erreur lors de la suppression du collaborateur');
                }
            }
        });
    }

    return <div className="">
        <div className="flex justify-between items-center mb-2">
            <h4>Listes des collaboraeturs</h4>
            <button 
            className="btn btn-primary"
            onClick={() => {
                setSelectedCollaboarator(null);
                setShowModal(true);
            }}
            >Ajouter collaboraetur</button>
        </div>
        <div className="flex justify-between">
            <div className="">
                <div className="flex gap-2 flew-wrap">
                    {collaborateurs.map(collaborateur => {
                        return <div key={collaborateur.id} className="p-2 rounded-lg bg-gray-200 flex gap-2 items-center">
                            <span>{collaborateur.name}</span>
                            <button 
                            className="btn btn-warning btn-sm"
                            onClick={() => {
                                setSelectedCollaboarator(collaborateur);
                                setShowModal(true);
                            }}
                            ><Pencil size={14} /></button>
                            <button 
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(collaborateur.id)}
                            ><Trash size={14} /></button>
                        </div>
                    })}
                    {collaborateurs.length === 0 && <div className="text-center">Aucun collaborateur trouvé</div>}
                </div>
            </div>
        </div>
        {showModal && <CreateEditCollaboratorModal 
        show={showModal} 
        handleClose={() => setShowModal(false)} 
        collaborator={selectedCollaboarator} 
        onSave={handleSave}
        beautician_id={beautician_id}
        />}
    </div>
}

export default ListeCollaborateurs;