import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CreateEditCollaboratorModal = ({ show, handleClose, collaborator, onSave, beautician_id }) => {
    const [name, setName] = useState(collaborator ? collaborator.name : '');

    const handleSave = () => {
        const updatedCollaborator = { id: collaborator?.id || null, name: name, beautician_id: beautician_id };
        onSave(updatedCollaborator, collaborator ? false : true);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{collaborator ? 'Edit Collaborator' : 'Create Collaborator'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrer le nom"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Enregistrer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateEditCollaboratorModal;