import React, { useEffect, useState } from 'react';
import {
  User,
  Shield,
  ChevronRight,
  X,
  ArrowRight,
} from 'lucide-react';
import './TeleConseillerSettings.css';
import SecuritySettings from './settings/SecuritySettings';
import ProfileSettings from './settings/ProfileSettings';
import { useLocation, useNavigate } from 'react-router-dom';

const TeleConseillerSettings = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('profile');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const addQueryParam = (key, value) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
  };

  const handleClickSettingSection = (section) => {
    setActiveSection(section);
    addQueryParam('active', section);
    setIsMenuOpen(false);
  }

  const handleShowMenu = () => {
    setIsMenuOpen(true);
  }

  const handleHideMenu = () => {
    setIsMenuOpen(false);
  }

  useEffect(() => {
    if (location.search) {
      let params_array = location.search.split("=");
      if (params_array.length >= 2) {
        setActiveSection(params_array[1]);
        addQueryParam('active', params_array[1]);
      }
    }
  }, []);

  const settingsSections = [
      {
        id: 'profile',
        title: 'Gérer les informations',
        icon: <User size={20} />,
        description: 'Gérez vos informations personnelles et professionnelles'
      },
      {
        id: 'security',
        title: 'Sécurité et confidentialité',
        icon: <Shield size={20} />,
        description: 'Gérez la sécurité de votre compte'
      }
    ];

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'profile':
        return (
          <ProfileSettings />
        );

      case 'security':
        return (
          <SecuritySettings />
        );
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      {/* Navigation des paramètres */}
      {!isMenuOpen &&
        <button
          onClick={handleShowMenu}
          className="show-settings-menu-button py-3 px-2 bg-red-200 rounded-lg hover:bg-red-300">
          <ArrowRight size={20} />
        </button>
      }
      <div className={`settings-navigation ${isMenuOpen ? 'settings-navigation-show' : 'settings-navigation-hidden'}`}>
        <div className='hide-settings-menu-button w-full px-3 flex justify-end mb-2'>
          <button
            onClick={handleHideMenu}
            className='py-2 px-3 bg-gray-100 rounded hover:bg-gray-200'><X size={20} /></button>
        </div>
        {settingsSections.map((section) => (
          <button
            key={section.id}
            className={`settings-nav-item ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => handleClickSettingSection(section.id)}
          >
            <div className="nav-item-icon">{section.icon}</div>
            <div className="nav-item-content">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
            <ChevronRight size={20} className="nav-item-arrow" />
          </button>
        ))}
      </div>

      {/* Contenu des paramètres */}
      <div className="settings-content">
        <div className="settings-header">
          <h2>{settingsSections.find(s => s.id === activeSection)?.title}</h2>
        </div>
        {renderSettingsContent()}
      </div>
    </div>
  );
};

export default TeleConseillerSettings;