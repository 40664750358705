import React, { useState, useContext, useEffect } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import availabilityApi from "../../../api/availability.api";
import { FaPencil } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";
import { formatTime } from "../../../utils/functions";


const OpenTime = ({ activeID, is_modal = false }) => {

    const [schedule, setSchedule] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [applyModal, setApplyModal] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedDaysToApply, setSelectedDaysToApply] = useState([]);

    // Liste complète des jours de la semaine
    const defaultDays = [
        { day: "Lun.", field: "monday" },
        { day: "Mar.", field: "tuesday" },
        { day: "Mer.", field: "wednesday" },
        { day: "Jeu.", field: "thursday" },
        { day: "Ven.", field: "friday" },
        { day: "Sam.", field: "saturday" },
        { day: "Dim.", field: "sunday" },
    ];

    // Charger les disponibilités depuis la base de données
    const fetchAvailabilities = async () => {
        try {
            const response = await availabilityApi.getBeauticianAvailability(activeID);
            if (response && Array.isArray(response)) {
                // Fusionner les données de la base avec les jours par défaut
                const mergedSchedule = defaultDays.map((defaultDay) => {
                    const existingData = response.find(
                        (item) => item.day.toLowerCase() === defaultDay.field
                    );
                    return {
                        ...defaultDay,
                        id: existingData?.id || null,
                        start_time: existingData?.start_time || "-",
                        break_start: existingData?.break_start || "-",
                        break_end: existingData?.break_end || "-",
                        end_time: existingData?.end_time || "-",
                    };
                });
                setSchedule(mergedSchedule);
            } else {
                setSchedule(defaultDays.map((day) => ({
                    ...day,
                    id: null,
                    start_time: "-",
                    break_start: "-",
                    break_end: "-",
                    end_time: "-",
                })));
            }
        } catch (error) {
            console.log(error);
            Swal.fire("Erreur", "Impossible de charger les disponibilités.", "error");
        }
    };

    useEffect(() => {
        fetchAvailabilities();
    }, [activeID]);

    const handleOpenModal = (dayIndex) => {
        const day = schedule[dayIndex];
        setSelectedDay({ ...day, index: dayIndex });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedDay(null);
    };

    const handleInputChange = (field, value) => {
        if (selectedDay) {
            setSelectedDay({ ...selectedDay, [field]: value });
        }
    };

    const handleApplyToOtherDays = () => {
        if (selectedDay && validateSchedule()) {
            setApplyModal(true);
        }

    };

    const handleSaveApplyModal = async () => {
        try {
            // Boucle sur les jours sélectionnés pour appliquer les modifications
            for (const field of [...selectedDaysToApply, selectedDay.field]) {
                const dayToUpdate = schedule.find((item) => item.field === field);

                if (dayToUpdate) {
                    const data = {
                        beautician_id: activeID,
                        day: field.toLowerCase(),
                        start_time: selectedDay.start_time === "-" ? null : selectedDay.start_time,
                        end_time: selectedDay.end_time === "-" ? null : selectedDay.end_time,
                        break_start: selectedDay.break_start === "-" ? null : selectedDay.break_start,
                        break_end: selectedDay.break_end === "-" ? null : selectedDay.break_end,
                    };

                    // Création ou mise à jour dans l'API
                    if (dayToUpdate.id) {
                        // Si un ID existe, mise à jour
                        const response = await availabilityApi.updateAvailability(localStorage.getItem('token'), dayToUpdate.id, data);
                        if (!response.error) {
                            dayToUpdate.start_time = selectedDay.start_time;
                            dayToUpdate.end_time = selectedDay.end_time;
                            dayToUpdate.break_start = selectedDay.break_start;
                            dayToUpdate.break_end = selectedDay.break_end;
                        } else {
                            Swal.fire("Erreur", `Échec de la mise à jour pour ${dayToUpdate.day}`, "error");
                        }
                    } else {
                        // Sinon, création
                        const response = await availabilityApi.createAvailability(localStorage.getItem('token'), data);
                        if (!response.error && response.id) {
                            dayToUpdate.id = response.id;
                            dayToUpdate.start_time = selectedDay.start_time;
                            dayToUpdate.end_time = selectedDay.end_time;
                            dayToUpdate.break_start = selectedDay.break_start;
                            dayToUpdate.break_end = selectedDay.break_end;
                        } else {
                            Swal.fire("Erreur", `Échec de la création pour ${dayToUpdate.day}`, "error");
                        }
                    }
                }
            }

            // Mise à jour de l'état local
            setSchedule((prevSchedule) =>
                prevSchedule.map((item) => {
                    if (selectedDaysToApply.includes(item.field) || item.field === selectedDay.field) {
                        return {
                            ...item,
                            start_time: selectedDay.start_time,
                            break_start: selectedDay.break_start,
                            break_end: selectedDay.break_end,
                            end_time: selectedDay.end_time,
                        };
                    }
                    return item;
                })
            );

            // Réinitialiser les sélections et fermer les modales
            setApplyModal(false);
            handleCloseModal();
            setSelectedDaysToApply([]);
            Swal.fire("Succès", "Les horaires ont été appliqués avec succès.", "success");
        } catch (error) {
            Swal.fire("Erreur", "Une erreur s'est produite lors de l'application des horaires.", "error");
            console.error(error);
        }
    };


    const handleSelectDayToApply = (field) => {
        setSelectedDaysToApply((prevSelected) =>
            prevSelected.includes(field)
                ? prevSelected.filter((item) => item !== field)
                : [...prevSelected, field]
        );
    };


    const validateSchedule = () => {
        const { start_time, break_start, break_end, end_time } = selectedDay;

        // Vérification des champs obligatoires
        if (start_time === "-" || end_time === "-") {
            Swal.fire("Erreur", "Les champs Ouverture et Fermeture doivent être remplis.", "error");
            return false;
        }

        // Vérification de l'ordre ouverture < fermeture
        if (start_time >= end_time) {
            Swal.fire("Erreur", "L'heure d'ouverture doit être avant l'heure de fermeture.", "error");
            return false;
        }

        // Vérification de l'ordre ouverture < pause-début
        if (break_start !== "-" && start_time >= break_start) {
            Swal.fire("Erreur", "L'heure d'ouverture doit être avant le début de la pause.", "error");
            return false;
        }

        // Vérification des pauses : si l'un est défini, l'autre doit l'être
        if ((break_start !== "-" && break_end === "-") || (break_start === "-" && break_end !== "-")) {
            Swal.fire("Erreur", "Si l'heure de début ou de fin de pause est définie, les deux doivent être remplies.", "error");
            return false;
        }

        // Vérification de l'ordre pause-début < pause-fin
        if (break_start !== "-" && break_end !== "-" && break_start >= break_end) {
            Swal.fire("Erreur", "Le début de la pause doit être avant la fin de la pause.", "error");
            return false;
        }

        // Vérification de l'ordre pause-fin < fermeture
        if (break_end !== "-" && break_end >= end_time) {
            Swal.fire("Erreur", "La fin de la pause doit être avant la fermeture.", "error");
            return false;
        }

        return true;
    };

    const handleSave = async () => {
        if (selectedDay && validateSchedule()) {
            const data = {
                beautician_id: activeID,
                day: selectedDay.field.toLowerCase(),
                start_time: selectedDay.start_time === "-" ? null : selectedDay.start_time,
                end_time: selectedDay.end_time === "-" ? null : selectedDay.end_time,
                break_start: selectedDay.break_start === "-" ? null : selectedDay.break_start,
                break_end: selectedDay.break_end === "-" ? null : selectedDay.break_end,
            };

            createOrUpdate(data);

        }
    };

    const createOrUpdate = async (data) => {
        try {
            const token = localStorage.getItem('token');

            // Vérification si la disponibilité existe déjà
            const checkResponse = await availabilityApi.verifyIfExist(token, activeID, selectedDay.field.toLowerCase());

            if (checkResponse.exists) {
                // Mise à jour si elle existe
                const updateResponse = await availabilityApi.updateAvailability(token, checkResponse.id, data);
                if (!updateResponse.error) {
                    // Mettre à jour le tableau local après la modification
                    setSchedule((prevSchedule) =>
                        prevSchedule.map((item) =>
                            item.field === selectedDay.field
                                ? { ...item, ...selectedDay } // Remplacer l'élément modifié
                                : item
                        )
                    );
                    Swal.fire("Succès", "Les horaires ont été mis à jour avec succès !", "success");
                } else {
                    Swal.fire("Erreur", updateResponse.error, "error");
                }
            } else {
                // Création si elle n'existe pas
                const createResponse = await availabilityApi.createAvailability(token, data);
                if (!createResponse.error) {
                    // Ajouter un nouvel élément au tableau après la création
                    setSchedule((prevSchedule) => {
                        // S'assurer de ne pas ajouter une nouvelle ligne si l'élément existe déjà
                        const existingIndex = prevSchedule.findIndex(item => item.field === selectedDay.field);
                        if (existingIndex !== -1) {
                            const updatedSchedule = [...prevSchedule];
                            updatedSchedule[existingIndex] = {
                                ...selectedDay,
                                id: createResponse.id,
                                start_time: selectedDay.start_time,
                                break_start: selectedDay.break_start,
                                break_end: selectedDay.break_end,
                                end_time: selectedDay.end_time,
                            };
                            return updatedSchedule;
                        } else {
                            return [
                                ...prevSchedule,
                                {
                                    id: createResponse.id,
                                    day: selectedDay.day,
                                    field: selectedDay.field,
                                    start_time: selectedDay.start_time,
                                    break_start: selectedDay.break_start,
                                    break_end: selectedDay.break_end,
                                    end_time: selectedDay.end_time,
                                },
                            ];
                        }
                    });
                    Swal.fire("Succès", "Les horaires ont été ajoutés avec succès !", "success");
                } else {
                    Swal.fire("Erreur", createResponse.error, "error");
                }
            }

            handleCloseModal(); // Fermer la modale après la sauvegarde
        } catch (error) {
            Swal.fire("Erreur", "Impossible de sauvegarder les horaires. Veuillez réessayer.", "error");
        }
    }

    const handleDelete = async (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Annuler",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await availabilityApi.deleteAvailability(localStorage.getItem('token'), id);
                    if (!response.error) {
                        // Mettre à jour le tableau local après la modification
                        setSchedule((prevSchedule) =>
                            prevSchedule.map((item) =>
                                item.id === id
                                    ? { ...item, start_time: '-', end_time: '-', break_start: '-', break_end: '-', id: null } // Remplacer l'élément modifié
                                    : item
                            )
                        );

                        Swal.fire("Succès", "Disponibilité supprimé avec succès.", "success");
                    }
                } catch (error) {
                    console.log(error);
                    Swal.fire("Erreur", "Impossible de supprimer la disponibilité.", "error");
                }
            }
        });
    };

    return <React.Fragment>
        {!is_modal && <h4 className="text-xl sm:text-2xl">Heures d'ouvertures hebdomadaires</h4>}
        <div className="w-full overflow-x-scroll">
            <Table>
                <thead>
                    <tr>
                        <th>Jour</th>
                        <th className="text-center">Ouv.</th>
                        <th className="text-center">Pause dej.</th>
                        <th className="text-center">Ferm.</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schedule.map((item, index) => (
                        <tr key={index}>
                            <td>{item.day}</td>
                            <td className="text-center">{(item.start_time && item.start_time !== "-") ? formatTime(item.start_time) : "-"}</td>
                            <td className="text-center">
                                {(item.break_start && item.break_start !== "-") ? formatTime(item.break_start) : "-"}
                                {" "}à{" "}
                                {(item.break_end && item.break_end !== "-") ? formatTime(item.break_end) : "-"}
                            </td>
                            <td className="text-center">{(item.end_time && item.end_time !== "-") ? formatTime(item.end_time) : "-"}</td>
                            <td className="flex justify-center gap-2 items-center">
                                <Button
                                    variant="warning"
                                    size="sm"
                                    onClick={() => handleOpenModal(index)}
                                >
                                    <FaPencil />
                                </Button>
                                {item.id &&
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => handleDelete(item.id)}
                                    >
                                        <FaTrash />
                                    </Button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

        {/* Modal */}
        {showModal &&
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modifier les horaires - {selectedDay?.day}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-y-scroll">
                    <label>
                        Copier les horaires d’un jour existant :
                        <select
                            className="form-select"
                            onChange={(e) => {
                                const copiedDay = schedule.find((item) => item.field === e.target.value);
                                if (copiedDay) {
                                    setSelectedDay({
                                        ...selectedDay,
                                        start_time: copiedDay.start_time,
                                        break_start: copiedDay.break_start,
                                        break_end: copiedDay.break_end,
                                        end_time: copiedDay.end_time,
                                    });
                                }
                            }}
                            defaultValue=""
                        >
                            <option value="" disabled>
                                Sélectionnez un jour
                            </option>
                            {schedule.map((item) => {
                                if (item.start_time !== "-" && item.end_time !== '-' && item.day !== selectedDay?.day) {
                                    return <option key={item.field} value={item.field}>
                                        {item.day}
                                    </option>;
                                }
                            })}
                        </select>
                    </label>
                    {selectedDay && (
                        <div className="flex flex-col gap-3">
                            <label>
                                Ouverture :
                                <input
                                    type="time"
                                    className="form-control"
                                    value={selectedDay.start_time === "-" ? "" : selectedDay.start_time}
                                    onChange={(e) => handleInputChange("start_time", e.target.value || "-")}
                                />
                            </label>
                            <label>
                                Début de pause :
                                <input
                                    type="time"
                                    className="form-control"
                                    value={selectedDay.break_start === "-" ? "" : selectedDay.break_start}
                                    onChange={(e) => handleInputChange("break_start", e.target.value || "-")}
                                />
                            </label>
                            <label>
                                Fin de pause :
                                <input
                                    type="time"
                                    className="form-control"
                                    value={selectedDay.break_end === "-" ? "" : selectedDay.break_end}
                                    onChange={(e) => handleInputChange("break_end", e.target.value || "-")}
                                />
                            </label>
                            <label>
                                Fermeture :
                                <input
                                    type="time"
                                    className="form-control"
                                    value={selectedDay.end_time === "-" ? "" : selectedDay.end_time}
                                    onChange={(e) => handleInputChange("end_time", e.target.value || "-")}
                                />
                            </label>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-full flex sm:flex-row flex-col-reverse items-center justify-between gap-2">

                        <Button variant="secondary" className="w-full" onClick={handleCloseModal}>
                            Annuler
                        </Button>
                        <Button variant="outline-primary" className="w-full" onClick={handleApplyToOtherDays}>
                            Appliquer à plusieurs
                        </Button>
                        <Button variant="primary" className="w-full" onClick={handleSave}>
                            Enregistrer
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>}

        {/* Modal pour appliquer aux autres jours */}
        {applyModal &&
            <Modal show={applyModal} onHide={() => setApplyModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Appliquer à d'autres jours</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-col gap-y-3">
                        {schedule.map((item) => (
                            <div key={item.field}>
                                <label>
                                    <input
                                        type="checkbox"
                                        //checked={selectedDay?.field === item?.field}
                                        checked={selectedDaysToApply.includes(item.field)}
                                        onChange={() => handleSelectDayToApply(item.field)}
                                    />{"  "}
                                    {item.day}
                                </label>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setApplyModal(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSaveApplyModal}>
                        Appliquer
                    </Button>
                </Modal.Footer>
            </Modal>
        }

    </React.Fragment>


}

export default OpenTime;