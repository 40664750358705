import React from 'react';
import SearchBar from '../searchbar/SearchBar';
import heroImage from '../../images/hero/hero.png';
import './Hero.css';

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <div className="hero-background" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="hero-overlay"></div>
      </div>
      <section className="hero">
        <div className="container position-relative">
            <div className="col-lg-12 col-xl-10">
              <div className="hero-content">
                <h1 className="display-4 text-center fw-bold mb-4">
                  Trouvez votre perle de beauté …
                </h1>
                <p className="lead text-center mb-4">
                  Découvrez et prenez rendez-vous avec nos partenaires de confiance:
                </p>
                <SearchBar />
              </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;