import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BeauticianPortalContext } from "../../../../context/BeauticianPortalContext";
import appointmentApi from "../../../../api/appointement.api";

const ShowListClientsModal = ({ show, onHide }) => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [search, setSearch] = useState("");
    const { profileData } = useContext(BeauticianPortalContext);

    useEffect(() => {
        if (profileData) {
            fetchClients();
        }
    }, [profileData]);

    const fetchClients = async () => {
        try {
            const res = await appointmentApi.getBeauticianClients(
                localStorage.getItem("token"),
                profileData.beautician_id
            );
            setData(res || []);
            setFilteredData(res || []);
        } catch (error) {
            console.error("Erreur lors du chargement des clients :", error);
        }
    };

    // Mettre à jour la liste filtrée lorsqu'on recherche
    useEffect(() => {
        if (search) {
            const filtered = data.filter(client =>
                Object.values(client).some(value =>
                    value?.toString().toLowerCase().includes(search.toLowerCase())
                )
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    }, [search, data]);

    // Définition des colonnes du tableau
    const columns = [
        {
            name: "Prénom",
            selector: row => row.first_name || "N/A",
            sortable: true,
        },
        {
            name: "Nom",
            selector: row => row.last_name || "N/A",
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.unique_email || "N/A",
        },
        {
            name: "Téléphone",
            selector: row => row.phone || "N/A",
        },
        {
            name: "Nb. Rendez-vous",
            selector: row => row.appointment_count || 0,
            sortable: true,
        },
    ];

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Liste des Clients</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Champ de recherche */}
                <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Rechercher un client..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />

                {/* Tableau avec pagination */}
                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    noDataComponent="Aucun client trouvé"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShowListClientsModal;