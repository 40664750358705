import React, { useState, useEffect, useContext, useMemo } from "react";
import appointmentApi from '../../../api/appointement.api';
import { motion } from "motion/react";
import DataTable from 'react-data-table-component';
import { formatTime } from "../../../utils/functions";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import NoAppointmentMessage from "../client/NoAppointmentMessage";
import ShowBeauticianHistoryModal from "./historique/ShowBeauticianHistoryModal";
import ShowListClientsModal from "./historique/ShowListClientsModal";
import { BeauticianPortalContext } from "../../../context/BeauticianPortalContext";
import MySpinner from "../../loader/Spinner";
import { Search, X } from "lucide-react";

const BeauticianHistorique = () => {
    const { profileData } = useContext(BeauticianPortalContext);
    const [allData, setAllData] = useState([]); // Stock toutes les données
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [search, setSearch] = useState("");
    const [showClients, setShowClients] = useState(false);

    const statusLabels = {
        pending: "Terminé",
        cancelled: "Annulé",
        confirmed: "Terminé",
        completed: "Terminé",
    };

    const getStatusClass = (status) => {
        if (status === "cancelled") return "bg-red-100 text-red-400 border-red-300";
        return "bg-gray-100 text-gray-400 border-gray-300";
    };

    const columns = [
        {
            name: 'Client',
            selector: row => `${row.first_name} ${row.last_name}`,
            sortable: true,
        },
        {
            name: 'Date et heure',
            selector: row => new Date(row.appointment_date),
            sortable: true,
            format: row => `${format(
                new Date(row.appointment_date),
                "EEEE d MMMM yyyy",
                { locale: fr }
            )} ${formatTime(row.start_time)}`,
        },
        {
            name: 'Services',
            selector: row => row.services.map(service => service.subcategory_name).join(', '),
            sortable: true,
        },
        {
            name: 'Adresse email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <div className={`px-3 py-1 rounded border-2 text-sm ${getStatusClass(row.status)}`}>
                    {statusLabels[row.status]}
                </div>
            ),
        },
    ];

    const fetchAppointmentsHistory = async () => {
        setIsLoading(true);
        try {
            const result = await appointmentApi.getBeauticianAppointmentHistory({
                beauticianId: profileData.beautician_id,
                token: localStorage.getItem('token')
            });
            setAllData(result.appointments);
        } catch (error) {
            console.error("Erreur lors de la récupération des rendez-vous :", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (profileData) {
            fetchAppointmentsHistory();
        }
    }, [profileData]);

    // Filtrer les données en fonction de la recherche
    const filteredData = useMemo(() => {
        if (!search) return allData;

        return allData.filter(item => {
            const searchLower = search.toLowerCase();
            const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
            const email = item.email.toLowerCase();
            const date = format(new Date(item.appointment_date), "EEEE d MMMM yyyy ", { locale: fr }).toLowerCase();
            const services = item.services.map(service => service.subcategory_name.toLowerCase()).join(' ');

            return (
                fullName.includes(searchLower) ||
                email.includes(searchLower) ||
                date.includes(searchLower) ||
                services.includes(searchLower)
            );
        });
    }, [allData, search]);

    const handleItemClick = (appointment) => {
        setSelectedAppointment(appointment);
    };

    const handleClear = () => {
        setSearch("");
    };

    const customStyles = {
        rows: {
            style: {
                cursor: 'pointer',
            },
        },
    };

    return (
        <div className="agenda-container">
            <div className="agenda-header w-full">
                <div className='flex flex-wrap w-full justify-between items-center'>
                    <h4>Historiques</h4>
                    <button className="btn btn-secondary" onClick={() => setShowClients(true)}>
                        Mes fiches clients
                    </button>
                </div>
            </div>

            <div className="min-h-[65vh] flex flex-col justify-between pb-1">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="overflow-y-auto p-2"
                >
                    <div className="w-full max-w-md mb-2">
                        <div className="flex space-x-2">
                            <div className="w-full max-w-md mx-auto relative">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                                <input
                                    type="text"
                                    placeholder="Rechercher par nom, date, email, services..."
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                />
                                {search && (
                                    <X
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                                        size={20}
                                        onClick={handleClear}
                                    />
                                )}
                            </div>
                        </div>
                        {/* <p className="mt-2 text-warning">NB : format pour la recherche par date : dd/MM/yyyy</p> */}
                    </div>

                    {isLoading ? (
                        <MySpinner height="70vh" />
                    ) : filteredData.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            customStyles={customStyles}
                            onRowClicked={handleItemClick}
                            progressPending={isLoading}
                            progressComponent={<MySpinner height="200px" />}
                            noDataComponent={<NoAppointmentMessage />}
                        />
                    ) : (
                        <NoAppointmentMessage />
                    )}
                </motion.div>
            </div>

            {selectedAppointment && (
                <ShowBeauticianHistoryModal
                    appointment={selectedAppointment}
                    onClose={() => setSelectedAppointment(null)}
                />
            )}

            {showClients && (
                <ShowListClientsModal 
                    show={showClients}
                    onHide={() => setShowClients(false)}
                />
            )}
        </div>
    );
};

export default BeauticianHistorique;