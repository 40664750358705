
import React, { useState } from "react";
import ShowAllActiveAbonnementModal from "./ShowAllActiveAbonnementModal";
import ShowAbonnementByMonthModal from "./ShowAbonnementByMonthModal";
import { Table } from "react-bootstrap";

const AbonnementStats = ({ statistics }) => {
    const {
        totalRevenue,
        totalUsers,
        subscriptionsByMonth,
        totalActiveSubscriptions,
        avgSubscriptionsPerUser,
        subscriptionsByOffer,
        revenueByMonth,
        totalSubscriptionsWithPromo,
        totalAttribued
    } = statistics;


    const [showAllAcitveAbonnement, setShowAllActiveAbonnement] = useState(false);
    const [showAbonnementByMonth, setShowAbonnmentByMonth] = useState(false);
    const [selectedDate, setSelectedDate] = useState({ month: 0, year: 0 });
    const [selectedType, setSelectedType] = useState('');

    const handleRowClick = (month) => {

        setSelectedDate({
            month: new Date(month).getMonth() + 1,
            year: new Date(month).getFullYear(),
        });

        setShowAbonnmentByMonth(true);
    }

    const handleShowDetailsByType = (type) => {
        setSelectedType(type);
        setShowAllActiveAbonnement(true);
    }

    return (
        <div className="p-6 bg-gray-100 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Statistiques des Abonnements</h2>

            {/* Section des totaux */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                <div className="p-4 bg-white rounded shadow text-center">
                    <h3 className="text-lg font-medium text-gray-600">Chiffre d'Affaires Total</h3>
                    <p className="text-2xl font-bold text-blue-600">{parseFloat(totalRevenue).toFixed(2)} €</p>
                </div>
                <div className="p-4 bg-white rounded shadow text-center">
                    <h3 className="text-lg font-medium text-gray-600">Total Utilisateurs</h3>
                    <p className="text-2xl font-bold text-blue-600">{totalUsers}</p>
                </div>
                <div className="p-4 bg-white rounded shadow text-center cursor-pointer" onClick={() => handleShowDetailsByType('active')}>
                    <h3 className="text-lg font-medium text-gray-600">Abonnements Actifs</h3>
                    <p className="text-2xl font-bold text-blue-600">{totalActiveSubscriptions}</p>
                </div>
                <div className="p-4 bg-white rounded shadow text-center">
                    <h3 className="text-lg font-medium text-gray-600">Abonnements Moyens</h3>
                    <p className="text-2xl font-bold text-blue-600">{avgSubscriptionsPerUser}</p>
                </div>
                <div className="p-4 bg-white rounded shadow text-center cursor-pointer" onClick={() => handleShowDetailsByType('attribued')}>
                    <h3 className="text-lg font-medium text-gray-600">Abonnements attribué</h3>
                    <p className="text-2xl font-bold text-blue-600">{totalAttribued}</p>
                </div> 
                <div className="p-4 bg-white rounded shadow text-center cursor-pointer" onClick={() => handleShowDetailsByType('code-promo')}>
                    <h3 className="text-lg font-medium text-gray-600">Abonnements par code promo</h3>
                    <p className="text-2xl font-bold text-blue-600">{totalSubscriptionsWithPromo}</p>
                </div>
            </div>

            {/* Abonnements par mois */}
            <div className="mb-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Abonnements par Mois</h3>
                <div className="overflow-x-auto overflow-y-auto max-h-[40vh]">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Mois</th>
                                <th>Total Abonnements</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptionsByMonth.map((item, index) => (
                                <tr key={index} onClick={() => handleRowClick(item.month)}>
                                    <td>{new Date(item.month).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' })}</td>
                                    <td>{item.total_subscriptions}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>

            {/* Répartition des abonnements par offre */}
            <div className="mb-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Répartition par Offre</h3>
                <div className="overflow-x-auto">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Offre</th>
                                <th>Total Abonnements</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subscriptionsByOffer.map((offer, index) => (
                                <tr key={index}>
                                    <td>{offer.offer_name}</td>
                                    <td>{offer.total_subscriptions}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>

            {/* Chiffre d'affaires par mois */}
            <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Chiffre d'Affaires par Mois</h3>
                <div className="overflow-x-auto overflow-y-auto max-h-[40vh]">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Mois</th>
                                <th>Chiffre d'Affaires (€)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {revenueByMonth.map((item, index) => (
                                <tr key={index} onClick={() => handleRowClick(item.month)}>
                                    <td>{new Date(item.month).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' })}</td>
                                    <td>{parseFloat(item.revenue).toFixed(2)} €</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>

            {/** Modal show all active aboonements */}
            <ShowAllActiveAbonnementModal
                show={showAllAcitveAbonnement}
                selectedType={selectedType}
                onClose={() => setShowAllActiveAbonnement(false)}
            />

            {/** Modal show aboonements by month*/}
            {(selectedDate.year !== 0 && selectedDate.month !== 0) &&
                <ShowAbonnementByMonthModal
                    selectedDate={selectedDate}
                    show={showAbonnementByMonth}
                    onClose={() => setShowAbonnmentByMonth(false)}
                />
            }
        </div>
    );
};

export default AbonnementStats;