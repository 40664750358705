import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TeleConseillerPortalSidebar from '../../../components/portals/tele-conseiller/TeleConseillerPortalSidebar';
import './TeleConseillerLayout.css';
import { TeleConseillerPortalContext } from '../../../context/TeleConseillerPortalContext';
import MySpinner from '../../../components/loader/Spinner';
import { Menu } from 'lucide-react';
import TeleConseillerPortalHeader from '../../../components/portals/tele-conseiller/TeleConseillerPortalHeader';


const TeleConseillerPortalLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {isParentLoading} = useContext(TeleConseillerPortalContext);
  const [isCompact, setIsCompact] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Scroll vers le haut à chaque changement de route
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="portal-wrapper">
      <TeleConseillerPortalHeader
        isSidebarOpen={isSidebarOpen}
        onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)}
      />
      
      <div className="portal-content">
        {/* Ajouter le bouton de toggle */}
        <button 
          className="compact-toggle"
          onClick={() => setIsCompact(!isCompact)}
        >
          <Menu size={20} />
        </button>
  
        <aside className={`sidebar ${isSidebarOpen ? 'sidebar-open' : ''} ${isCompact ? 'compact' : ''}`}>
          <TeleConseillerPortalSidebar onLinkClick={() => setIsSidebarOpen(false)} />
        </aside>
  
        <main className="main-container">
          {!isParentLoading && <Outlet />}
          {isParentLoading && <MySpinner height={"70vh"}/>}
        </main>
      </div>
    </div>
  );

};

export default TeleConseillerPortalLayout;