import React, { useContext, useEffect } from 'react';
import { AppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import userApi from '../api/user.api';
import Swal from 'sweetalert2';
import { BASE_URL } from '../utils/constants';


const NeedToAcceptTerms = () => {
    const { connectedUser, setActivePageForHeader } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Indiquer explicitement qu'on n'est pas sur la home
        setActivePageForHeader("");
        // Nettoyage au démontage du composant
        return () => {
            setActivePageForHeader("");
        };
    }, [setActivePageForHeader]);

    useEffect(() => {
        if (connectedUser) {
            if (connectedUser.agree_terms) {
                // redirect to dashboard
                let url = "";
                switch (connectedUser.user_type) {
                    case "beautician":
                        url = '/beautician-dashboard';
                        break;
                    case "client":
                        url = '/client-dashboard';
                        break;
                    case "recruteur":
                        url = '/recruteur-dashboard';
                        break;
                    case "tele_conseiller":
                        url = '/tele-conseiller-dashboard';
                        break;
                    default:
                        url = "/";
                        break;
                }

                window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}${url}`;
            }
        }
    }, [connectedUser])

    const handleAcceptTerms = async () => {
        await userApi.acceptTerms(localStorage.getItem('token'), connectedUser.id);
        Swal.fire({
            icon: 'success',
            title: 'Conditions générales acceptées!',
            showConfirmButton: false,
            timer: 1500
        }).then(() => {
            let url = "";
            switch (connectedUser.user_type) {
                case "beautician":
                    url = '/beautician-dashboard';
                    break;
                case "client":
                    url = '/client-dashboard';
                    break;
                case "recruteur":
                    url = '/recruteur-dashboard';
                    break;
                case "tele_conseiller":
                    url = '/tele-conseiller-dashboard';
                    break;
                default:
                    url = "/";
                    break;
            }
            window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}${url}`;
        });
    };

    return (
        <div className="flex items-center justify-center min-h-[70vh] bg-gray-100">
            <div className="p-6 bg-white rounded-lg shadow-md text-center">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">
                    Vous devez accepter les conditions générales d'utilisation pour continuer!
                </h2>
                <p className="text-gray-600 mb-6">
                    Lire
                    <a href={`${process.env.NODE_ENV === "production" ? BASE_URL : 'http://localhost:3000'}/ConditionsGenerales`} rel="noreferrer" target="_blank" className="text-blue-600 underline cursor-pointer ml-1">
                        les conditions générales d'utilisation et conditions générales de ventes
                    </a>
                </p>
                <button
                    onClick={handleAcceptTerms}
                    className="px-4 py-2 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                    Accepter les termes et conditions d'utilisation
                </button>
            </div>
        </div>
    );

}


export default NeedToAcceptTerms;