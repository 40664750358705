import { useContext, useEffect, useState } from "react";
import { BeauticianPortalContext } from "../../../../context/BeauticianPortalContext";
import beauticianApi from "../../../../api/beautician.api";
import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../../../utils/messageHelper";

const PaymentModeSettings = () => {

    const { profileData, setReloadTrigger } = useContext(BeauticianPortalContext);

    const [loading, setLoading] = useState(true);
    const [paymentMode, setPaymentMode] = useState(null);
    const [depositPercentage, setDepositPercentage] = useState(0);
    const [refundLimitTime, setRefundLimitTime] = useState(24);
    const [iban, setIban] = useState('');
    const [bic, setBic] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [paypalLink, setPaypalLink] = useState('');

    const fetchPaymentMode = async (beautician_id) => {
        setLoading(true);
        const res = await beauticianApi.getBeauticianPaymentMode(beautician_id);
        setPaymentMode(res.data || null);
        setLoading(false);
    }

    useEffect(() => {
        if (profileData) {
            fetchPaymentMode(profileData.beautician_id);
            setDepositPercentage(profileData.deposit_percentage);
            setRefundLimitTime(profileData.refund_limit_time_for_deposit)
            setIban(profileData.iban);
            setBic(profileData.bic);
            setFirstName(profileData.bank_info_first_name);
            setLastName(profileData.bank_info_last_name);
            setPaypalLink(profileData.paypal_link);
        }
    }, [profileData]);


    const handleCheckChange = async (key, value) => {
        if (profileData) {
            // Créez une copie temporaire pour simuler la modification
            const updatedPaymentMode = {
                ...paymentMode,
                [key]: value
            };

            // Vérifiez si au moins une valeur reste true
            const isValid = Object.values(updatedPaymentMode).some(v => v === true);

            if (!isValid) {
                // Si aucune valeur n'est `true`, prévenir l'utilisateur
                showWarningMessage("Au moins un mode de paiement doit être sélectionné.");
                return;
            }

            // Si la modification est valide, appliquez-la
            try {
                await beauticianApi.updatePaymentMode(
                    localStorage.getItem('token'),
                    profileData.beautician_id,
                    key,
                    value
                );
                setPaymentMode(updatedPaymentMode);
                showSuccessMessage("Mode de paiement modifié avec succès!");
            } catch (error) {
                // Gérer les erreurs API
                showErrorMessage("Une erreur s'est produite lors de la mise à jour.");
                console.error(error);
            }
        }
    };

    const handleDepositPercentageChange = async (value) => {
        if (profileData) {
            try {
                await beauticianApi.updateDepositPercentage(
                    localStorage.getItem('token'),
                    profileData.beautician_id,
                    parseFloat(value)
                );
                setDepositPercentage(value);
                showSuccessMessage("Pourcentage d'acompte mis à jour avec succès!");
            } catch (error) {
                showErrorMessage("Une erreur s'est produite lors de la mise à jour.");
                console.error(error);
            }
        }
    }

    const handleRefundLimitTimeChange = async (value) => {
        if (profileData) {
            try {
                await beauticianApi.updateRefundLimitTimeForDeposit(
                    localStorage.getItem('token'),
                    profileData.beautician_id,
                    value
                );
                setRefundLimitTime(value);
                showSuccessMessage("Durée limite de remboursement applicable à l'acompte mis à jour avec succès!");
            } catch (error) {
                showErrorMessage("Une erreur s'est produite lors de la mise à jour.");
                console.log(error);
            }
        }
    }

    const validateBankInfo = () => {
        if (!firstName || !lastName || !iban || !bic) {
            showWarningMessage("Veuillez remplir tous les champs.");
            return false;
        }
        return true;
    }

    const handleSaveBankInfo = async () => {
        if (!validateBankInfo()) return;
        if (profileData) {
            try {
                await beauticianApi.updateBankInfo(
                    localStorage.getItem('token'),
                    profileData.beautician_id,
                    {
                        first_name: firstName,
                        last_name: lastName,
                        iban: iban,
                        bic: bic,
                        paypal_link: paypalLink
                    }
                );
                showSuccessMessage("Informations bancaires mises à jour avec succès!");
                setTimeout(() => {
                    setReloadTrigger(prev => !prev);
                }, 1000);
            } catch (error) {
                showErrorMessage("Une erreur s'est produite lors de la mise à jour.");
                console.error(error);
            }
        }
    }

    return <div className="settings-form">
        <div className="notifications-settings">
            {!loading && <div>
                <div>
                    <h6>Veuillez sélectionner le mode de paiement que vous souhaitez accepter :</h6>
                    <div className="notification-option">
                        <div className="notification-info">
                            <h4>Paiement complet</h4>
                            <p>Paiement en ligne complet par carte bancaire</p>
                        </div>
                        <label className="switch">
                            <input type="checkbox" checked={paymentMode.full} onChange={() => handleCheckChange('full', !paymentMode.full)} />
                            <span className="slider"></span>
                        </label>
                    </div>
                    <div className="notification-option">
                        <div className="notification-info">
                            <h4>Acompte</h4>
                            <p>Paiment en ligne partiel par carte bancaire</p>
                        </div>
                        <label className="switch">
                            <input type="checkbox" checked={paymentMode.deposit} onChange={() => handleCheckChange('deposit', !paymentMode.deposit)} />
                            <span className="slider"></span>
                        </label>
                    </div>
                    <div className="notification-option">
                        <div className="notification-info">
                            <h4>Sur site</h4>
                            <p>Paiment sur place lors du rendez-vous</p>
                        </div>
                        <label className="switch">
                            <input type="checkbox" checked={paymentMode.onsite} onChange={() => handleCheckChange('onsite', !paymentMode.onsite)} />
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>

                {(paymentMode.full || paymentMode.deposit) && <div className="mt-3">
                    <hr />
                    <h6>Veuillez entrer les informations de votre compte bancaire pour le versement de vos gains à la fin de chaque mois :</h6>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-3 flex flex-col gap-2">
                                <label>Prénom</label>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="px-3 py-2 mt-2 bg-gray-100 rounded w-full border border-gray-300"
                                    placeholder="Prénom"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-3 flex flex-col gap-2">
                                <label>Nom</label>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="px-3 py-2 mt-2 bg-gray-100 rounded w-full border border-gray-300"
                                    placeholder="Nom"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mt-3 flex flex-col gap-2">
                                <label>IBAN</label>
                                <input
                                    type="text"
                                    value={iban}
                                    onChange={(e) => setIban(e.target.value)}
                                    className="px-3 py-2 mt-2 bg-gray-100 rounded w-full border border-gray-300"
                                    placeholder="IBAN"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mt-3 flex flex-col gap-2">
                                <label>Adresse BIC/SWIFT</label>
                                <input
                                    type="text"
                                    value={bic}
                                    onChange={(e) => setBic(e.target.value)}
                                    className="px-3 py-2 mt-2 bg-gray-100 rounded w-full border border-gray-300"
                                    placeholder="BIC/SWIFT"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mt-3 flex flex-col gap-2">
                                <label>Ou ajouter votre lien PayPal (optionnel)</label>
                                <input
                                    type="url"
                                    value={paypalLink}
                                    onChange={(e) => setPaypalLink(e.target.value)}
                                    className="px-3 py-2 mt-2 bg-gray-100 rounded w-full border border-gray-300"
                                    placeholder="Lien PayPal"
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={handleSaveBankInfo}
                        className="py-2 px-3 rounded mt-3 bg-blue-600 hover:bg-blue-700 text-white"
                    >Enregistrer</button>
                </div>
                }

                {paymentMode.deposit && <>
                    <div className="mt-3">
                        <hr />
                        <h6>Veuillez préciser le taux applicable si vous acceptez le paiement par acompte :</h6>
                        <select
                            value={depositPercentage}
                            onChange={(e) => handleDepositPercentageChange(e.target.value)}
                            className="px-3 py-2 mt-2 bg-gray-100 rounded min-w-[200px] border border-gray-300"
                        >
                            <option value="10.00">10%</option>
                            <option value="30.00">30%</option>
                            <option value="50.00">50%</option>
                        </select>
                    </div>

                    <div className="mt-3">
                        <hr />
                        <h6>Veuillez indiquer la durée limite de remboursement applicable à l'acompte :</h6>
                        <select
                            value={refundLimitTime}
                            onChange={(e) => handleRefundLimitTimeChange(e.target.value)}
                            className="px-3 py-2 mt-2 bg-gray-100 rounded min-w-[200px] border border-gray-300"
                        >
                            <option value="24">24h</option>
                            <option value="48">48h</option>
                        </select>
                    </div>
                </>
                }
            </div>
            }
        </div>
    </div>
}

export default PaymentModeSettings;