import React, { useContext, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
	LayoutDashboard,
	UserCircle,
	Settings,
	MessageCircle,
} from 'lucide-react';
import './TeleConseillerPortalSidebar.css';

import { TeleConseillerPortalContext } from '../../../context/TeleConseillerPortalContext';
import { useMessage } from '../../../contexts/MessageContext'; // Ajout du context message


const TeleConseillerPortalSidebar = ({ onLinkClick }) => {

	const { profileData, isParentLoading } = useContext(TeleConseillerPortalContext);
	const { unreadMessages } = useMessage(); // Récupération des messages non lus

	const [menuItems, setMenuItems] = useState([
		{
			title: "Tableau de bord",
			icon: <LayoutDashboard size={20} />,
			path: "/beautician-dashboard/", // Chemin complet pour le dashboard
			exact: true, // Propriété pour activer uniquement ce chemin exact
		}
	]);

	useEffect(() => {
		if (!isParentLoading && profileData) {

			setMenuItems(([
				{
					title: "Tableau de bord",
					icon: <LayoutDashboard size={20} />,
					path: "/tele-conseiller-dashboard/", // Chemin complet pour le dashboard
					exact: true, // Propriété pour activer uniquement ce chemin exact
				},
				{
					title: "Messages",
					icon: <MessageCircle size={20} />,
					path: "/tele-conseiller-dashboard/messaging",
					hasNotification: unreadMessages > 0,
					notificationCount: unreadMessages // Ajout du compte
				},
				{
					title: "Mon Profil",
					icon: <UserCircle size={20} />,
					path: "/tele-conseiller-dashboard/profile",
				},
				{
					title: "Paramètres",
					icon: <Settings size={20} />,
					path: "/tele-conseiller-dashboard/settings",
				}
			]));
		}
	}, [profileData, isParentLoading, unreadMessages]);

	return (
		<div className="sidebar-inner">
			<div className="sidebar-branding">
				<Link to="/" className="sidebar-brand-text">Planibeauty</Link>
			</div>

			<nav className="sidebar-nav">
				{menuItems.map((item, index) => (
					<NavLink
						key={index}
						to={item.path}
						className={({ isActive }) =>
							`sidebar-link ${isActive ? 'sidebar-link-active' : ''}`
						}
						onClick={onLinkClick}
						end={item.exact}
						title={item.title} // Ajout du titre qui apparaîtra au survol
					>
						<span className="sidebar-link-icon">
							{item.icon}
							{item.hasNotification && (
								<span className="sidebar-message-count">
									{item.notificationCount > 9 ? '9+' : item.notificationCount}
								</span>
							)}
						</span>
						<span className="sidebar-link-text">{item.title}</span>
					</NavLink>
				))}
			</nav>
		</div>
	);
};

export default TeleConseillerPortalSidebar;
