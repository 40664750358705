import React, { useState, useEffect } from "react"
import { BASE_URL } from "../../../utils/constants"
import { motion } from "motion/react"
import MySpinner from "../../loader/Spinner"
import Swal from "sweetalert2"
import appointmentApi from "../../../api/appointement.api"
import beauticianApi from "../../../api/beautician.api"
import userApi from "../../../api/user.api"
import defaultProfile from '../../../assets/images/default-profile.png'
import { ChevronLeft, ChevronRight, Star } from "lucide-react"

const RenderStar = ({ starCount }) => {
    return <div className="mt-2 flex items-center gap-1">
        {[1, 2, 3, 4, 5].map(i => <Star fill={starCount > i ? "#bd193b" : "transparent"} size={14} />)}
    </div>
}
const Preferences = () => {
    const [data, setData] = useState([])
    const [userPreferences, setUserPreferences] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [reloadTrigger, setReloadTrigger] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const ITEMS_PER_PAGE = 10

    const getImageUrl = (profilePhoto) => {
        if (!profilePhoto) return defaultProfile
        try {
            return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`
        } catch (error) {
            console.error('Error with image path:', error)
            return defaultProfile
        }
    }

    const groupAppointmentsByBusinessName = (appointments) => {
        const grouped = appointments.reduce((acc, appointment) => {
            const { business_name, beautician_id, appointment_date, services } = appointment

            if (!acc[business_name]) {
                acc[business_name] = {
                    business_name,
                    beautician_id,
                    data: []
                }
            }

            acc[business_name].data.push({
                appointment_date,
                services: services?.map(s => ({ subcategory_name: s.subcategory_name })) || []
            })

            return acc
        }, {})

        return Object.values(grouped)
    }

    const handleAddToPreferences = (beautician_id, business_name) => {
        Swal.fire({
            title: "Préférences",
            html: `Voulez-vous ajouter <strong>${business_name}</strong> aux préférences ?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Oui, ajouter",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const token = localStorage.getItem("token")
                    const user_id = localStorage.getItem("userId")
                    const add_result = await userApi.createUserPreferences(token, user_id, beautician_id)

                    if (add_result.success) {
                        Swal.fire("Succès", "Préférence ajoutée", "success")
                        setReloadTrigger(prev => !prev)
                    } else {
                        Swal.fire("Erreur", add_result.message, "error")
                    }
                } catch (err) {
                    Swal.fire("Erreur", err.message, "error")
                }
            }
        })
    }

    const handleRemovePreferences = (beautician_id, business_name) => {
        Swal.fire({
            title: "Préférences",
            html: `Voulez-vous supprimer <strong>${business_name}</strong> des préférences ?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const token = localStorage.getItem("token")
                    const user_id = localStorage.getItem("userId")
                    const remove_result = await userApi.deleteUserPreferences(token, user_id, beautician_id)

                    if (remove_result.success) {
                        Swal.fire("Succès", "Préférence supprimée", "success")
                        setReloadTrigger(prev => !prev)
                    } else {
                        Swal.fire("Erreur", remove_result.message, "error")
                    }
                } catch (err) {
                    Swal.fire("Erreur", err.message, "error")
                }
            }
        })
    }

    const fetchUserPreferences = async () => {
        try {
            const token = localStorage.getItem("token")
            const user_id = localStorage.getItem("userId")

            const user_preferences = await userApi.getUserPreferences(token, user_id)

            if (user_preferences.success) {
                const temp_data = await Promise.all(user_preferences.data.map(async (pref) => {
                    const beautician = await beauticianApi.getBeauticianById(pref.beautician_id)
                    const data = await userApi.fetchBeauticianUserByUserId(
                        localStorage.getItem('token'),
                        pref.beautician_id
                    )

                    return { ...pref, ...beautician.data, profilePhoto: getImageUrl(data.profilePhoto) }
                }))

                setUserPreferences(temp_data)
            } else {
                Swal.fire("Erreur", user_preferences.message, "error")
            }
        } catch (err) {
            Swal.fire("Erreur", err.message, "error")
        } finally {
            setIsLoading(false)
        }
    }

    const fetchAppointmentsHistory = async () => {
        setIsLoading(true)
        try {
            const userId = localStorage.getItem("userId")
            const result = await appointmentApi.getUserAppointmentHistory({
                userId,
                token: localStorage.getItem('token'),
                page: currentPage,
                limit: ITEMS_PER_PAGE,
            })

            const beauticianData = await Promise.all(
                groupAppointmentsByBusinessName(result.appointments).map(async (b) => {
                    const data = (await beauticianApi.getBeauticianById(b.beautician_id)).data

                    return { ...b, profilePhoto: getImageUrl(data.profile_photo) }
                })
            )

            setData(beauticianData)
            setTotalPages(Math.ceil(result.total_count / ITEMS_PER_PAGE))
        } catch (error) {
            console.error("Erreur lors de la récupération des rendez-vous :", error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleRedirect = async (e, beautician_id) => {
        try {
            const beautician = await beauticianApi.getBeauticianById(beautician_id)

            if (beautician.success) {
                window.location = `/beauticians/${beautician.data.slug}`
            }
        } catch (err) {
            Swal.fire("Erreur", err.message, "error")
        }
    }

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page)
        }
    }

    useEffect(() => {
        fetchUserPreferences()
        fetchAppointmentsHistory()
    }, [reloadTrigger])

    return (
        <div className="w-full h-[90vh] mt-[1vh] bg-white overflow-scroll">
            {isLoading ? <MySpinner height="70vh" /> : (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }} className="h-full w-full py-2 px-3">
                    <h1 className="mx-8 text-xl pt-4 pb-2 border-b">Mes préférences</h1>
                    {userPreferences.length > 0 ? userPreferences.map((beautician, key) => (
                        <div key={key} className="m-4 px-8 p-4 flex flex-col gap-4 md:flex-row items-center border rounded shadow-sm">
                            <div className="flex items-center gap-4">
                                <img className="size-16 rounded-full border-4" src={beautician.profilePhoto} alt="Profile photo" />

                                <div>
                                    <span className="text-lg">{beautician.business_name}</span>

                                    <RenderStar starCount={beautician.average_rating} />
                                </div>
                            </div>
                            <div className="md:ms-auto flex gap-4 items-center">
                                <button onClick={(e) => handleRedirect(e, beautician.beautician_id)} className="underline">Voir le profil</button>
                                <button onClick={() => handleRemovePreferences(beautician.beautician_id, beautician.business_name)} className="px-3 py-2 text-white bg-[#bd193b] rounded">Supprimer</button>
                            </div>
                        </div>
                    )) : <p className="text-center my-8 text-gray-500">Aucun professionnel en préférences.</p>}

                    <h1 className="mx-8 text-xl pt-4 pb-2 border-b">Sélectionner par historique</h1>

                    {data.length > 0 && data.map((app, key) => !userPreferences.some(pref => pref.beautician_id === app.beautician_id) && (
                        <div key={key} className="m-4 px-8 p-4 flex flex-col gap-4 md:flex-row items-center border rounded shadow-sm">
                            <div className="flex items-center gap-4">
                                <img className="size-16 rounded-full border-4" src={app.profilePhoto} alt="Profile photo" />

                                <div>
                                    <span className="text-lg">{app.business_name}</span>

                                    <RenderStar starCount={app.average_rating} />
                                </div>
                            </div>
                            <div className="md:ms-auto flex gap-4 items-center">
                                <button onClick={(e) => handleRedirect(e, app.beautician_id)} className="underline">Voir le profil</button>
                                <button onClick={() => handleAddToPreferences(app.beautician_id, app.business_name)} className="px-3 py-2 text-white bg-[#bd193b] rounded">Ajouter</button>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-4">
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
                            <ChevronLeft />
                        </button>
                        <span className="px-4 py-2 text-lg">{currentPage} / {totalPages}</span>
                        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                            <ChevronRight />
                        </button>
                    </div>
                </motion.div>
            )}
        </div>
    )
}

export default Preferences
