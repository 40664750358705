import React, { createContext, useEffect, useState } from 'react';
import { io } from "socket.io-client";
import Loader from '../components/loader/Loader';

export const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [beauticians, setBeauticians] = useState([]);
    const [serviceCategories, setServiceCategories] = useState([]);
    const [priceRanges, setPriceRanges] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState('disconnected');



    useEffect(() => {

        const isProduction = process.env.NODE_ENV === 'production';

        // Configuration Socket.IO client
        const socketOptions = {
            path: '/socket.io',
            transports: ['polling', 'websocket'], // Commencer par polling
            reconnection: true,
            reconnectionAttempts: Infinity, // Augmenter les tentatives
            reconnectionDelay: 1000,
            timeout: 60000, // Augmenter le timeout
            autoConnect: true,
            withCredentials: true,
            forceNew: true,
            extraHeaders: {
                "my-custom-header": "true"
            }
        };

        const SOCKET_URL = isProduction
            ? window.location.origin
            : process.env.REACT_APP_SOCKET_URL;

        // console.log('Tentative de connexion socket:', {
        //     url: SOCKET_URL,
        //     options: socketOptions,
        //     mode: isProduction ? 'production' : 'development'
        // });

        const socketInstance = io(SOCKET_URL, socketOptions);

        // Gestionnaire de connexion
        socketInstance.on('connect', () => {
            // console.log('Socket connecté:', {
            //     id: socketInstance.id,
            //     transport: socketInstance.io.engine.transport.name
            // });
            setConnectionStatus('connected');
            setSocket(socketInstance);
            setError(null);

            // Demande initiale des données
            socketInstance.emit('get-beauticians');
            socketInstance.emit('get-service-categories');
        });

        // Gestionnaire d'erreur de connexion
        socketInstance.on('connect_error', (error) => {
            console.error('Erreur détaillée:', {
                error: error.message,
                transport: socketInstance.io.engine?.transport?.name,
                readyState: socketInstance.io.engine?.readyState
            });
            setConnectionStatus('error');
            setError(`Erreur de connexion: ${error.message}`);

            // Tentative de fallback vers polling
            if (error.message.includes('websocket')) {
                // console.log('Fallback vers polling...');
                socketInstance.io.opts.transports = ['polling'];
            }
        });

        // Gestionnaire de reconnexion
        socketInstance.on('reconnect_attempt', (attemptNumber) => {
            // console.log(`Tentative de reconnexion #${attemptNumber}`);
            setConnectionStatus('reconnecting');
        });

        socketInstance.on('reconnect', () => {
            // console.log('Reconnecté avec succès');
            setConnectionStatus('connected');
            setError(null);

            // Recharger les données après reconnexion
            socketInstance.emit('get-beauticians');
            socketInstance.emit('get-service-categories');
        });

        socketInstance.on('reconnect_failed', () => {
            // console.log('Échec de la reconnexion');
            setConnectionStatus('failed');
            setError('Impossible de se reconnecter au serveur');
        });

        // Gestionnaire de déconnexion
        socketInstance.on('disconnect', (reason) => {
            // console.log('Déconnecté:', reason);
            setConnectionStatus('disconnected');
            if (reason === 'io server disconnect') {
                // Reconnexion manuelle si déconnecté par le serveur
                socketInstance.connect();
            }
        });

        // Gestionnaires de données
        socketInstance.on('beauticians', (data) => {
            // console.log('Beauticians reçus:', data?.length || 0);
            if (Array.isArray(data)) {
                setBeauticians(data);
                setIsLoading(false);
            }
        });

        socketInstance.on('service-categories', (data) => {
            // console.log('Catégories reçues:', data?.length || 0);
            if (Array.isArray(data)) {
                setServiceCategories(data);
            }
        });

        socketInstance.on('price-ranges', (data) => {
            // console.log('Prix reçus:', data?.length || 0);
            if (Array.isArray(data)) {
                setPriceRanges(data);
            }
        });

        // Gestionnaire d'erreur générique
        socketInstance.on('error', (error) => {
            console.error('Erreur socket:', error);
            setError(error.message || 'Une erreur est survenue');
        });

        // Nettoyage à la destruction du composant
        return () => {
            if (socketInstance) {
                // console.log('Nettoyage de la connexion socket');
                socketInstance.removeAllListeners();
                socketInstance.disconnect();
            }
        };
    }, []);

    // Valeur du contexte
    const contextValue = {
        socket,
        beauticians,
        setBeauticians,
        serviceCategories,
        setServiceCategories,
        priceRanges,
        setPriceRanges,
        isLoading,
        error,
        connectionStatus
    };

    return (
        <SearchContext.Provider value={contextValue}>
            <Loader isLoading={isLoading} />
            {!isLoading && children}
        </SearchContext.Provider>
    );
};

export default SearchContextProvider;