import React, { useEffect, useState } from "react";
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';

const TimeSlots = ({ selectedDate, selectedTime, handleTimeSelect, dispoHebdoTime, duration, validAppointments, blockedDates, collaborateurs, selectedCollaborateur }) => {
    const [slots, setSlots] = useState([]);

    const constructSlots = () => {
        const getTimeElement = () => {
            let day = format(selectedDate, 'EEEE').toLowerCase();
            let time_element = null;
            if (dispoHebdoTime) {
                dispoHebdoTime.map((element) => {
                    if (element.day === day) {
                        time_element = element;
                    }
                });
            }
            return time_element;
        }

        const timeElement = getTimeElement();
        if (timeElement) {
            let slots = generateTimeSlots(timeElement.start_time, timeElement.end_time, timeElement.break_start, timeElement.break_end, duration);
            setSlots(slots);
        }
    }

    function generateTimeSlots(start_time, end_time, break_start, break_end, duration) {
        const convertToMinutes = (time) => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };

        const convertToTime = (minutes) => {
            const hours = Math.floor(minutes / 60);
            const mins = minutes % 60;
            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
        };

        const selectedDateStr = format(selectedDate, "yyyy-MM-dd");
        const startMinutes = convertToMinutes(start_time);
        const endMinutes = convertToMinutes(end_time);
        const breakStartMinutes = break_start ? convertToMinutes(break_start) : null;
        const breakEndMinutes = break_end ? convertToMinutes(break_end) : null;

        const now = new Date();
        const isToday = format(now, "yyyy-MM-dd") === selectedDateStr;
        const currentMinutes = isToday ? now.getHours() * 60 + now.getMinutes() : 0;

        const filteredBlockedDates = blockedDates
            .filter(({ date_start, date_end }) => {
                const startDate = format(date_start, 'yyyy-MM-dd');
                const endDate = format(date_end, 'yyyy-MM-dd');
                return (
                    format(selectedDate, 'yyyy-MM-dd') >= startDate &&
                    format(selectedDate, 'yyyy-MM-dd') <= endDate
                );
            })
            .map(({ start_time, end_time }) => {
                const start = start_time ? convertToMinutes(start_time) : null;
                const end = end_time ? convertToMinutes(end_time) : null;

                return { start, end };
            });

            const filteredAppointments = validAppointments
            .filter(({ appointment_date, employee_id }) => {
                const isSameDate = format(new Date(appointment_date), "yyyy-MM-dd") === selectedDateStr;
                
                if (!isSameDate) return false;
        
                // Compter le nombre de RDV sans collaborateur spécifié pour cette date/heure
                const unassignedAppointments = validAppointments.filter(app => 
                    format(new Date(app.appointment_date), "yyyy-MM-dd") === selectedDateStr &&
                    (app.employee_id === null || app.employee_id === undefined)
                ).length;
        
                // Compter les collaborateurs déjà occupés (avec RDV assignés)
                const busyCollaborateurs = collaborateurs.filter(collab =>
                    validAppointments.some(app =>
                        format(new Date(app.appointment_date), "yyyy-MM-dd") === selectedDateStr &&
                        app.employee_id &&
                        parseInt(app.employee_id) === parseInt(collab.id)
                    )
                ).length;
        
                if (selectedCollaborateur) {
                    // Cas avec un collaborateur sélectionné
                    const hasDirectAppointment = parseInt(employee_id) === parseInt(selectedCollaborateur);
                    
                    // Si ce collaborateur a déjà un RDV direct, il n'est pas disponible
                    if (hasDirectAppointment) return true;
        
                    // Si le nombre total de RDV (assignés + non assignés) est égal ou supérieur
                    // au nombre de collaborateurs, aucun créneau n'est disponible
                    if (busyCollaborateurs + unassignedAppointments >= collaborateurs.length) {
                        return true;
                    }
        
                    return false;
                } else {
                    // Cas sans collaborateur sélectionné
                    // Le créneau est indisponible UNIQUEMENT si le nombre total de RDV
                    // (assignés + non assignés) est égal ou supérieur au nombre de collaborateurs
                    return busyCollaborateurs + unassignedAppointments >= collaborateurs.length;
                }
            })
            .map(({ start_time, end_time }) => ({
                start: convertToMinutes(start_time),
                end: convertToMinutes(end_time),
            }));

        const isSlotTaken = (slotStart, slotEnd) => {
            const isAppointmentTaken = filteredAppointments.some(({ start, end }) =>
                slotStart < end && slotEnd > start
            );

            const isBlocked = filteredBlockedDates.some(({ start, end }) => {
                if (start === null && end === null) {
                    return true;
                }
                return (
                    (start !== null && slotEnd > start) &&
                    (end !== null && slotStart < end)
                );
            });

            return isAppointmentTaken || isBlocked;
        };

        const slots = [];
        let currentSlotMinutes = startMinutes;

        while (currentSlotMinutes + duration <= endMinutes) {
            const slotStart = currentSlotMinutes;
            const slotEnd = currentSlotMinutes + duration;

            const isInBreakTime =
                breakStartMinutes !== null &&
                slotEnd > breakStartMinutes &&
                slotStart < breakEndMinutes;

            const isTaken = isSlotTaken(slotStart, slotEnd);

            // Exclure les créneaux avant l'heure actuelle si la date est aujourd'hui
            const isPast = isToday && slotStart < currentMinutes;

            slots.push({
                start: convertToTime(slotStart),
                end: convertToTime(slotEnd),
                isDisabled: isInBreakTime || isTaken || isPast,
            });

            currentSlotMinutes += 30;
        }

        return slots;
    }


    const getEngableSlotCount = (slots) => {
        let count = 0;
        slots.forEach(element => {
            if (!element.isDisabled) count++;
        });
        return count;
    }

    useEffect(() => {
        if ((selectedCollaborateur && selectedDate) || (!selectedCollaborateur && selectedDate)) {
            constructSlots();
        }
    }, [selectedDate, selectedCollaborateur]); // Ajout de `duration` dans les dépendances pour recalculer les créneaux si nécessaire

    return (
        <React.Fragment>
            <div className="timeslots-container">
                <h3 className="text-center">{format(selectedDate, "EEEE d MMMM yyyy", { locale: fr })} {selectedTime && <span> à {selectedTime}</span>}</h3>
                {getEngableSlotCount(slots) === 0 && <h5>Pas de créneau disponible pour ce date!</h5>}
                {getEngableSlotCount(slots) > 0 && <>
                    <h4>Choix de l'heure</h4>
                    <div className="timeslots-grid">
                        {slots.map(({ start, isDisabled }) => {
                            if (!isDisabled) {
                                return <button
                                    key={start}
                                    className={`timeslot-button ${selectedTime === start ? 'selected' : ''}`}
                                    onClick={() => !isDisabled && handleTimeSelect(start)}
                                >
                                    {start}
                                </button>
                            }
                        })}
                    </div>
                </>
                }
            </div>
        </React.Fragment>
    );
}

export default TimeSlots;
