import React, { createContext, useContext, useState, useEffect } from "react";
import { showErrorMessage } from "../utils/messageHelper";
import userApi from "../api/user.api";
import { AppContext } from "../contexts/AppContext";
import { BASE_URL } from "../utils/constants";
export const TeleConseillerPortalContext = createContext();

export const TeleConseillerPortalProvider = ({ children }) => {
    const { connectedUser } = useContext(AppContext);
    const [profileData, setProfileData] = useState(null);
    const [isParentLoading, setIsParentLoading] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [activeAccordionSetting, setActiveAccordionSetting] = useState(0);

    const fetchTeleConseillerData = async (user_id) => {
        setIsParentLoading(true);
        try {
            const data = await userApi.fetchTeleConseillerUserByUserId(localStorage.getItem('token'), user_id);
            //console.log(data);
            if (data) {
                setProfileData(data);
                setTimeout(() => {
                    setIsParentLoading(false);
                }, 500);
            }
        } catch (error) {
            showErrorMessage("Impossible de charger les données de l'utilisateur!");
            setIsParentLoading(false);
        }
    }

    useEffect(() => {
        if (connectedUser) {
            fetchTeleConseillerData(connectedUser.id);
        }
    }, [connectedUser]);

    useEffect(() => {
        if (connectedUser) {
            fetchTeleConseillerData(connectedUser.id);
        }
    }, [reloadTrigger]);
    

    useEffect(() => {
        // if agree terms false
        if(profileData && !profileData.agree_terms) {
            window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/accept-terms`;
            return;
        }
    }, [profileData]);


    return <TeleConseillerPortalContext.Provider
        value={{
            profileData,
            isParentLoading,
            setReloadTrigger,
            activeAccordionSetting,
            setActiveAccordionSetting
        }}
    >
        {children}
    </TeleConseillerPortalContext.Provider>
};