import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { formatTime } from "../../../../utils/functions";
import { truncateText } from "../../../../utils/stringHelper";

// Fonction d'affichage du modal pour l'historique des rendez-vous
const ShowBeauticianHistoryModal = ({ appointment, onClose }) => {
    return (
        <Modal show={!!appointment} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{appointment?.first_name} {appointment?.last_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                {/* Affichage de la date et de l'heure du rendez-vous */}
                <p className="text-muted">
                    <strong>Date et Heure :</strong> {format(new Date(appointment?.appointment_date), 'EEEE d MMMM yyyy', { locale: fr })}
                </p>

                {/* Affichage des horaires du rendez-vous */}
                <p className="text-muted">
                    <strong>Horaires :</strong> {formatTime(appointment?.start_time)} - {formatTime(appointment?.end_time)}
                </p>

                {/* Affichage du statut du rendez-vous */}
                <p>
                    <strong>Statut :</strong> {' '}
                    <span className={`badge ${appointment?.status === 'pending'
                        ? 'bg-warning'
                        : appointment?.status === 'confirmed'
                            ? 'bg-primary'
                            : appointment?.status === 'completed'
                                ? 'bg-success'
                                : appointment?.status === 'cancelled'
                                    ? 'bg-danger'
                                    : 'bg-secondary'
                        }`}>
                        {appointment?.status === 'pending'
                            ? 'En attente'
                            : appointment?.status === 'confirmed'
                                ? 'Confirmé'
                                : appointment?.status === 'completed'
                                    ? 'Terminé'
                                    : appointment?.status === 'cancelled'
                                        ? 'Annulé'
                                        : 'Inconnu'}
                    </span>
                </p>

                <hr />

                {/* Affichage des services réservés */}
                <div className="mt-3">
                    <strong>A propos du client :</strong>

                    <div className="mt-2">
                        <p className="text-muted">
                            <strong>Nom complet :</strong> {appointment?.first_name} {appointment?.last_name}
                        </p>
                        <p className="text-muted">
                            <strong>Email :</strong> {appointment?.email}
                        </p>
                        <p className="text-muted">
                            <strong>Téléphone :</strong> {appointment?.phone}
                        </p>
                    </div>
                </div>

                <hr />

                {/* Affichage des services réservés */}
                <div className="mt-3">
                    <strong>Services réservés :</strong>
                    {appointment?.services && appointment.services.length > 0 ? (
                        appointment.services.map((service, index) => (
                            <div key={index} className="mb-2">
                                <p>
                                    <strong>{index + 1} - {service.subcategory_name}</strong> - {truncateText(service.subcategory_description, 50)}
                                </p>
                                <p className="text-muted">
                                    <strong>Prix :</strong> {service.subcategory_price} € {' '} | {' '}
                                    <strong>Durée :</strong> {service.subcategory_duration} minutes
                                    {
                                        appointment.is_planified_appointment ?
                                            <>{' '} | {' '}<strong>Rendez-vous planifié : </strong> {appointment.appointment_number}-ième rendez-vous</> :
                                            <>{service.is_multiple_appointment && <>{' '} | {' '}<strong>Rendez-vous planifié : </strong> Premier rendez-vous</>}</>
                                    }
                                </p>
                                <hr />
                            </div>
                        ))
                    ) : (
                        <p>Aucun service réservé.</p>
                    )}
                </div>

                {/* Affichage du paiement */}
                <div className="mt-3">

                    {appointment.promo_code_pro_id && (
                        <p className='mt-2'>
                            <b>
                                Le client a utilisé un code promo avec
                                une remise de {appointment.promo_discount_percentage}% .
                            </b>
                        </p>
                    )}

                    <strong>Montant total :</strong> {appointment?.total_prices} €
                    <br />
                    <strong>Montant payé :</strong> {appointment?.payed} €
                    <br />
                    <strong>Type de paiement :</strong> {appointment?.payment_type === "onsite" ? "Sur place" : "En ligne"}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShowBeauticianHistoryModal;
