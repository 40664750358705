import React, { useContext, useEffect } from 'react';
import {
  MessageCircle 
} from 'lucide-react';
import './TeleConseillerDashboard.css';
import { useNavigate } from 'react-router-dom';
import { TeleConseillerPortalContext } from '../../../context/TeleConseillerPortalContext';
import { useMessage } from '../../../contexts/MessageContext'; // Ajout du context message

const TeleConseillerDashboard = () => {
  const navigate = useNavigate();

  const { isParentLoading, profileData } = useContext(TeleConseillerPortalContext);

  const { unreadMessages } = useMessage(); // Ajout du hook useMessage


  useEffect(() => {
    const fetchDashboardData = async () => {
        
    };

    if(profileData) {
      fetchDashboardData();
    }

  }, [profileData]);


  return (
    <div className="dashboard">
      <div className="dashboard-wrapper">
      <header className="dashboard-header">
          <div className="header-content flex justify-between">
            <h1>Tableau de bord</h1>
            <div className="today-date">
              {new Date().toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </div>
              <div className="flex gap-2">
                <button 
                  className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white'
                  onClick={() => navigate('/messaging')}
                >
                  <MessageCircle size={16} /> Messages
                  {unreadMessages > 0 && (
                    <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                      {unreadMessages > 9 ? '9+' : unreadMessages}
                    </span>
                  )}
                </button>
              </div>
          </div>
        </header>

        <div className="">

        </div>
      </div>
    </div>
  );
};

export default TeleConseillerDashboard;
