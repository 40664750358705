import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { FaSave } from 'react-icons/fa';
import Swal from 'sweetalert2';
import beauticianApi from "../../api/beautician.api";

const AboutEtabOrBeautician = ({ activeID, about_text }) => {
    const [apropos, setApropos] = useState(about_text);

    const saveAproposEtab = async () => {
        if (!apropos.trim()) {
            Swal.fire({
                title: 'Champs manquants',
                text: "Le champ 'A propos' ne peut pas être vide.",
                icon: 'warning',
                confirmButtonText: 'OK',
            });
            return;
        }
        
        if (apropos.length >= 500) {
            Swal.fire({
                title: "Informations",
                text: "L'apropos ne doit pas depasser le 500 caractères",
                icon: 'warning'
            });
            return;
        }


        const data = {
            description: apropos
        }

        const response = await beauticianApi.updateBeautician(localStorage.getItem("token"), activeID, data);

        if (response.success) {
            Swal.fire({
                title: 'Succès!',
                text: 'Site mis à jour avec succès',
                icon: 'success',
                confirmButtonText: 'OK',
            });
        } else {
            Swal.fire({
                title: 'Erreur',
                text: 'Une erreur est survenue lors de l\'opération.',
                icon: 'error',
                confirmButtonText: 'Réessayer',
            });
        }

    }

    return (
        <React.Fragment>
            <Accordion.Header>A propos de l'ésthéticien(ne) ou de l'établissement</Accordion.Header>
            <Accordion.Body>
                <textarea
                    className="resize-none p-2 w-full rounded border shadow-sm"
                    value={apropos}
                    onChange={(e) => setApropos(e.target.value)}
                    placeholder="A propos de l'ésthéticienne ou de l'établissement"
                />
                <button
                    className="px-3 py-2 mt-2 ms-auto flex gap-2 items-center text-white bg-blue-600 rounded shadow"
                    onClick={() => saveAproposEtab()}
                >
                    <FaSave /> {'Enregistrer'}
                </button>
            </Accordion.Body>
        </React.Fragment>
    );
}

export default AboutEtabOrBeautician;