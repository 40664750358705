import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import './style.css'
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import Swal from "sweetalert2"
import beauticianApi from "../../../api/beautician.api"

const ShowBeauticianPaymentStatus = ({ showModal, handleClose, selectedBeautician, setTrigger }) => {
    const [beautician, setBeautician] = useState({})

    const status = {
        unpayed: "Non payé",
        payed: "Payé",
        upcoming: "À venir"
    }

    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ]

    const handlePayment = () => {
        let htmlContent = 'Êtes-vous sûre de valider ce paiment ?'

        Swal.fire({
            title: "Paiment",
            html: htmlContent,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await beauticianApi.updatePaymentStatus(beautician?.id, beautician?.payments?.date?.month, beautician?.payments?.date?.year)

                if(response.success) {
                    Swal.fire("Paiment", "Paiment effectuer!", "success")
                    setTrigger(prev => !prev)
                }
                else {
                    Swal.fire("Paiment", "Erreur s'est produite lors de la mise à jour du statut de paiment", "error")
                }

                handleClose()
            }
        })
    }

    // Utilise useEffect pour loguer uniquement à l'ouverture du modal
    useEffect(() => {
        if (showModal) {
            // console.log(selectedBeautician)
            setBeautician(selectedBeautician)
        }
    }, [showModal, selectedBeautician])

    return (
        <Modal show={showModal} onHide={handleClose} size="lg" className="my-modal-xl" centered>
            <Modal.Header>
                <Modal.Title>
                    Status de paiment de: {beautician?.business_name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-y-auto">
                <div className="">
                    <h2 className="text-xl font-bold text-gray-800">Paiment du mois de {months[beautician?.payments?.date?.month - 1]} {beautician?.payments?.date?.year}</h2>
                    
                    <h4 className="text-lg font-bold text-gray-800">Informations bancaire: </h4>
                    <p>Nom: {beautician?.bank_info_last_name}</p>
                    <p>Prénoms: {beautician?.bank_info_first_name}</p>
                    <p>IBAN: {beautician?.iban}</p>
                    <p>BIC: {beautician?.bic}</p>
                    <p>Lien Paypal : <span style={{userSelect: "text"}}>{beautician?.paypal_link}</span></p>

                    <h4 className="text-lg font-bold text-gray-800">Informations du paiment: </h4>
                    <p>Total du paiment: {beautician?.payments?.online}€</p>
                    <div className="flex items-center justify-between">
                        <p>
                            Status:
                            <span
                                className={`
                                    ${beautician?.payments?.status === "À venir"  && "text-yellow-500"}
                                    ${beautician?.payments?.status === "unpayed"  && "text-[#bd193b]"}
                                    ${beautician?.payments?.status === "payed"  && "text-green-500"}
                                `}
                            > {status[beautician?.payments?.status]}</span>
                        </p>

                        {beautician?.payments?.status === "unpayed" &&
                        beautician?.payments?.online > 0 &&
                            <button onClick={handlePayment} className="px-3 py-2 text-white bg-[#bd193b] rounded shadow">Effectuer le paiment en ligne</button>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Fermer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ShowBeauticianPaymentStatus
