import React from "react";
import './AbonnementCard.css';

const AbonnementCard = ({ title, price, image, features, onClick, is_dispo, m_auto = true, addButton = false, buttonText }) => {

    const handleClick = () => {
        if(addButton) {
            // ne rien faire
        } else {
            onClick();
        }
    }

    return (
        <div className={`tarif-card ${is_dispo ? 'cursor-pointer' : 'disabled cursor-not-allowed'} ${!m_auto && '!mx-0'}`} onClick={handleClick}>
            {!is_dispo && <div className="watermark">**Bientôt disponible**</div>}
            <h2>{title}</h2>
            <div className="price">
                <span className="amount">{price} €</span>
                <span className="period">/mois</span>
            </div>
            <ul className="features-list">
                {features.map((feature, index) => <li key={index}>{feature}</li>)}
            </ul>
            <div className="tarif-illustration">
                <img
                    src={image}
                    alt="Interface agenda"
                />
            </div>
            {addButton && 
            <button onClick={onClick} className="bg-[#bd193b] p-2 w-full text-center text-white rounded mt-3 transition-all delay-100 hover:bg-[#d83556] hover:scale-105">{buttonText ?? 'Souscrire'}</button>
            }
        </div>);
}

export default AbonnementCard;