import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { FaSave } from 'react-icons/fa';
import { Download, Trash } from "lucide-react";
import Swal from 'sweetalert2';
import { BASE_URL } from "../../utils/constants";
import { showErrorMessage, showSuccessMessage, showWarningMessage } from "../../utils/messageHelper";
import { Table } from "react-bootstrap";
import { beauticianDocumentsApi } from "../../api/beautician-documents.api";
import { truncateText } from "../../utils/stringHelper";



const DocumentsUpload = ({ activeID }) => {
    const [title, setTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [documents, setDocuments] = useState([]);
    const [localReload, setLocalReload] = useState(false);

    useEffect(() => {

        const fetchBeauticianDocuments = async () => {
            try {
                const res = await beauticianDocumentsApi.getByBauticianId(activeID, localStorage.getItem('token'));
                setDocuments(res);
            } catch (error) {
                showErrorMessage(error);
            }
        }

        fetchBeauticianDocuments();
    }, [localReload]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);
            setUploadStatus('');
        }
    };

    const handleDeleteDocument = async (id) => {
        Swal.fire({
            title: "Voullez vous vraiment supprimer le document?",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Non"
        }).then((result) => {
            if (result.isConfirmed) {
                const delete_image = beauticianDocumentsApi.deleteDocumentById(id, localStorage.getItem("token"));
                if (delete_image) {
                    setLocalReload(prev => !prev);
                    showSuccessMessage("Document supprimée avec succès!")
                } else {
                    showErrorMessage("Impossible de supprimer le document!");
                }
            }
        });
    };

    const saveDocuments = async () => {
        if (!title.trim()) {
            Swal.fire({
                title: 'Champs manquants',
                text: "Le champ 'Titre du document' ne peut pas être vide.",
                icon: 'warning',
                confirmButtonText: 'OK',
            });
            return;
        }

        if (!selectedFile) {
            showWarningMessage("Veuillez sélectionner une image pour le logo.");
            return;
        }

        Swal.fire({
            title: 'Chargement...',
            text: 'Veuillez patienter...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const save = await beauticianDocumentsApi.saveDocument(activeID, localStorage.getItem('token'), selectedFile, title);
            if (!save) {
                Swal.close();
                setTimeout(() => {
                    showErrorMessage("Échec lors de l'enregistrement du document!");
                }, 200);
            } else {
                Swal.close();
                setLocalReload((prev) => !prev);
                setTitle('');
                setSelectedFile(null);

                // Reset le champ de fichier
                document.getElementById('file').value = null;

                setTimeout(() => {
                    showSuccessMessage("Document enregistrée avec succès!");
                }, 200);
            }

        } catch (error) {
            Swal.close();
            setTimeout(() => {
                showErrorMessage(error || 'Une errreur s\'est produite!')
            }, 200);
        }
    }

    const getDocumentUrl = (filename) => {
        return `${BASE_URL}/api/beautician-documents/show/${encodeURIComponent(filename)}`;
    }

    const handleDownload = async (fileUrl, fileName) => {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = fileName || "document.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    return (
        <React.Fragment>
            <Accordion.Header>Mes documents</Accordion.Header>
            <Accordion.Body>
                <div className="">
                    <h5>Mes documents enregistrés</h5>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Titre</th>
                                <th>Nom du fichier</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.length > 0 && documents.map(document => (
                                <tr key={document.id}>
                                    <td>{document.title}</td>
                                    <td>{truncateText(document.file_url, 25)}</td>
                                    <td>
                                        <div className="flex items-center gap-3">

                                            {/* <a
                                                onClick={() => handleDownload(getDocumentUrl(document.file_url), document.title)}
                                                href={getDocumentUrl(document.file_url)}
                                                download={document.file_url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-blue-500 hover:text-blue-700 transition"
                                            >
                                                <Download size={16} />
                                            </a> */}
                                            <button
                                                onClick={() => handleDownload(getDocumentUrl(document.file_url), document.title)}
                                                className="text-blue-500 hover:text-blue-700 transition"
                                            >
                                                <Download size={16} />
                                            </button>
                                            <button
                                                onClick={() => handleDeleteDocument(document.id)}
                                                className="text-red-500 hover:text-red-700 transition"
                                            >
                                                <Trash size={16} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                            {documents.length === 0 && <tr>
                                <td colSpan={3}>Aucun document à afficher</td>
                            </tr>}
                        </tbody>
                    </Table>
                </div>
                <hr />
                <div className="mt-3">
                    <h5>Ajouter un document</h5>
                    <div className="w-full flex flex-col space-y-2 mb-3">
                        <label htmlFor="title" className="font-semibold">Titre du document :</label>
                        <input type="text"
                            className="p-2 w-full rounded border"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Titre du document"
                            id="title"
                        />
                    </div>
                    <div className="w-full flex flex-col space-y-2">
                        <label htmlFor="file" className="cursor-pointer font-semibold">
                            Choisir le document* (PDF ou image) :
                        </label>
                        <input
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={handleFileChange}
                            id="file"
                        />


                        {uploadStatus && <p className="mt-2 text-sm text-red-500">{uploadStatus}</p>}
                    </div>
                    <button
                        className="px-3 py-2 mt-2 ms-auto flex gap-2 items-center text-white bg-blue-600 rounded"
                        onClick={saveDocuments}
                    >
                        <FaSave /> {'Enregistrer'}
                    </button>
                </div>
            </Accordion.Body>
        </React.Fragment>
    );
}

export default DocumentsUpload;