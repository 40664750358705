import React, { useState, useEffect, useContext } from 'react';
import { Calendar } from 'lucide-react';
import './SelectNextAppointmentDate.css';
import CustomCalendar from '../../../booking/CustomCalendar';
import TimeSlots from '../../../booking/TimeSlots';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import availabilityApi from '../../../../api/availability.api';
import blockedDatesApi from '../../../../api/blockedDates.api';
import appointmentApi from '../../../../api/appointement.api';
import employeesApi from '../../../../api/employees.api';
import Swal from 'sweetalert2';

import { showErrorMessage } from '../../../../utils/messageHelper';
import MySpinner from '../../../loader/Spinner';
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';
import { Form } from 'react-bootstrap';

const SelectNextAppointmentDate = ({ service, selectedDate, selectedTime, setSelectedDate, setSelectedTime, nextAppointmentNumber, selectedCollaborateur, setSelectedCollaborateur }) => {

  const { profileData } = useContext(BeauticianPortalContext);

  const [isLoading, setIsLoading] = useState(true);
  const [dispoHebdo, setDispoHebdo] = useState([]);
  const [dispoHebdoTime, setDispoHebdoTime] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [blockedDatesWithTime, setBlockedDatesWithTime] = useState([]);
  const [validAppointments, setValidAppointments] = useState([]);
  const [collaborateurs, setCollaborateurs] = useState([]);

  function getFullyBlockedDays(blockedDates) {
    const fullyBlockedDays = [];

    blockedDates.forEach((entry) => {
      if (entry.start_time === null && entry.end_time === null) {
        // Convertir date_start et date_end en Date sans erreurs de fuseau
        const startDate = parseISO(entry.date_start);
        const endDate = parseISO(entry.date_end);

        // Obtenir toutes les dates entre startDate et endDate
        const days = eachDayOfInterval({ start: startDate, end: endDate });

        // Ajouter chaque jour au tableau sous forme de chaîne formatée (YYYY-MM-DD)
        days.forEach((day) => {
          const dayString = format(day, 'yyyy-MM-dd');
          if (!fullyBlockedDays.includes(dayString)) {
            fullyBlockedDays.push(dayString);
          }
        });
      }
    });

    return fullyBlockedDays;
  }


  const fetchBlockedDates = async (id) => {
    try {
      const response = await blockedDatesApi.getBlockedDates(id);
      if (response && Array.isArray(response)) {
        setBlockedDatesWithTime(response);
        let blocked_dates = getFullyBlockedDays(response);
        setBlockedDates(blocked_dates);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Erreur", "Impossible de charger les jours bloqués.", "error");
    }
  };

  const fetchAvailabilities = async (id) => {
    try {
      const response = await availabilityApi.getBeauticianAvailability(id);
      if (response && Array.isArray(response)) {
        const dispos = [];
        const disposTime = [];
        response.map((element) => {
          if (element.start_time !== null && element.end_time !== null) {
            dispos.push(element.day);
            disposTime.push(element);
          }
        });
        setDispoHebdo(dispos);
        setDispoHebdoTime(disposTime);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Erreur", "Impossible de charger les disponibilités.", "error");
    }
  };

  const fetchValidAppointments = async (id) => {
    try {
      const response = await appointmentApi.listAppointmentsValidByBeautician(id);
      if (response && Array.isArray(response)) {
        setValidAppointments(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchCollaborateurs = async (id) => {
    try {
      const response = await employeesApi.getAllEmployeesByBeauticianId(id);
      if (response.success && Array.isArray(response.data)) {
        setCollaborateurs(response.data);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Erreur", "Impossible de charger les collaborateurs.", "error");
    }
  };


  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const beauticianId = profileData.beautician_id;

      if (beauticianId) {

        try {
          await Promise.all([
            fetchAvailabilities(beauticianId),
            fetchBlockedDates(beauticianId),
            fetchValidAppointments(beauticianId),
            fetchCollaborateurs(beauticianId)
          ]);
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        } catch (error) {
          setIsLoading(false);
          showErrorMessage("Une erreur s'est produite lors du charmement des données!");
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="date-selection">
      {/* En-tête avec la durée totale */}
      <div className="date-selection-header flex justify-between gap-3 items-center">
        <div className="total-duration">
          <Calendar size={20} />
          <span>Durée totale : {service.subcategory_duration} min</span>
        </div>
        <span>Service : {service.subcategory_name}</span>
        <span>{nextAppointmentNumber}-ième rendez-vous</span>
        {profileData.type_beautician === "établissement" &&
          <div className="flex flex-col">
            <Form.Label htmlFor="collaborateur">Avec qui?</Form.Label>
            <Form.Select
              name="collaborateur"
              id="collaborateur"
              type="select"
              value={selectedCollaborateur}
              onChange={(e) => {
                setSelectedCollaborateur(e.target.value);
                setSelectedTime(null);
              }}
            >
              <option value="">Ne pas preciser</option>
              {collaborateurs.map(elem => <option key={elem.id} value={elem.id}>{elem.name}</option>)}
            </Form.Select>
          </div>
        }
      </div>

      {/* Navigation des dates */}
      {isLoading ?
        <MySpinner height={"55vh"} /> :
        (<>
          <div className="date-selection-header flex flex-col items-center gap-3">
            <h4>Selectionnez la date</h4>
            <CustomCalendar
              selectedDate={selectedDate}
              setSelectedDate={handleDateSelect}
              dispoHebdo={dispoHebdo}
              blockedDates={blockedDates}
            />
          </div>

          {/* Créneaux horaires */}
          {(selectedDate && dispoHebdoTime) && (
            <TimeSlots
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              handleTimeSelect={handleTimeSelect}
              dispoHebdoTime={dispoHebdoTime}
              duration={service.subcategory_duration}
              validAppointments={validAppointments}
              blockedDates={blockedDatesWithTime}
              collaborateurs={collaborateurs}
              selectedCollaborateur={selectedCollaborateur}
            />
          )}</>)}
    </div>
  );
};

export default SelectNextAppointmentDate;