import React, { useContext, useEffect, useState } from 'react'
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import {
  TrendingUp,
  Users,
  DollarSign,
  Clock,
  ChevronDown,
  Calendar
} from 'lucide-react'
import './BeauticianStats.css'
import Swal from 'sweetalert2'
import appointmentApi from '../../../api/appointement.api'
import availabilityApi from '../../../api/availability.api'
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext'

const BeauticianStats = () => {
  const { profileData } = useContext(BeauticianPortalContext);
  const [timeFrame, setTimeFrame] = useState('month')
  const [revenueData, setRevenueData] = useState([])
  const [servicesData, setServicesData] = useState([])
  const [kpis, setKpis] = useState([
    {
      title: "Chiffre d'affaires",
      value: "0€",
      // change: "",
      icon: <DollarSign size={24} />,
      trend: "up"
    },
    {
      title: "Clients",
      value: "0",
      // change: "0",
      icon: <Users size={24} />,
      trend: "up"
    },
    {
      title: "Taux occupation",
      value: "100%",
      // change: "0%",
      icon: <Clock size={24} />,
      trend: "up"
    },
    {
      title: "Rendez-vous",
      value: "0",
      // change: "0",
      icon: <Calendar size={24} />,
      trend: "up"
    }
  ])

  const updateKpi = (title, updates) => {
    setKpis(prevKpis =>
      prevKpis.map(k => (k.title === title ? { ...k, ...updates } : k))
    )
  }

  const getRevenueData = async () => {
    try {

      const result = await appointmentApi.getAppointmentByBeautician(profileData.beautician_id);

      // Nouveaux clients (ce mois uniquement)
      const currentMonth = new Date().getMonth()
      const currentYear = new Date().getFullYear()

      // Total rendez-vous
      const AllAppointement = result.length
      const AppointmentActiveMonth = result.reduce((uniqueAppointment, r) => {
        const createdDate = new Date(r.created_at)

        // Vérifie si le rendez-vous est dans le mois et l'année en cours
        if (createdDate.getMonth() === currentMonth && createdDate.getFullYear() === currentYear) {
          uniqueAppointment.push(r.client_id) // Ajoute l'ID du client au Set
        }

        return uniqueAppointment
      }, []).length

      const allClients = result.reduce((uniqueClients, r) => {
        uniqueClients.add(r.client_id)

        return uniqueClients
      }, new Set()).size // La taille du Set représente le nombre de clients uniques

      const nouveauxClients = result.reduce((uniqueClients, r) => {
        const createdDate = new Date(r.created_at)

        // Vérifie si le rendez-vous est dans le mois et l'année en cours
        if (createdDate.getMonth() === currentMonth && createdDate.getFullYear() === currentYear) {
          uniqueClients.add(r.client_id) // Ajoute l'ID du client au Set
        }

        return uniqueClients
      }, new Set()).size // La taille du Set représente le nombre de clients uniques

      // Chiffre d'affaires (rendez-vous terminés uniquement)
      const chiffreDAffaire = result.filter((r, i) => {
        const appointement_date = new Date(r.appointment_date)

        return (r.status === "completed" || r.status === "confirmed")
          && new Date(appointement_date) < new Date()
      })
        .reduce((acc, { total_prices }) => acc + parseFloat(total_prices || 0), 0)
      const chiffreDAffaireDuMois = result.filter((r => {
        const appointement_date = new Date(r.appointment_date)

        return (r.status === "completed" || r.status === "confirmed")
          && appointement_date.getMonth() === currentMonth
          && appointement_date.getFullYear() === currentYear
          && appointement_date < new Date()
      }))
        .reduce((acc, { total_prices }) => acc + parseFloat(total_prices || 0), 0)

      // Mise à jour des KPI
      updateKpi("Clients", {
        value: allClients,
        // change: nouveauxClients
      })
      updateKpi("Chiffre d'affaires", {
        value: `${chiffreDAffaire.toFixed(2)}€`,
        // change: `${chiffreDAffaireDuMois.toFixed(2)}€`
      })
      updateKpi("Rendez-vous", {
        value: AllAppointement,
        // change: AppointmentActiveMonth
      })

      // Liste des mois en français
      const monthNames = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ];

      // Fonction pour extraire le mois formaté
      function getMonthName(dateStr) {
        const month = new Date(dateStr).getMonth(); // Récupère l'indice du mois (0-11)
        return monthNames[month];
      }

      // Récupérer l'année actuelle
      const revenueByMonth = result.filter(r => {
        const appointement_date = new Date(r.appointment_date);

        // Filtrer les rendez-vous de l'année active
        return (r.status === "completed" || r.status === "confirmed") &&
          appointement_date < new Date() &&
          appointement_date.getFullYear() === currentYear; // Vérifier l'année
      })
        .reduce((acc, r) => {
          const month = getMonthName(r.appointment_date); // Utilisation de la fonction pour obtenir le mois formaté
          acc[month] = (acc[month] || 0) + parseFloat(r.total_prices || 0);
          return acc;
        }, {});

      setRevenueData(Object.entries(revenueByMonth).map(([month, revenue]) => ({ month, revenue: `${revenue}€` })))

    } catch (error) {
      Swal.fire("Erreur", error.message, "error")
    }
  }

  const getServicesData = async () => {
    try {

      // Récupération des sous-catégories du prestataire
      const beauticianAppointment = (await appointmentApi.getAppointmentByBeautician(profileData.beautician_id))
        .filter(r => {
          const appointement_date = new Date(r.appointment_date)

          return (r.status === "completed" || r.status === "confirmed") && appointement_date < new Date()
        })

      const serviceCount = beauticianAppointment.reduce((acc, appointment) => {
        const { service_name } = appointment;

        // Ajouter la durée au total du service
        if (!acc[service_name]) {
          acc[service_name] = {
            name: service_name,
            value: 1
          };
        } else {
          acc[service_name].value++; // Ajouter la durée au total si le service existe déjà
        }

        return acc;
      }, {});

      // Calcul du total de rendez-vous
      const totalCount = Object.values(serviceCount).reduce((sum, category) => sum + category.value, 0)

      // Mise à jour des données des services
      setServicesData(Object.values(serviceCount).map(category => ({
        name: category.name,
        value: category.value,
        percentage: ((category.value / totalCount) * 100).toFixed(2)
      })))

    } catch (error) {
      Swal.fire("Erreur", error.message, "error")
    }
  }

  const getBeauticianAvailability = async () => {
    try {

      // Récupération des données de disponibilité et des rendez-vous
      const [schedule, appointments] = await Promise.all([
        availabilityApi.getBeauticianAvailability(profileData.beautician_id),
        appointmentApi.getAppointmentByBeautician(profileData.beautician_id)
      ])

      // Jours bloqués et fériés (exemple codé en dur, à adapter selon votre logique)
      const blockedDays = [0, 6];  // Exemple : dimanche (0) et samedi (6) bloqués
      const holidays = ["2025-01-01", "2025-05-01"]; // Liste des jours fériés au format YYYY-MM-DD

      // Récupérer l'année et le mois en cours
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1  // Mois de 1 à 12

      // Filtrer les rendez-vous pour l'année et le mois en cours
      const filteredAppointments = appointments.filter(appointment => {
        const appointmentDate = new Date(appointment.start_time)
        const appointmentYear = appointmentDate.getFullYear()
        const appointmentMonth = appointmentDate.getMonth() + 1  // Mois de 1 à 12
        return appointmentYear === currentYear && appointmentMonth === currentMonth
      })

      // Calcul du total des minutes disponibles pour l'année et le mois
      const totalAvailableMinutes = schedule.reduce((total, day) => {
        const dayOfWeek = new Date(`1970-01-01T${day.start_time}Z`).getDay()  // Récupérer le jour de la semaine
        const isBlocked = blockedDays.includes(dayOfWeek)  // Vérifier si c'est un jour bloqué
        const currentDay = new Date().toISOString().split('T')[0]  // Date actuelle (au format YYYY-MM-DD)
        const isHoliday = holidays.includes(currentDay)  // Vérifier si c'est un jour férié
        if (isBlocked || isHoliday) return total;  // Exclure les jours bloqués et fériés

        // Exclusion des jours bloqués et fériés dans les calculs
        const workStart = new Date(`1970-01-01T${day.start_time}Z`)
        const workEnd = new Date(`1970-01-01T${day.end_time}Z`)
        const breakStart = new Date(`1970-01-01T${day.break_start}Z`)
        const breakEnd = new Date(`1970-01-01T${day.break_end}Z`)

        const morningMinutes = (breakStart - workStart) / (1000 * 60)
        const afternoonMinutes = (workEnd - breakEnd) / (1000 * 60)
        return total + morningMinutes + afternoonMinutes
      }, 0)

      // Calcul des minutes occupées par les rendez-vous confirmés pour l'année et le mois
      const takenMinutes = filteredAppointments.filter(appointment => appointment.status === "confirmed")
        .reduce((total, appointment) => {
          const start = new Date(`1970-01-01T${appointment.start_time}Z`)
          const end = new Date(`1970-01-01T${appointment.end_time}Z`)
          return total + (end - start) / (1000 * 60)
        }, 0)

      // Calcul du taux de disponibilité pour l'année et le mois
      const availabilityPercentage = ((takenMinutes / totalAvailableMinutes) * 100).toFixed(2)

      // Mise à jour de l'indicateur KPI
      updateKpi("Taux occupation mensuel", { value: `${availabilityPercentage}%` })
    } catch (error) {
      Swal.fire("Erreur", error.message, "error")
    }
  }


  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8']

  useEffect(() => {
    if(profileData) {
      getRevenueData()
      getBeauticianAvailability()
      getServicesData()
    }
  }, [profileData])

  return (
    <div className="stats-container">
      <div className="stats-header">
        <h1>Statistiques</h1>
        <div className="timeframe-selector">
          <select
            value={timeFrame}
            onChange={(e) => setTimeFrame(e.target.value)}
            className="timeframe-select"
          >
            <option value="week">Cette semaine</option>
            <option value="month">Ce mois</option>
            <option value="year">Cette année</option>
          </select>
          <ChevronDown size={20} className="select-icon" />
        </div>
      </div>

      <div className="kpi-grid">
        {kpis.map((kpi, index) => (
          <div key={index} className="kpi-card">
            <div className="kpi-icon">{kpi.icon}</div>
            <div className="kpi-info">
              <span className="kpi-title">{kpi.title}</span>
              <div className="kpi-stats">
                <span className="kpi-value">{kpi.value}</span>
                <span className={`kpi-change ${kpi.trend}`}>
                  {kpi.change}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="charts-grid">
        <div className="chart-card">
          <div className="chart-header">
            <h2>Chiffre d'affaires</h2>
            <TrendingUp size={20} />
          </div>
          <div className="chart-content">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={revenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  stroke="#0088FE"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="chart-card">
          <div className="chart-header">
            <h2>Répartition des services</h2>
          </div>
          <div className="chart-content">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={servicesData}
                  innerRadius={60}
                  outerRadius={100}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {servicesData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <div className="chart-legend">
              {servicesData.map((service, index) => (
                <div key={index} className="legend-item">
                  <span
                    className="legend-color"
                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                  />
                  <span className="legend-label">{service.name}</span>
                  <span className="legend-value">{service.percentage}%</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeauticianStats
