// src/api/appointment.api.js
import { API_URL } from "../utils/constants";

const appointmentApi = {
    // Rendez-vous par esthéticienne
    getAppointmentByBeautician: async (beauticianID) => {
        try {
            const response = await fetch(`${API_URL}/appointment/beautician/${beauticianID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },

    // Obtenir un rendez-vous spécifique
    getAppointmentById: async (appointmentID) => {
        try {
            const response = await fetch(`${API_URL}/appointment/${appointmentID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error(`Erreur lors de la recherche du rendez-vous ${appointmentID}`);
        }
    },

    // Créer une rendez-vous
    createAppointment: async (token, appointmentData, is_from_beautician_portal=false) => {
        try {
            const response = await fetch(`${API_URL}/appointment`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...appointmentData,
                    status: is_from_beautician_portal ? 'confirmed' : 'pending'
                })
            });
            return await response.json();
        } catch (error) {
            console.log(error);
            throw new Error('Erreur lors de la création du rendez-vous');
        }
    },

    createManualAppointment: async (token, appointmentData) => {
        try {
            const response = await fetch(`${API_URL}/appointment/create-manual`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...appointmentData,
                    status: 'confirmed'
                })
            });
            return await response.json();
        } catch (error) {
            console.log(error);
            throw new Error('Erreur lors de la création du rendez-vous');
        }
    },

    // Mises à jours du statut d'un rendez-vous
    updateAppointmentStatus: async (token, appointmentID, appointmentData) => {
        try {
            const response = await fetch(`${API_URL}/appointment/${appointmentID}/status`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(appointmentData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du statut du rendez-vous');
        }
    },

    cancelExpiredAppointment: async (token, appointment_id) => {
        try {
            const response = await fetch(`${API_URL}/appointment/cancel-expired`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({appointment_id: appointment_id})
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du statut du rendez-vous');
        }
    },
    
    // Mise à jours de la durée d'une
    updateAppointmentDuration: async(token, appointmentID, end_time, new_address, commentaire) => {
        try {
            const response = await fetch(`${API_URL}/appointment/duration/${appointmentID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    end_time: end_time,
                    new_address: new_address,
                    commentaire: commentaire 
                })
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du statut du rendez-vous');
        }

    },

    // Supprimer une rendez-vous
    deleteAppointment: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/appointment/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression du rendez-vous');
        }
    },

    listAppointmentsValidByBeautician: async (beauticianID) => {
        try {
            const response = await fetch(`${API_URL}/appointment/beautician/${beauticianID}/valid`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },

    listAppointmentsValidByBeauticianJoinUserAndServices: async (token, beauticianID) => {
        try {
            const response = await fetch(`${API_URL}/appointment/beautician/${beauticianID}/valid-with-jointure`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },

    listAppointmentsValidByUserIdJoinBeauticianAndServices: async (token, user_id) => {
        try {
            const response = await fetch(`${API_URL}/appointment/user/${user_id}/valid-with-jointure`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },
    
    getUserAppointmentHistory: async ({ userId, token, page = 1, limit = 10 }) => {
        
        try {
            const queryParams = new URLSearchParams({
                page,         // Page actuelle
                limit,        // Nombre de notifications par page
            }).toString();

            const response = await fetch(`${API_URL}/appointment/user/${userId}/history?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },

    getBeauticianAppointmentHistory: async ({ beauticianId, token, search = "" }) => {
        
        try {
            const queryParams = new URLSearchParams({
                search
            }).toString();

            const response = await fetch(`${API_URL}/appointment/beautician/${beauticianId}/history?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },

    getBeauticianClients: async (token, beautician_id) => {
        
        try {
            const response = await fetch(`${API_URL}/appointment/beautician/${beautician_id}/client`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des clients de l\'esthéticienne');
        }
    },

    isAppointmentAvailable: async (beautician_id, appointment_date, start_time, end_time, employee_id = null) => {
        try {
            // Construction des paramètres de requête
            const data = {
                beautician_id: beautician_id,
                appointment_date: appointment_date,
                start_time: start_time,
                end_time: end_time,
                employee_id: employee_id
            };

            // URL finale avec les paramètres encodés
            const url = `${API_URL}/appointment/is-appointment-available`;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`Erreur API : ${response.statusText}`);
            }

            return await response.json();
        } catch (error) {
            console.log(error);
            throw new Error("Erreur lors de la vérification des rendez-vous de l'esthéticienne");
        }
    },

    verifyIfNextAppointmentExist: async (token, beautician_id, user_id, service_id, appointment_id) => {
        try {
            const response = await fetch(`${API_URL}/appointment/verify-if-next-appointment-exist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    beautician_id: beautician_id,
                    user_id: user_id,
                    service_id: service_id,
                    appointment_id: appointment_id
                })
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des rendez-vous de l\'esthéticienne');
        }
    },

};

export default appointmentApi;