import React, { useEffect, useRef, useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import {
    UserCircle,
    ChevronDown,
    MessageCircle,
    Cog,
    LayoutDashboard,
    Users
} from 'lucide-react'
import { useMessage } from '../../../contexts/MessageContext' // Import du context message
import bgContact from '../../../assets/images/bg-contact.png'


const RecruteurPortalLayout = () => {
    const { unreadMessages } = useMessage(); // Ajout du hook pour les messages

    const [isMobile, setIsMobile] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)
    const menuRef = useRef(null);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768)
        setMenuOpened(window.innerWidth >= 768)
    }

    const handleMenu = () => {
        if(isMobile)
            setMenuOpened(prev => !prev)
    }

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const menuItems = [
        {
            title: "Tableau de bord",
            icon: <LayoutDashboard size={20} />,
            path: "/recruteur-dashboard/",
            exact: true, // Propriété pour activer uniquement ce chemin exact
        },
        {
            title: "Mon Profil",
            icon: <UserCircle size={20} />,
            path: "/recruteur-dashboard/profile",
        },
        {
            title: "Recrutements",
            icon: <Users size={20} />,
            path: "/recruteur-dashboard/beautician-management",
        },
        {
            title: "Messages",
            icon: <MessageCircle size={20} />,
            path: "/recruteur-dashboard/messaging", // Redirection directe vers /messaging
            suffix: unreadMessages > 0 ? (
                <div className="relative">
                    <span style={{
                        backgroundColor: 'red',
                        borderRadius: "50%",
                        padding: "0px 6px",
                        color: "white"
                    }}>{unreadMessages > 9 ? '9+' : unreadMessages}</span>
                </div>
            ) : null
        },
        {
            title: "Paramètres",
            icon: <Cog size={20} />,
            path: "/recruteur-dashboard/settings",
        }
    ]

    return (
        
        <div className="min-h-screen flex flex-col md:flex-row border" style={{
            backgroundImage: `url(${bgContact})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
        }}>
            {/* Sidebar */}
            <aside className="w-full md:w-1/6 flex flex-col border-r bg-white">
                <header
                    className="flex items-center justify-between cursor-pointer md:cursor-default"
                    onClick={handleMenu}
                >
                    <h1 className="p-3 text-xl text-[#bd193b] font-semibold border-b">BeautyPro</h1>
                    <button className="p-4 md:hidden">
                        <ChevronDown className={`cursor-pointer ${menuOpened && "rotate-180"} duration-300`} />
                    </button>
                </header>

                <div
                    ref={menuRef}
                    className="overflow-hidden duration-500 px-3 flex flex-col align-center justify-between gap-4 md:!height-auto"
                    style={{
                        height: menuOpened ? `${menuRef.current?.scrollHeight}px` : '0px',
                        transition: 'height 0.5s ease',
                    }}
                >
    
                    {menuItems.map((item, index) =>
                        <NavLink
                            key={index}
                            to={item.path}
                            end={item.exact}
                            className={({ isActive }) =>
                                `flex items-center gap-2 no-underline duration-300 cursor-pointer *:cursor-pointer ${isActive ? "text-[#bd193b]" : "text-gray-800"}` 
                            }
                        >
                            {item.icon}
                            <span className="flex items-center gap-2">
                                {typeof item.title === 'string' ? item.title : item.title}
                                {item.suffix}
                            </span>
                        </NavLink>
                    )}
                </div>
            </aside>

            {/* Main content */}
            <div className="w-full flex justify-center pr-0">
                <div className="w-full pr-0">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default RecruteurPortalLayout
