// searchUtils.js
export const searchCities = async (query) => {
    try {
      if (!query || query.length < 2) return [];
  
      let results = [];
      const isPostalCode = /^\d+$/.test(query);
  
      if (isPostalCode) {
        // Pour les codes postaux, on recherche directement avec l'API adresse
        const response = await fetch(
          `https://api-adresse.data.gouv.fr/search/?q=${query}&type=municipality&limit=10`
        );
        const data = await response.json();
  
        if (data.features) {
          results = data.features
            // Filtrer pour ne garder que les résultats dont le code postal commence par query
            .filter(feature => feature.properties.postcode.startsWith(query))
            .map(feature => ({
              text: `${feature.properties.city} (${feature.properties.postcode})`,
              type: 'ville',
              subtext: feature.properties.context,
              coordinates: feature.geometry.coordinates.reverse(),
              typeLabel: 'VILLE'
            }));
        }
      } else {
        // Pour les noms de villes
        const response = await fetch(
          `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&type=municipality&limit=10`
        );
        const data = await response.json();
  
        if (data.features) {
          results = data.features.map(feature => ({
            text: `${feature.properties.city} (${feature.properties.postcode})`,
            type: 'ville',
            subtext: feature.properties.context,
            coordinates: feature.geometry.coordinates.reverse(),
            typeLabel: 'VILLE'
          }));
        }
      }
  
      // Filtrer les doublons
      return results
        .filter((v, i, a) => a.findIndex(t => t.text === v.text) === i)
        .slice(0, 10);
  
    } catch (error) {
      console.error('Erreur lors de la recherche de villes:', error);
      return [];
    }
  };

export const searchAddresses = async (query) => {
  try {
      if (!query || query.length < 2) return [];

      const response = await fetch(
          `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(query)}&limit=5`
      );
      const data = await response.json();
      
      return data.features.map(feature => ({
          text: feature.properties.label,
          type: 'adresse',
          city: feature.properties.city,
          postcode: feature.properties.postcode,
          coordinates: feature.geometry.coordinates.reverse(),
          subtext: feature.properties.context
      }));
  } catch (error) {
      console.error('Erreur lors de la recherche d\'adresses:', error);
      return [];
  }
};

export const searchLocations = async (query, beauticians = []) => {
  try {
      if (!query || query.length < 2) return [];

      // Obtenir les résultats d'adresses et de villes
      const [addressResults, cityResults] = await Promise.all([
          searchAddresses(query),
          searchCities(query)
      ]);

      // Rechercher dans les beauticians avec le tableau passé en paramètre
      const beauticianResults = searchBeauticians(query, beauticians);

      // Combiner et limiter les résultats
      return [
          ...beauticianResults,
          ...addressResults,
          ...cityResults
      ].slice(0, 8);
  } catch (error) {
      console.error('Erreur lors de la recherche:', error);
      return [];
  }
};

const searchBeauticians = (query, beauticians) => {
  if (!Array.isArray(beauticians)) return [];
  
  const term = query.toLowerCase();
  return beauticians
      .filter(b => (
          b.business_name?.toLowerCase().includes(term) ||
          b.city?.toLowerCase().includes(term) ||
          b.category_names?.some(cat => cat.toLowerCase().includes(term))
      ))
      .map(b => ({
          text: b.business_name || '',
          subtext: `${b.city || ''} - ${(b.category_names || []).join(', ')}`,
          type: 'beautician',
          id: b.id,
          coordinates: [b.latitude || 0, b.longitude || 0]
      }));
};

export const filterBeauticians = ({
    searchTerm = '',
    services = [],
    priceRange = [],
    rating = 0,
    location = null,
    radius = 5000,
    beauticians = []
}) => {
    // console.log('Starting beautician filtering with:', {
    //     location,
    //     radius,
    //     totalBeauticians: beauticians.length
    // });

    return beauticians.filter(beautician => {
        // console.log(`Processing ${beautician.business_name}`);
        
        // Valider les données du beautician
        if (!beautician) {
            // console.log('Invalid beautician data');
            return false;
        }

        // Filtre par terme de recherche
        if (searchTerm && !matchesSearchTerm(beautician, searchTerm)) {
            return false;
        }

        // Filtre par services (catégories ou sous-catégories)
        if (services.length > 0 && !hasRequiredServices(beautician, services)) {
            return false;
        }

        // Autres filtres restent inchangés
        if (priceRange.length > 0 && !matchesPriceRange(beautician, priceRange)) {
            return false;
        }

        if (rating > 0) {
            const beauticianRating = parseFloat(beautician.average_rating) || 0;
            if (beauticianRating < rating) {
                return false;
            }
        }

        if (location && radius) {
            const withinRadius = isWithinRadius(beautician, location, radius);
            if (!withinRadius) {
                // console.log(`${beautician.business_name} outside radius`);
                return false;
            }
        }

        return true;
    });
};

const matchesSearchTerm = (beautician, term) => {
  const searchTerm = term.toLowerCase();
  const searchFields = [
      beautician.business_name,
      beautician.city,
      beautician.neighborhood,
      beautician.postal_code,
      beautician.description
  ];

  return searchFields.some(field => 
      field && field.toLowerCase().includes(searchTerm)
  );
};

const hasRequiredServices = (beautician, serviceIds) => {
    if (!serviceIds.length) return true;
    
    // console.log('Checking beautician:', beautician.business_name);
    // console.log('ServiceIds to check:', serviceIds);
    // console.log('Beautician subcategories:', beautician.subcategory_ids);
    
    return serviceIds.some(serviceId => {
        const numericId = Number(serviceId);
        
        // Si l'ID est une catégorie
        if (beautician.category_ids?.includes(numericId)) {
            // console.log(`Found category ${numericId} for ${beautician.business_name}`);
            return true;
        }
        
        // Si l'ID est une sous-catégorie
        if (beautician.subcategory_ids?.includes(numericId)) {
            // console.log(`Found subcategory ${numericId} for ${beautician.business_name}`);
            return true;
        }
        
        return false;
    });
};

const matchesPriceRange = (beautician, selectedRanges) => {
  if (!selectedRanges.length || !beautician.budget_category) {
      return true;
  }
  return selectedRanges.includes(beautician.budget_category);
};

const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3;
  const φ1 = lat1 * Math.PI/180;
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c;
};

const isWithinRadius = (beautician, location, radius) => {
    if (!location || !radius) return true;
    if (!beautician.latitude || !beautician.longitude) return false;
  
    const distance = getDistance(
        beautician.latitude,
        beautician.longitude,
        location.lat,
        location.lng
    );
  
    return distance <= radius;
  };