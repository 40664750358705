import React, { useState, useContext, useRef, useEffect } from 'react';
import { LogOut, Menu, UserCircle, ChevronDown, MessageCircle } from 'lucide-react'; // Ajouté MessageCircle
import './TeleConseillerPortalHeader.css';
import { REDIRECT_AFTER_LOGOUT } from '../../../utils/constants';
import Swal from 'sweetalert2';
import { AppContext } from '../../../contexts/AppContext';
import { NavLink, useNavigate } from 'react-router-dom';
import { TeleConseillerPortalContext } from '../../../context/TeleConseillerPortalContext';
import { useMessage } from '../../../contexts/MessageContext';


const TeleConseillerPortalHeader = ({
  isSidebarOpen,
  onSidebarToggle,
}) => {
  const { setIsLoggedIn } = useContext(AppContext);
  const { isParentLoading, profileData } = useContext(TeleConseillerPortalContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const { unreadMessages } = useMessage();

  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Référence pour le dropdown

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Effet pour gérer le clic à l'extérieur
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Si le clic est en dehors du dropdown, fermer le dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Ajouter l'écouteur d'événement au document
    document.addEventListener('click', handleOutsideClick);

    // Nettoyer l'écouteur lors du démontage
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Voulez vous vraiment se deconnecter?",
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        setIsLoggedIn(false);
        window.location.href = REDIRECT_AFTER_LOGOUT;
      }
    })
  }

  return (
    <header className="app-header">
      <div className="header-wrapper">
        {/* Left section */}
        <div className="header-left">
          <button className="menu-button" onClick={onSidebarToggle}>
            <Menu size={22} />
          </button>
        </div>

        {/* Right section */}
        <div className="header-right">

          <div className="user-menu relative" ref={dropdownRef}>
            <button
              className="user-profile-button"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown();
              }}
            >
              <UserCircle size={28} />
              <span className="user-name">{profileData?.first_name} {profileData?.last_name}</span>
              <ChevronDown size={16} />
              {unreadMessages > 0 && <span className="message-indicator" />} {/* S'assurer que cette ligne est présente */}
            </button>
            {showDropdown && (
              <div className="absolute mt-2 right-0 w-64 bg-gray-50 shadow-md rounded-lg p-2 z-50">
                <div className="text-sm text-gray-600 p-2">
                  <strong></strong> {profileData?.email}
                </div>
                {(!isParentLoading && profileData?.is_available) && <>
                  <NavLink 
                    to="/messaging" // Changé pour correspondre au routage global
                    className="dropdown-link"
                    onClick={() => {
                      setShowDropdown(false);
                      navigate('/messaging'); // Force la navigation vers la même route que le UserMenu
                    }}
                  >
                    <MessageCircle size={20} style={{ marginRight: '8px' }} />
                    Messages
                    {unreadMessages > 0 && (
                      <span className="message-count">
                        {unreadMessages > 99 ? '99+' : unreadMessages}
                      </span>
                    )}
                  </NavLink>
                  <div className="p-2 hover:bg-gray-100">
                    <a
                      href={`/conseillere-beaute/${profileData?.slug}`}
                      className="text-blue-600 no-underline hover:text-blue-800 text-sm"
                    >
                      Accéder à ma page
                    </a>
                  </div>

                </>
                }
              </div>
            )}
          </div>

          <button className="logout-button" aria-label="Déconnexion" onClick={handleLogout}>
            <LogOut size={20} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default TeleConseillerPortalHeader;