import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import abonnementApi from "../../../api/abonnement.api";
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';
import './style.css';

const ShowAbonnementByMonthModal = ({ selectedDate, show, onClose }) => {

    const [abonnements, setAbonnements] = useState(false);

    const fetchAbonnementByMonth = async () => {
        const res = await abonnementApi.getAbonnementByMonth(localStorage.getItem('token'), selectedDate.month, selectedDate.year);
        if (res) {
            setAbonnements(res);
        }
    }

    useEffect(() => {
        if(selectedDate && selectedDate?.month !== 0 && selectedDate.year !== 0) {
            fetchAbonnementByMonth();
        }
    }, [selectedDate]);

    return (
        <Modal show={show} onHide={onClose} size="xl" className="my-modal-xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Abonnements {format(new Date().setFullYear(selectedDate.year, selectedDate.month - 1), 'MMMM yyyy', {locale: fr}) }</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                {abonnements.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Utilisateur</th>
                                <th>Offre</th>
                                <th>Prix par Mois</th>
                                <th>Début</th>
                                <th>Fin</th>
                                <th>Code promo</th>
                                <th>Remise</th>
                                <th className="border border-gray-400 px-4 py-2">Total payé</th>
                            </tr>
                        </thead>
                        <tbody>
                            {abonnements.map((abonnement, index) => (
                                <tr key={abonnement.id}>
                                    <td>{index + 1}</td>
                                    <td>{abonnement.user_name}</td>
                                    <td>{abonnement.offre_name}</td>
                                    <td>{abonnement.price_per_month} €</td>
                                    <td>{new Date(abonnement.start_date).toLocaleDateString()}</td>
                                    <td>{new Date(abonnement.end_date).toLocaleDateString()}</td>
                                    <td>{abonnement.promo_code || '-'}</td>
                                    <td>{abonnement.promo_discount_percentage || '-'}</td>
                                    <td>{abonnement.is_attribued ? "Abonnement attribué" : (`${abonnement.promo_discount_percentage ? (abonnement.price_per_month * (1 - (abonnement.promo_discount_percentage / 100))) : abonnement.price_per_month} €`)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div className="text-center text-gray-500">
                        Aucun abonnement n'est disponible.
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShowAbonnementByMonthModal;