import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../contexts/AppContext';
import ContactForm from './ContactForm';
import "./Contact.css";
import "./ContactForm.css";

const Contact = () => {
  const [isContactMounted, setIsContactMounted] = useState(false);
  const { setActivePageForHeader } = useContext(AppContext);

  useEffect(() => {
    setIsContactMounted(true);
    window.scrollTo(0, 0);
    
    document.body.setAttribute('data-page', 'search');
    setActivePageForHeader("search");

    return () => {
      setIsContactMounted(false);
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);

  return (
    <>
      <Helmet>
        <title>Contact | PlaniBeauty</title>
        <meta name="description" content="Une question ? Notre équipe est à votre écoute. Contactez PlaniBeauty pour toute information sur nos services de réservation beauté." />
        <link rel="canonical" href="https://www.planibeauty.fr/contact" />
        <meta property="og:title" content="Contactez PlaniBeauty" />
        <meta property="og:description" content="Notre équipe est à votre disposition pour répondre à vos questions." />
      </Helmet>
      <div className={`contact ${isContactMounted ? "mounted" : ""}`}>
        <div className="contact__info"></div>
        <ContactForm />
      </div>
    </>
  );
};

export default Contact;