import React, { useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PlanifyNextAppointmentModal from './PlanifyNextAppointmentModal';
import CancelAppointmentModal from './CancelAppointmentModal';
import appointmentApi from '../../../../api/appointement.api';
import { showWarningMessage } from '../../../../utils/messageHelper';
import { getPaymentTypeString } from '../../../../utils/functions';
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';

const ShowInfoClientModal = ({ showModal, handleCloseModal, selectedEvent }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPlanifyNextAppointmentModal, setShowPlanifyNextAppointmentModal] = useState(false);
  const [selectedService, setSelectedService] = useState();
  const [nextAppointmentNumber, setNextAppointmentNumber] = useState(0);
  const { profileData } = useContext(BeauticianPortalContext);


  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  }

  const handleClosePlanifyNextAppointmentModal = () => {
    setShowPlanifyNextAppointmentModal(false);
  }

  const handleClickPlanifyNextAppointmentButton = async (service, _nextAppointmentNumber, number_of_appointment) => {

    // on verifie d'abord si le rendez-vous suivant existe (beautician id, client id, service, id de l'old appointment )
    const response = await appointmentApi.verifyIfNextAppointmentExist(
      localStorage.getItem('token'),
      profileData.beautician_id,
      selectedEvent.extendedProps.user_id,
      service.beautician_subcategory_id,
      selectedEvent.extendedProps.is_planified_appointment ? selectedEvent.extendedProps.old_appointment_id : selectedEvent.extendedProps.appointment_id,
    );

    if (response.data.length > 0) {
      let max_appointment_number = Math.max.apply(Math, response.data.map(function (o) { return o.appointment_number; }));
      if (max_appointment_number >= number_of_appointment) {
        showWarningMessage('Le nombre de rendez-vous planifiés est atteint pour ce service');
        return;
      }

      setNextAppointmentNumber(max_appointment_number + 1);
    } else {
      setNextAppointmentNumber(_nextAppointmentNumber);
    }


    setSelectedService(service);
    setShowPlanifyNextAppointmentModal(true);
  }

  return (
    <>
      {/* Modale principale */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdropClassName="main-backdrop"
        size='lg'
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>A propos du rendez-vous</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-y-scroll">
          <div className="mb-3">
            <strong className="text-primary">Nom du client :</strong>
            <p>
              {selectedEvent.extendedProps.first_name}{' '}
              {selectedEvent.extendedProps.last_name}
            </p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Email :</strong>
            <p>{selectedEvent.extendedProps.email}</p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Téléphone :</strong>
            <p>{selectedEvent.extendedProps.phone || 'Non spécifié'}</p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Date :</strong>
            <p>
              {selectedEvent.start.toLocaleDateString('fr-FR', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Heure :</strong>
            <p>
              {selectedEvent.extendedProps.start_time.slice(0, 5)}{' '}
              -{' '}
              {selectedEvent.extendedProps.end_time.slice(0, 5)}
            </p>
          </div>
          <div className="mb-3">
            <strong className="text-primary">Services :</strong>
            {selectedEvent.extendedProps.services && (
              <ul>
                {selectedEvent.extendedProps.services.map((service, index) => (<React.Fragment key={index}>
                  {!service.is_multiple_appointment ? (
                    <li key={index}>
                      {service.subcategory_name} - {service.subcategory_price}€{' '}
                      ({service.subcategory_duration} min)
                    </li>) : (
                    <li key={index}>
                      {service.subcategory_name} - {service.subcategory_price}€{' '}
                      ({service.subcategory_duration} min) <br />
                      {selectedEvent.extendedProps.user_id ? <>
                        {
                          (selectedEvent.extendedProps.is_planified_appointment ? `${selectedEvent.extendedProps.appointment_number}-ième rendez-vous` : 'Premier rendez-vous')} sur {service.number_of_appointment} rendez-vous<br />
                        {(parseInt(service.number_of_appointment) - parseInt(selectedEvent.extendedProps.appointment_number))} rendez-vous restant(s) <br />
                        {parseInt(selectedEvent.extendedProps.appointment_number) < parseInt(service.number_of_appointment) && (
                          <button
                            className='px-3 py-2 rounded bg-blue-600 text-white text-sm hover:bg-blue-600'
                            onClick={() => handleClickPlanifyNextAppointmentButton(service, parseInt(selectedEvent.extendedProps.appointment_number) + 1, service.number_of_appointment)}
                          >Organiser le prochain rendez-vous</button>)}
                      </> :
                        <span>Rendez-vous multiple planifié manuellement, pour planifier le prochain rendez-vous, organiser un autre rendez-vous manuellement!</span>
                      }
                    </li>
                  )
                  }
                  <hr />
                </React.Fragment>
                )
                )}
              </ul>
            )}
          </div>
          {selectedEvent.extendedProps.collaborateur &&
            <div className="mb-3">
              <strong className="text-primary">Collaborateur :</strong>
              <p>
                {selectedEvent.extendedProps.collaborateur}
              </p>
            </div>
          }
          <div className="mb-3">
            <strong className="text-primary">Statut du paiement :</strong>

            {selectedEvent.extendedProps.promo_code_pro_id && (
              <p className='mt-2'>
                Le client a utilisé le code promo <b>{selectedEvent.extendedProps.promo_code}</b> avec
                une remise de {selectedEvent.extendedProps.promo_discount_percentage}% .
              </p>
            )}

            {selectedEvent.extendedProps.is_planified_appointment ? (
              selectedEvent.extendedProps.user_id ?
                <p>Déjà payé lors du premier rendez-vous</p> :
                <p>Rendez-vous planifié manuellement</p>
            ) :
              <p>
                Type de paiement :{' '}
                {getPaymentTypeString(selectedEvent.extendedProps.payment_type)}
                <br />
                Total à payer : {selectedEvent.extendedProps.total_prices} €
                <br />
                Payé : {selectedEvent.extendedProps.payed} €
                <br />
                Reste :{' '}
                {(
                  Number(selectedEvent.extendedProps.total_prices) -
                  Number(selectedEvent.extendedProps.payed)
                ).toFixed(2)}{' '}
                €
              </p>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowCancelModal(true)}>
            Annuler le rendez-vous
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal d'annulation */}
      <CancelAppointmentModal
        showModal={showCancelModal}
        handleCloseModal={handleCloseCancelModal}
        selectedEvent={selectedEvent}
        handleCloseParentModal={handleCloseModal}
      />

      {/* Modal pour le planification du prochain rendez-vous */}
      <PlanifyNextAppointmentModal
        showModal={showPlanifyNextAppointmentModal}
        handleCloseModal={handleClosePlanifyNextAppointmentModal}
        service={selectedService}
        nextAppointmentNumber={nextAppointmentNumber}
        old_appointment={
          {
            appointment_date: selectedEvent.extendedProps.appointment_date,
            client_first_name: selectedEvent.extendedProps.first_name,
            client_last_name: selectedEvent.extendedProps.last_name,
            client_phone: selectedEvent.extendedProps.phone,
            client_email: selectedEvent.extendedProps.email,
            client_id: selectedEvent.extendedProps.user_id,
            appointment_id: selectedEvent.extendedProps.is_planified_appointment ? selectedEvent.extendedProps.old_appointment_id : selectedEvent.extendedProps.appointment_id,
          }
        }
      />
    </>
  );
};

export default ShowInfoClientModal;
