import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMessage } from '../../contexts/MessageContext';
import './UserMenu.css';

const UserMenu = ({ firstName, lastName, userType, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { unreadMessages } = useMessage();
  const isDashboardEnabled = userType === 'admin' || userType === 'operator';
  const dashboardUrl = "/dashboard";
  const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();

  // Fermer le menu lors du changement de route
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const getUserRoleLabel = (type) => {
    const roles = {
      'admin': 'Administrateur',
      'operator': 'Opérateur',
      'beautician': 'Esthéticienne',
      'client': 'Client'
    };
    return roles[type] || 'Utilisateur';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    setIsOpen(false);
    if (onLogout) onLogout();
  };

  return (
    <div className="user-menu-container" ref={menuRef}>
      <button
        className="user-avatar"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Menu utilisateur"
      >
        {initials}
        {unreadMessages > 0 && (
          <span className="unread-indicator" />
        )}
      </button>

      {isOpen && (
        <>
          <div className="menu-backdrop" onClick={() => setIsOpen(false)} />

          <div className="user-dropdown">
            <div className="user-info">
              <div className="user-avatar-large">{initials}</div>
              <div className="user-details">
                <div className="user-name">{`${firstName} ${lastName}`}</div>
                <div className="user-role">{getUserRoleLabel(userType)}</div>
              </div>
            </div>

            <div className="dropdown-divider" />

            <div className="dropdown-actions">
              {isDashboardEnabled && (
                <>
                  <button
                    onClick={() => handleNavigation(dashboardUrl)}
                    className="logout-button"
                    style={{ marginBottom: '10px' }}
                  >
                    <span className="logout-icon">📊</span>
                    Dashboard
                  </button>
                  <div className="dropdown-divider" />
                </>
              )}

              {userType === "client" && (
                <button
                  onClick={() => handleNavigation("/client-dashboard")}
                  className="logout-button"
                  style={{ marginBottom: '10px' }}
                >
                  <span className="logout-icon">📊</span>
                  Dashboard
                </button>
              )}

              {userType === "beautician" && (
                <button
                  onClick={() => handleNavigation("/beautician-dashboard")}
                  className="logout-button"
                  style={{ marginBottom: '10px' }}
                >
                  <span className="logout-icon">📊</span>
                  Dashboard
                </button>
              )}

              {userType === "recruteur" && (
                <button
                  onClick={() => handleNavigation("/recruteur-dashboard")}
                  className="logout-button"
                  style={{ marginBottom: '10px' }}
                >
                  <span className="logout-icon">📊</span>
                  Dashboard
                </button>
              )}

              {userType === "tele_conseiller" && (
                <button
                  onClick={() => handleNavigation("/tele-conseiller-dashboard")}
                  className="logout-button"
                  style={{ marginBottom: '10px' }}
                >
                  <span className="logout-icon">📊</span>
                  Dashboard
                </button>
              )}

              <button
                onClick={() => handleNavigation('/messaging')}
                className="logout-button message-button"
                style={{ marginBottom: '10px', position: 'relative' }}
              >
                <span className="logout-icon">💬</span>
                Messages
                {unreadMessages > 0 && (
                  <span className="message-count">
                    {unreadMessages > 9 ? '9+' : unreadMessages}
                  </span>
                )}
              </button>

              <div className="dropdown-divider" />
              <button
                className="logout-button"
                onClick={handleLogout}
              >
                <span className="logout-icon">→</span>
                Déconnexion
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserMenu;