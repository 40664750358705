import React, { useState, useContext, useEffect } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import blockedDatesApi from "../../../api/blockedDates.api";
import moment from 'moment';
import { FaTrash } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import { truncateText } from "../../../utils/stringHelper";
import { formatTime } from "../../../utils/functions";

const BlockedDay = ({ activeID }) => {
    const [blockedDates, setBlockedDates] = useState([]);
    const [showBlockedModal, setShowBlockedModal] = useState(false);
    const [selectedBlockedDate, setSelectedBlockedDate] = useState(null);

    const token = localStorage.getItem('token');
    const minDate = moment().add(1, 'day').format('YYYY-MM-DD');
    const maxDate = moment().add(1, 'year').format('YYYY-MM-DD');

    const fetchBlockedDates = async () => {
        try {
            const response = await blockedDatesApi.getBlockedDates(activeID);
            if (response && Array.isArray(response)) {
                setBlockedDates(response);
            }
        } catch (error) {
            Swal.fire("Erreur", "Impossible de charger les jours bloqués.", "error");
        }
    };

    useEffect(() => {
        fetchBlockedDates();
    }, [activeID]);

    const handleOpenBlockedModal = (blockedDate = null) => {
        if (blockedDate) {
            // Formater les dates pour le préremplissage
            const formattedDate = {
                ...blockedDate,
                date_start: moment(blockedDate.date_start).format('YYYY-MM-DD'),
                date_end: blockedDate.date_end
                    ? moment(blockedDate.date_end).format('YYYY-MM-DD')
                    : '',
            };
            setSelectedBlockedDate(formattedDate);
        } else {
            // Créer un nouvel objet vide avec des valeurs par défaut
            setSelectedBlockedDate({
                date_start: minDate,
                date_end: minDate,
                start_time: '',
                end_time: '',
                reason: ''
            });
        }
        setShowBlockedModal(true);
    };

    const handleCloseBlockedModal = () => {
        setShowBlockedModal(false);
        setSelectedBlockedDate(null);
    };

    const handleBlockedInputChange = (field, value) => {
        if (field === 'date_start') {
            setSelectedBlockedDate(prev => ({
                ...prev,
                [field]: value,
                ['date_end']: value, // on change aussi le date_end
            }));
        } else {
            setSelectedBlockedDate(prev => ({
                ...prev,
                [field]: value
            }));
        }
    };

    const validateBlockedDate = () => {
        const { date_start, date_end, start_time, end_time } = selectedBlockedDate;

        if (!date_start || !date_end) {
            Swal.fire("Erreur", "La date de début et date fin sont obligatoires.", "error");
            return false;
        }

        if (moment(date_start).isBefore(minDate)) {
            Swal.fire("Erreur", "La date de début doit être égale ou postérieure à aujourd'hui.", "error");
            return false;
        }

        if (moment(date_start).isAfter(maxDate)) {
            Swal.fire("Erreur", "La date de début ne peut pas dépasser une année à partir d'aujourd'hui.", "error");
            return false;
        }

        if (date_end && moment(date_end).isBefore(date_start)) {
            Swal.fire("Erreur", "La date de fin doit être égale ou postérieure à la date de début.", "error");
            return false;
        }

        if((start_time && !end_time) || (!start_time && end_time)) {
            Swal.fire("Erreur", "L'heure début et l'heure de fin sont obligatoire si l'un d'entre eux est défini!", "error");
            return false;
        }

        if (start_time && end_time && start_time >= end_time) {
            Swal.fire("Erreur", "L'heure de début doit être inférieure à l'heure de fin.", "error");
            return false;
        }

        return true;
    };

    const handleSaveBlockedDate = async () => {
        if (!validateBlockedDate()) return;

        const payload = {
            ...selectedBlockedDate,
            // Conserver uniquement la partie date sans heure
            date_start: moment(selectedBlockedDate.date_start).format('YYYY-MM-DD'),
            date_end: selectedBlockedDate.date_end
                ? moment(selectedBlockedDate.date_end).format('YYYY-MM-DD')
                : null,
        };


        try {
            if (selectedBlockedDate.id) {
                // Mise à jour d'un jour bloqué
                const response = await blockedDatesApi.updateBlockedDate(
                    selectedBlockedDate.id,
                    payload,
                    token
                );
                if (!response.error) {
                    setBlockedDates((prev) =>
                        prev.map((date) =>
                            date.id === selectedBlockedDate.id
                                ? { ...date, ...payload }
                                : date
                        )
                    );
                    Swal.fire("Succès", "Jour bloqué mis à jour avec succès.", "success");
                }
            } else {
                // Création d'un nouveau jour bloqué
                const response = await blockedDatesApi.createBlockedDate(
                    activeID,
                    payload,
                    token
                );
                if (!response.error) {
                    setBlockedDates((prev) => [...prev, response]);
                    Swal.fire("Succès", "Jour bloqué ajouté avec succès.", "success");
                }
            }
            handleCloseBlockedModal();
        } catch (error) {
            console.log(error);
            Swal.fire("Erreur", "Impossible de sauvegarder le jour bloqué.", "error");
        }
    };

    const handleDeleteBlockedDate = async (id) => {
        Swal.fire({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Annuler",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await blockedDatesApi.deleteBlockedDate(id, token);
                    if (!response.error) {
                        setBlockedDates((prev) =>
                            prev.filter((date) => date.id !== id)
                        );
                        Swal.fire("Succès", "Jour bloqué supprimé avec succès.", "success");
                    }
                } catch (error) {
                    Swal.fire("Erreur", "Impossible de supprimer le jour bloqué.", "error");
                }
            }
        });
    };

    const formatDate = (date) => {
        if (!date) return "";
        return moment(date).format('DD/MM/YYYY');
    };

    return (
        <React.Fragment>
            <h4 className="text-xl sm:text-2xl">Jours et plages d'indisponibilités</h4>
            <Button variant="primary" onClick={() => handleOpenBlockedModal()}>
                Ajouter une période bloquée
            </Button>
            <div className="w-full overflow-x-scroll">
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>Date(s)</th>
                            <th>Heure</th>
                            <th>Raison</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {blockedDates.map((item) => (
                            <tr key={item.id}>
                                <td>{formatDate(item.date_start)} {(item.date_end && item.date_end !== item.date_start) && `au ${formatDate(item.date_end)}`}</td>
                                <td>{(item.start_time && item.end_time) ? (formatTime(item.start_time) + " à " + formatTime(item.end_time)) : "Journée entière"}</td>
                                <td>{item.reason ? truncateText(item.reason, 20) : "-"}</td>    
                                <td className="flex justify-center gap-2 items-center">
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        onClick={() => handleOpenBlockedModal(item)}
                                    >
                                        <FaPencil />
                                    </Button>{" "}
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => handleDeleteBlockedDate(item.id)}
                                    >
                                        <FaTrash />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {showBlockedModal &&
                <Modal show={showBlockedModal} onHide={handleCloseBlockedModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {selectedBlockedDate?.id
                                ? "Modifier"
                                : "Ajouter"}{" "}
                            une période bloquée
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="overflow-y-scroll">
                        <div>
                            <div className="mb-3">
                                <label>Date de début* :</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={selectedBlockedDate?.date_start || ""}
                                    min={minDate}
                                    max={maxDate}
                                    onChange={(e) =>
                                        handleBlockedInputChange("date_start", e.target.value)
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <label>Date de fin* :</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={selectedBlockedDate?.date_end || ""}
                                    min={selectedBlockedDate?.date_start || minDate}
                                    max={maxDate}
                                    onChange={(e) =>
                                        handleBlockedInputChange("date_end", e.target.value)
                                    }
                                />
                                <div>
                                    <small className="text-yellow-600">Si la date de fin est identique à la date de début, cela signifie que c'est un jour unique.</small>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-between">
                            <div>
                                <label>Heure de début (optionnel) :</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    value={selectedBlockedDate?.start_time || ""}
                                    onChange={(e) =>
                                        handleBlockedInputChange("start_time", e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label>Heure de fin (optionnel) :</label>
                                <input
                                    type="time"
                                    className="form-control"
                                    value={selectedBlockedDate?.end_time || ""}
                                    onChange={(e) =>
                                        handleBlockedInputChange("end_time", e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <small className="text-yellow-600">Si l'heure de début et de fin ne sont pas précisés, cela signifie que la journée est entièrement bloquée.</small>
                        <div className="mt-3">
                            <label>Raison (optionnel) :</label>
                            <input
                                type="text"
                                className="form-control"
                                value={selectedBlockedDate?.reason || ""}
                                onChange={(e) =>
                                    handleBlockedInputChange("reason", e.target.value)
                                }
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseBlockedModal}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={handleSaveBlockedDate}>
                            Sauvegarder
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </React.Fragment>
    );
};

export default BlockedDay;