import React, { useState, useRef, useEffect, useContext } from 'react';
import './SignupDropdown.css';
import { AppContext } from '../../contexts/AppContext';

const SignupDropdown = ({ isAuthenticated, setIsSignupModalOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const {setSignUpUserType} = useContext(AppContext);

  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200); // Petit délai pour permettre le mouvement vers le menu
  };

  const handleSignupModal = (e, type) => {
    e.preventDefault();
    setIsOpen(false);
    setIsSignupModalOpen(true);
    setSignUpUserType(type);
  }

  // Nettoyer le timeout quand le composant est démonté
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (isAuthenticated) return null;

  return (
    <div 
      className="signup-dropdown-container"
      ref={dropdownRef}
    >
      <button 
        className="signup-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => window.location.href = '/ajouter-etablissement'}
      >
        S'inscrire
      </button>
      {isOpen && (
        <div 
          className="signup-dropdown-menu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="dropdown-menu-header">
            Choisissez votre profil
          </div>
          <a 
            href="/ajouter-etablissement?open=indep" 
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je suis un professionnel</div>
              <div className="dropdown-item-description">Créez votre profil professionnel</div>
            </div>
          </a>
          <a 
            href="/ajouter-etablissement?open=etab" 
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je suis un établissement</div>
              <div className="dropdown-item-description">Inscrivez votre établissement</div>
            </div>
          </a>
          <a 
            href="/ajouter-etablissement?open=tele_conseiller" 
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je suis une conseillère beauté</div>
              <div className="dropdown-item-description">Créez votre profil professionnel</div>
            </div>
          </a>
          <a 
            href="/signup"
            onClick={(e) => handleSignupModal(e, "client")}
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je suis un client</div>
              <div className="dropdown-item-description">Créez votre compte personnel</div>
            </div>
          </a>
          <a 
            href="/signup"
            onClick={(e) => handleSignupModal(e, "recruteur")}
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je suis un recruteur</div>
              <div className="dropdown-item-description">Créez votre compte personnel</div>
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default SignupDropdown;