import React from "react";
import { Modal, Button } from "react-bootstrap";
import OpenTime from "../../../dashboard/siteInfoAgenda/OpenTime";

const EditAvailabilityModal = ({ show, handleClose, beautician_id }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Mes disponibilités</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-y-scroll p-0 sm:p-2">
        <OpenTime activeID={beautician_id} is_modal={true} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAvailabilityModal;
