import { motion } from 'motion/react'
import React, { useEffect, useState } from 'react'
import MySpinner from "../../loader/Spinner";
import {
    Edit,
    Key,
    UserCircle,
    Mail,
    Phone,
    Cog
} from "lucide-react"
import EditModal from '../client/EditModal';
import userApi from '../../../api/user.api';
import ChangePasswordModal from '../client/ChangePasswordModal';
import ChangeEmailModal from '../client/ChangeEmailModal';


const Profile = () => {
    const [user, setUser] = useState({ email: "", first_name: "", last_name: "", phone: "" });
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
    const [data, setData] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    // const [showCancelModal, setShowCancelModal] = useState(false); // Gère la modale d'annulation
    // const [cancelReason, setCancelReason] = useState(''); // Stocke la raison de l'annulation
    // const [isSubmitting, setIsSubmitting] = useState(false); // Gère l'état de soumission
    // const [validationError, setValidationError] = useState(''); // Stocke les erreurs de validation

    const getUserInfo = async () => {
        setIsLoading(true);
        const user = await userApi.getProfile(localStorage.getItem('token'));
        if (user) {
            setUser(user);
        }

        setIsLoading(false);
    }


    useEffect(() => {
        getUserInfo();
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [reloadTrigger]);

    return (<React.Fragment>
        {isLoading ? <MySpinner height={"50vh"} /> :
            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: .25 }}
                className="w-full flex items-center justify-center p-4"
            >
                <aside className="bg-white/80 backdrop-blur-sm p-8 w-full flex flex-wrap rounded-2xl shadow-lg">

                    <div className="flex flex-col w-full items-center gap-2">

                        <div className="bg-gradient-to-r from-[#bd193b] to-pink-500 p-1 rounded-full">
                            <div className="bg-white p-2 rounded-full">
                                <UserCircle size={80} className="mb-2 text-[#bd193b] size-20 md:size-100" />
                            </div>
                        </div>

                        <h2 className="text-gray-800 text-2xl sm:text-3xl font-semibold tracking-wide text-center">{`${user.last_name} ${user.first_name}`}</h2>
                        <div className="space-y-4 w-full max-w-sm">
                            <div className="flex items-center justify-center gap-3">
                                <Mail className="w-5 h-5 text-[#bd193b]" />
                                <span className="text-gray-600 text-lg sm:text-xl font-light">{user.email}</span>
                            </div>
                            <div className="flex items-center justify-center gap-3">
                                <Phone className="w-5 h-5 text-[#bd193b]" />
                                <span className="text-gray-600 text-lg sm:text-xl font-light">{user.phone}</span>
                            </div>
                        </div>

                        <div className="w-full border-t border-gray-100 my-4"></div>

                        <div className="flex flex-col gap-4 w-full max-w-sm">
                            <button
                                onClick={() => setOpenModal(true)}
                                className="flex items-center justify-center gap-3 px-6 py-3 bg-white text-[#bd193b] rounded-xl shadow-sm border border-gray-100 transition-all hover:shadow-md hover:scale-[1.02]"
                            >
                                <Edit className="w-5 h-5" /> Modifier mes informations
                            </button>
                        </div>
                    </div>
                </aside>

                <EditModal
                    openModal={openModal}
                    user={user}
                    setOpenModal={setOpenModal}
                    setReloadTrigger={setReloadTrigger}
                />

                <ChangePasswordModal
                    openModal={openChangePasswordModal}
                    setOpenModal={setOpenChangePasswordModal}
                />

                <ChangeEmailModal
                    openModal={openChangeEmailModal}
                    setOpenModal={setOpenChangeEmailModal}
                    setReloadTrigger={setReloadTrigger}
                />

            </motion.section>}
    </React.Fragment>
    )
}

export default Profile
