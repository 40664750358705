import React, { useEffect, useContext } from 'react'
import ClientPortalLayout from './ClientPortalLayout'
import { Routes, Route } from 'react-router-dom';
import Profile from '../../../components/portals/client/Profile';
import AppointmentList from '../../../components/portals/client/AppointmentList';
import Historique from '../../../components/portals/client/Historique';
import Notification from '../../../components/portals/client/Notifications';
import Preferences from '../../../components/portals/client/Preferences';
import { AppContext } from '../../../contexts/AppContext';
import Messaging from '../../message/Messaging';
import { BASE_URL } from '../../../utils/constants';


const ClientPortal = () => {
	const { setActivePageForHeader, connectedUser } = useContext(AppContext);

	useEffect(() => {
		// Indiquer explicitement qu'on n'est pas sur la home
		setActivePageForHeader("");
		// Nettoyage au démontage du composant
		return () => {
			setActivePageForHeader("");
		};
	}, [setActivePageForHeader]);

	useEffect(() => {
		if (connectedUser) {
			if (!connectedUser.agree_terms) {
				// redirect to dashboard
				window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/accept-terms`;
			}
		}
	}, [connectedUser]);

	return (
		<Routes>
			<Route element={<ClientPortalLayout />}>
				<Route index element={<Profile />} />
				<Route path="agenda" element={<AppointmentList />} />
				<Route path="historiques" element={<Historique />} />
				<Route path="notifications" element={<Notification />} />
				<Route path='messaging' element={<Messaging />} />
				<Route path='preferences' element={<Preferences />} />
			</Route>
		</Routes>
	)
}

export default ClientPortal