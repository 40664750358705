import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import abonnementApi from '../../../../../api/abonnement.api';
import { format } from 'date-fns';

const ShowFactoryModal = ({ showModal, handleCloseModal, customerId }) => {

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchInvoices = async (customerId) => {
    setLoading(true);
    const res = await abonnementApi.getCustomerInvoiceByCustomerId(localStorage.getItem('token'), customerId);
    setInvoices(res || []);
    setLoading(false);
  }

  useEffect(() => {
    if(customerId) {
      fetchInvoices(customerId);
    }
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      backdropClassName="main-backdrop"
      size="xl" className="my-modal-xl"
      style={{ zIndex: 1050 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Factures</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-y-scroll min-h-[60vh]">
        <table className="table-auto w-full border-collapse border border-gray-400">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-400 px-4 py-2">Id</th>
              <th className="border border-gray-400 px-4 py-2">Date création</th>
              <th className="border border-gray-400 px-4 py-2">Montant dû</th>
              <th className="border border-gray-400 px-4 py-2">Montant payé</th>
              <th className="border border-gray-400 px-4 py-2">Devise</th>
              <th className="border border-gray-400 px-4 py-2">status</th>
              <th className="border border-gray-400 px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  Chargement...
                </td>
              </tr> 
            )}

            {!loading && invoices.map((invoice) => (
              <tr key={invoice.id}>

                <td className="border border-gray-300 px-4 py-2">{invoice.id}</td>
                <td className="border border-gray-300 px-4 py-2">{format(new Date(invoice.created * 1000), "dd/MM/yyyy H:m")}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {parseFloat(invoice.amount_due / 100).toFixed(2)} €
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {parseFloat(invoice.amount_paid / 100).toFixed(2)} €
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {invoice.currency}
                </td>
                <td className="border border-gray-300 px-4 py-2">{invoice.status}</td>
                <td className="border border-gray-300 px-4 py-2">
                  <a
                    href={invoice.invoice_pdf}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    Télécharger
                  </a>
                </td>
              </tr>
            ))}

            {!loading && invoices.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  Aucune facture trouvée
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowFactoryModal;
