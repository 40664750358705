import { API_URL } from "../utils/constants"
import { formatFrenchPhoneNumberForBrevo } from "../utils/functions";

const smsApi = {
    sendSmsNotification : async (phoneNumber, message) => {
        try {
            const send = await fetch(`${API_URL}/sms/send-sms-notification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phoneNumber: formatFrenchPhoneNumberForBrevo(phoneNumber),
                    message: message
                })
            });
            if (!send.ok) {
                throw new Error('Erreur lors de l\'envoie du sms!');
            }

            return await send.json();
        } catch(error) {
            throw new Error('Impossible d\'envoyer le sms!');
        }
    },

}

export default smsApi;