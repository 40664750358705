import React, { useState, useEffect, useRef } from "react";
import { MoreVertical } from "lucide-react";

const AppointmentActions = ({ appointment, handleItemClick, handleRedirect, handleCancel, setShowCancelModal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState("bottom");
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleDropdown = (event) => {
        setIsOpen(!isOpen);
        setTimeout(() => {
            if (dropdownRef.current) {
                const rect = dropdownRef.current.getBoundingClientRect();
                if (rect.bottom > window.innerHeight) {
                    setPosition("top");
                } else {
                    setPosition("bottom");
                }
            }
        }, 0);
    };

    return (
        <React.Fragment>
            <div className="flex justify-center items-center">
                <button onClick={toggleDropdown} className="p-2 rounded bg-gray-100 hover:bg-gray-200">
                    <MoreVertical size={20} />
                </button>
            </div>
            {isOpen && (
                <div 
                    ref={dropdownRef} 
                    className={`absolute right-10 ${position === "top" ? "bottom-full mb-2" : "top-5"} w-48 bg-white border border-gray-200 shadow-lg rounded-lg z-50`}
                >
                    <button onClick={() => handleItemClick(appointment)} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Voir détails
                    </button>
                    <button onClick={(e) => handleRedirect(e, appointment.slug)} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        Voir l'établissement
                    </button>
                    {appointment.status === "pending" && (
                        <button onClick={(e) => { e.preventDefault(); handleCancel(appointment); }} className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-100">
                            Annuler
                        </button>
                    )}
                    {appointment.status === "confirmed" && (
                        <button onClick={(e) => { e.preventDefault(); setShowCancelModal(true); }} className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-100">
                            Annuler
                        </button>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default AppointmentActions;