import { API_URL } from "../utils/constants";


export const beauticianDocumentsApi = {

    getByBauticianId: async (id, token) => {
        try {
            const response = await fetch(`${API_URL}/beautician-documents/get-by-beautician-id/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération des données: ' + error.message);
        }
    },

    saveDocument: async (id, token, document, title) => {
        try {
            const formData = new FormData();
            
            // Ajout de l'ID dans les données
            formData.append('beautician_id', id);
            formData.append('title', title);
            formData.append(`file`, document);
            
            // Requête fetch avec le FormData
            const response = await fetch(`${API_URL}/beautician-documents/save`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });
    
            // Traitement de la réponse
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.statusText}`);
            }
    
            return await response.json();
        } catch (error) {
            console.error(error);
            throw new Error('Erreur lors de l\'enregistrement du document');
        }
    },

    deleteDocumentById: async (id, token) => {
        try {
            const response = await fetch(`${API_URL}/beautician-documents/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression du document: ' + error.message);
        }
    }
}