// src/components/BusinessDropdown/BusinessDropdown.js
import React, { useState, useRef, useEffect, useContext } from 'react';
import './OpenToWorkDropdown.css';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

const OpenToWorkDropdown = ({setIsSignupModalOpen}) => {

const {setSignUpUserType} = useContext(AppContext);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleSignupModal = (e, type) => {
    e.preventDefault();
    setIsOpen(false);
    setIsSignupModalOpen(true);
    setSignUpUserType(type);
  }

  return (
    <div 
      className="business-dropdown-container"
      ref={dropdownRef}
    >
      <button 
        className="nav-link business-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {setIsOpen(false); navigate('/planibeauty-pro');}}
      >
        Open to work
      </button>
      {isOpen && (
        <div 
          className="business-dropdown-menu"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <a 
            href="/signup" 
            onClick={(e) => handleSignupModal(e, "recruteur")}
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je recrute</div>
              <div className="dropdown-item-description">Trouver vos talents</div>
            </div>
          </a>
          <a 
            href="#" 
            className="dropdown-item"
          >
            <div className="dropdown-item-content">
              <div className="dropdown-item-title">Je cherche un travail</div>
              <div className="dropdown-item-description">Je cherche un établissement/ poste/ mission</div>
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default OpenToWorkDropdown;