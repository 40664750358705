import React, { useContext, useEffect, useState } from 'react';
import { MapPin, Calendar, Gift, Plus, Star, MessageSquare, ChevronRight } from 'lucide-react';
import { BASE_URL } from '../../utils/constants';
import defaultProfile from '../../assets/images/default-profile.png';
import BeauticianGallery from './BeauticianGallery'
import { formatTime } from '../../utils/functions';
import './BeauticianInfo.css';
import ratingApi from '../../api/rating.api';
import AddReview from './AddReview';
import BookingContext from '../../context/BookingContext';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { conversationStarter } from '../../utils/conversation';

const openReview = (openReviewModal) => {
    openReviewModal(true)
}

const getCategoryRating = (categoriesArray=[], categorieName) => {
  // console.log('Categories Array:', categoriesArray);
  // console.log('Looking for category:', categorieName);
  const found = categoriesArray.filter(r => r.category === categorieName)[0];
  // console.log('Found rating:', found);
  return found?.average_rating || "0.0"
}

const CustomAccordion = ({ prestations }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    window.location.href=`#${prestations[index].category_name.toLowerCase().replaceAll(' ', '_')}`;
    //setOpenIndex(openIndex === index ? null : index); // Ferme si déjà ouvert
  };

  return (
    <div className="info-specialties">
      <h2 className="text-2xl font-bold mb-2">Spécialités</h2>
      <div className="">
        {prestations.length > 0 ? prestations.map((prestation, index) => (
          <AccordionItem
            key={index}
            prestation={prestation}
            isOpen={openIndex === index}
            onClick={() => toggleAccordion(index)}
          />
        )) : <p>Pas de prestations enregistrées</p>}
      </div>
    </div>
  );
};

const AccordionItem = ({ prestation, isOpen, onClick }) => {
  return (
    // <div className="relative">
      <div
        onClick={onClick}
        className={`w-full text-left px-1 py-1 bg-gray-50 border-b border-gray-200 hover:bg-gray-200 cursor-pointer`}
      >
        <div className="flex justify-between items-center cursor-pointer">
          <span className="font-medium cursor-pointer">{prestation.category_name}</span>
          <span><ChevronRight size={16} /></span>
        </div>
      </div>
      
    // </div>
  );
};

const BeauticianInfo = ({ beautician, prestations = [], shedule = [], openReviewModal, showAddReviewPanel, setShowAddReviewPanel, setReloadTrigger }) => {  const { setGiveAGift } = useContext(BookingContext);
  const [ratingByCategory, setRatingByCategory] = useState([]);
  const { connectedUser } = useContext(AppContext);
  const navigate = useNavigate();

  const handleAddCommentModal = (e) => {
    e.stopPropagation()
    setShowAddReviewPanel(true)
  }

  const handleGiveGift = () => {
    window.location.href="#prestation";
    setGiveAGift(true);
  }

  const startConversation = () => {
    conversationStarter.startBeauticianConversation(beautician, connectedUser, navigate);
  };

  const getImageUrl = (profilePhoto) => {
    if (!profilePhoto) return defaultProfile;
    try {
      return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
    } catch (error) {
      console.error('Error with image path:', error);
      return defaultProfile;
    }
  };

  useEffect(() => {
    const getBeauticianRatingCategory = async () => {
      try {
        // console.log('Fetching ratings for beautician:', beautician?.id);
        const response = await ratingApi.getRatingByBeauticianId(beautician?.id);
        // console.log('Complete API Response:', response);
            
        if (response && response.data) {
          // console.log('Setting rating data:', response.data);
          setRatingByCategory(response.data);
        } else {
          // console.warn('No data in API response');
          setRatingByCategory([]);
        }
      }
      catch (error) {
        console.error('Error fetching ratings:', error);
        setRatingByCategory([]);
      }
    };

    if (beautician && beautician.id) {
      getBeauticianRatingCategory();
    }
  }, [beautician?.id]);

  if (!beautician) {
    return <div>Loading...</div>;
  }

  return (
    <div className="info-wrapper">
      <div className="info-layout">
        {/* Profile column with photo and buttons */}
        <div className="info-profile">
          <div className="info-photo-container">
            <img
              src={getImageUrl(beautician.profile_photo)}
              alt={beautician.business_name || beautician.first_name}
            />
          </div>
          <div className="info-actions">
            <div className="info-actions-top">
              {/*<button className="info-gift-btn" onClick={handleGiveGift}>
                <Gift size={18} />
                Offrir
              </button>*/}
              <button className="info-booking-btn" onClick={() => window.location.href="#prestation"}>
                <Calendar size={18} />
                Prendre RDV
              </button>
            </div>
            {connectedUser && connectedUser.id !== beautician.user_id && (
              <button 
                className="info-message-btn"
                onClick={startConversation}
              >
                <MessageSquare size={18} />
                Contacter
              </button>
            )}
          </div>
        </div>

        {/* Rest of the existing layout remains unchanged */}
        <div className="info-details">
          <h1>{beautician.business_name || `${beautician.first_name} ${beautician.last_name}`}</h1>
          <div className="info-location">
            <MapPin size={18} />
            <span>{beautician.city}</span>
          </div>
          <div className="info-specialties">
            <CustomAccordion prestations={prestations} />
          </div>
          <div>
            <a href="#about" className="text-second underline cursor-pointer">En savoir plus</a>
          </div>
        </div>

        <div className="info-schedule">
          <h2>Disponibilités</h2>
          <div className="info-schedule-grid">
            {shedule.map((element, index) => (
              <div key={index} className="info-schedule-row">
                <span className="info-day">{element.day.split('')[0]}</span>
                <span className="info-status">{(element.start_time != '-' && element.end_time != "-") ? `${formatTime(element.start_time)} - ${formatTime(element.end_time)}` : 'Fermé'}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Rating Section */}
        <div className="info-rating cursor-pointer">
          <div className="info-rating-header flex items-center justify-between">
            <div className="info-rating-title">Note globale</div>

            <button className="text-second flex items-center gap-1 font-semibold underline" onClick={() => openReview(openReviewModal)}>
              Voir les avis ({beautician.comment_count})
              { (connectedUser && connectedUser?.user_type === 'client') &&
              <span
                className="cursor-pointer"
                onClick={handleAddCommentModal}
              >
                  <Plus size="18px" />
              </span>}
            </button>
          </div>

          <div className="info-rating-container">
          <div className="info-rating-global">
            <div className="info-rating-score">{beautician.average_rating || "0.0"}</div>
          </div>

          <div className="info-rating-list">
            <div className="info-rating-item">
              <span>Accueil</span>
              <span className="flex items-center gap-1">
                {getCategoryRating(ratingByCategory, "Welcome")} 
                <Star size={16} fill="#000" />
              </span>
            </div>
            <div className="info-rating-item">
              <span>Propreté</span>
              <span className="flex items-center gap-1">
                {getCategoryRating(ratingByCategory, "Cleanliness")} 
                <Star size={16} fill="#000" />
              </span>
            </div>
            <div className="info-rating-item">
              <span>Cadre & Ambiance</span>
              <span className="flex items-center gap-1">
                {getCategoryRating(ratingByCategory, "Ambiance")} 
                <Star size={16} fill="#000" />
              </span>
            </div>
            <div className="info-rating-item">
              <span>Qualité de la prestation</span>
              <span className="flex items-center gap-1">
                {getCategoryRating(ratingByCategory, "Service Quality")} 
                <Star size={16} fill="#000" />
              </span>
            </div>
            {/* <div className="info-rating-footer">
              <span className="count font-bold">{`${beautician.comment_count}`}</span>
              <span>{`client${beautician.comment_count > 1 ? 's' : ''} ${beautician.comment_count > 1 ? 'ont' : 'a'} partagé ${beautician.comment_count > 1 ? "leur" : "son"} avis`}</span>
            </div> */}
          </div>
        </div>
      </div>
      </div>

      <BeauticianGallery beautician_id={beautician.id} />
      
      <AddReview
        beautician_id={beautician.id}
        showAddReviewPanel={showAddReviewPanel}
        setShowAddReviewPanel={setShowAddReviewPanel}
        setReloadTrigger={setReloadTrigger}
      />
    </div>
  );
};

export default BeauticianInfo;