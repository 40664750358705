import React, { createContext, useContext, useState, useEffect } from "react";
import { showErrorMessage } from "../utils/messageHelper";
import userApi from "../api/user.api";
import { AppContext } from "../contexts/AppContext";
import { beauticiansSubCategoriesApi } from "../api/beauticians_subcategories.api";
import { groupPrestationsByCategoryId } from "../utils/functions";
import serviceCategoriesApi from "../api/service-categories.api";
import abonnementApi from "../api/abonnement.api";
import testeurApi from "../api/testeur.api";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../utils/constants";
export const BeauticianPortalContext = createContext();

export const BeauticianPortalProvider = ({ children }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const { connectedUser } = useContext(AppContext);
    const [profileData, setProfileData] = useState(null);
    const [isParentLoading, setIsParentLoading] = useState(false);
    const [prestations, setPrestations] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [activeAccordionSetting, setActiveAccordionSetting] = useState(0);
    const [abonnementActive, setAbonnementActive] = useState(false);
    const [isOnFreeTrial, setIsOnFreeTrial] = useState(false);
    const [trial, setTrial] = useState(null);
    const [isAbonnementAttribued, setIsAbonnementAttribued] = useState(false);


    const fetchBeauticianData = async (user_id) => {
        setIsParentLoading(true);
        try {
            const data = await userApi.fetchBeauticianUserByUserId(localStorage.getItem('token'), user_id);
            //console.log(data);
            if (data) {
                setProfileData(data);
                const req_prestations = await beauticiansSubCategoriesApi.getByBauticianId(data.beautician_id, localStorage.getItem('token'));
                const group_by_cat = groupPrestationsByCategoryId(req_prestations);
                setPrestations(group_by_cat || []);
                const all_cat = await serviceCategoriesApi.getAllCategories();
                setAllCategories(all_cat || []);
                setTimeout(() => {
                    setIsParentLoading(false);
                }, 500);
            }
        } catch (error) {
            showErrorMessage("Impossible de charger les données de l'utilisateur!");
            setIsParentLoading(false);
        }
    }

    const checkAbonnementState = async (user_id) => {
        const abonnement = await abonnementApi.getAbonnementByUserId(localStorage.getItem('token'), user_id);

        if(abonnement && abonnement.status === "active") {
            if (new Date(abonnement?.end_date) >= new Date()) {
                setAbonnementActive(true);
                if(abonnement.is_attribued) {
                    setIsAbonnementAttribued(true);
                }
                return;
            }
        } else if(abonnement && abonnement.status === "trialing") {
            if (new Date(abonnement?.trial_end) >= new Date()) {
                setAbonnementActive(true);
                setIsOnFreeTrial(true);
                setTrial({ 
                    trial_end_date: abonnement?.trial_end, 
                    trial_start_date: abonnement?.trial_start,
                    start_date: abonnement?.start_date,
                    end_date: abonnement?.end_date,
                    offre_name: abonnement?.offre_name,
                    price_per_month: abonnement?.price_per_month
                });
                return;
            }
        }

        // on verifie si c'est un testeur
        const is_testeur = await testeurApi.is_user_testeur(localStorage.getItem('token'), user_id);
        if (is_testeur) {
            setAbonnementActive(true);
            return;
        }
    }

    useEffect(() => {
        if (connectedUser) {
            fetchBeauticianData(connectedUser.id);
            checkAbonnementState(connectedUser.id);
        }
    }, [connectedUser]);

    useEffect(() => {
        if (connectedUser) {
            fetchBeauticianData(connectedUser.id);
            checkAbonnementState(connectedUser.id);
        }
    }, [reloadTrigger]);

    useEffect(() => {

        if (!abonnementActive) {
            if ([
                "/beautician-dashboard/profile",
                "/beautician-dashboard/services",
                "/beautician-dashboard/agenda",
                "/beautician-dashboard/historiques",
                "/beautician-dashboard/stats",
                "/beautician-dashboard/notifications",
            ].includes(location.pathname)) {
                navigate("/beautician-dashboard/");
            }
        }
    }, [abonnementActive, location])

    useEffect(() => {
        // ajoutons un condition s'il est présent dans le testeur
        const action = async () => {
            const is_testeur = await testeurApi.is_user_testeur(localStorage.getItem('token'), connectedUser.id);
            if(profileData.is_first_login && !is_testeur) {
                navigate("/beautician-dashboard/settings?active=abonnement");
                return;
            }
        }

        // if agree terms false
        if(profileData && !profileData.agree_terms) {
            window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/accept-terms`;
            return;
        }

        // first login
        if(profileData && connectedUser) {
            action();
        }
    }, [connectedUser, profileData]);


    return <BeauticianPortalContext.Provider
        value={{
            profileData,
            isParentLoading,
            prestations,
            allCategories,
            setReloadTrigger,
            activeAccordionSetting,
            setActiveAccordionSetting,
            abonnementActive,
            setAbonnementActive,
            isOnFreeTrial,
            trial,
            isAbonnementAttribued
        }}
    >
        {children}
    </BeauticianPortalContext.Provider>
};