import React, { useCallback, useContext } from 'react'

import { FaX, FaCheck } from "react-icons/fa6"
import { dashboardContext } from '../../pages/dashboard/Dashboard';
import userApi from '../../api/user.api';
import beauticianApi from '../../api/beautician.api';
import Swal from 'sweetalert2';
import teleConseillerApi from '../../api/tele_conseiller.api';

const DeleteModal = () => {
    const { activeID,
        showDeletionModal,
        setReloadTrigger,
        activePage,
        setDynamicFunction,
        isFromSheet,
        setIsFromSheet,
        showUserSheetModal
    } = useContext(dashboardContext);

    const updateFunction = useCallback(() => {
        setDynamicFunction(() => {
            return () => {
                showUserSheetModal(false);
            }
        });
    }, [setDynamicFunction]);

    const handleDeletion = async () => {
        const token = localStorage.getItem("token")

        if (["client", "operator", 'admin', 'recruteur'].includes(activePage)) {
            // Pour precision, pour le client, lors de l'affichage c'est l'id dans la table users qui est défini comme activeID
            // donc aucun problème d'utiliser activeID
            const delete_user_account = await userApi.deleteAccount(activeID);
            if (delete_user_account.success) {
                showDeletionModal(false);
                Swal.fire('Succès', 'Client supprimé avec succès!', 'info');
                setReloadTrigger((prev) => !prev);
                if (isFromSheet) {
                    setIsFromSheet(false);
                    updateFunction();
                }
            }
        }
        else if (["all", "indépendant", 'établissement'].includes(activePage)) {
            const beautician_account = await beauticianApi.deleteBeautician(token, activeID);
            const delete_user_account = await userApi.deleteAccount(beautician_account.data.user_id);
            if (delete_user_account.success) {
                showDeletionModal(false);
                Swal.fire('Succès', 'Utilisateur supprimé avec succès!', 'info');
                setReloadTrigger((prev) => !prev);
                if (isFromSheet) {
                    setIsFromSheet(false);
                    updateFunction();
                }
            }
        } else if (activePage === "tele_conseiller") {
            const tele_conseiller_account = await teleConseillerApi.delete(token, activeID);
            const delete_user_account = await userApi.deleteAccount(tele_conseiller_account.data.user_id);
            if (delete_user_account.success) {
                showDeletionModal(false);
                Swal.fire('Succès', 'Utilisateur supprimé avec succès!', 'info');
                setReloadTrigger((prev) => !prev);
                if (isFromSheet) {
                    setIsFromSheet(false);
                    updateFunction();
                }
            }
        }
    }

    return (
        <div className="absolute z-50 top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-80 flex items-center justify-center">
            <section className="relative p-3 w-2/5 flex flex-col gap-3 bg-white rounded shadow">
                <h2 className="text-center">Etes-vous sûres de vouloir supprimer ces données ?</h2>
                <div className="ms-auto flex items-center gap-3">
                    <button
                        onClick={handleDeletion}
                        className="px-3 py-2 flex items-center gap-2 text-white bg-[#bd193b] border-2 border-[#bd193b] rounded shadow"
                    >
                        <FaCheck /> Oui
                    </button>
                    <button
                        onClick={() => {
                            showDeletionModal(false);
                            setIsFromSheet(false);
                            setDynamicFunction(() => {
                                // Fonction par défaut
                                return () => { };
                            });
                        }}
                        className="px-3 py-2 flex items-center gap-2 border-2 border-gray-500 text-gray-500 hover:bg-gray-500 hover:text-white rounded shadow duration-200">
                        <FaX /> Annuler
                    </button>
                </div>
            </section>
        </div>
    )
}

export default DeleteModal;