import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../contexts/AppContext';
import './ConditionsGenerales.css';
import bandeImage from '../../images/hero/bande.png';

const ConditionsGenerales = () => {
    const { setActivePageForHeader } = useContext(AppContext);
    const [isMounted, setIsMounted] = useState(false);
    const [activeDocument, setActiveDocument] = useState('cgu');
    
    // Pour le débogage - affiche le chemin complet du PDF
    const documents = {
        cgu: {
            title: "Conditions Générales d'Utilisation",
            path: `${process.env.PUBLIC_URL}/pdfs/cgu.pdf`
        },
        cgv: {
            title: "Conditions Générales de Vente",
            path: `${process.env.PUBLIC_URL}/pdfs/cgv.pdf`
        }
    };
    
    useEffect(() => {
            window.scrollTo(0, 0);
            document.body.setAttribute('data-page', 'cg');
            setActivePageForHeader("cg");
        
            return () => {
              document.body.removeAttribute('data-page');
              setActivePageForHeader("");
            }
          }, [setActivePageForHeader]);

    useEffect(() => {
        setIsMounted(true);
        window.scrollTo(0, 0);
        
        document.body.setAttribute('data-page', 'cg');
        setActivePageForHeader("cg");

        // Pour le débogage - log du chemin du PDF
        console.log("Chemin du PDF actif:", documents[activeDocument].path);

        return () => {
            setIsMounted(false);
            document.body.removeAttribute('data-page');
            setActivePageForHeader("");
        };
    }, [setActivePageForHeader, activeDocument]);

    return (
        <>
            <Helmet>
                <title>Conditions Générales | PlaniBeauty</title>
                <meta name="description" content="Conditions générales d'utilisation et de vente de PlaniBeauty" />
                <link rel="canonical" href="https://www.planibeauty.fr/conditions-generales" />
            </Helmet>

            <div className={`conditions-generales ${isMounted ? "mounted" : ""}`}>
                {/* Hero Section */}
                <div className="cg-section">
                    <img src={bandeImage} alt="Conditions Générales" />
                    <div className="cg-overlay"></div>
                    <div className="container cg-content">
                        <div>
                            <h1>{documents[activeDocument].title}</h1>
                        </div>
                    </div>
                </div>

                {/* Contenu Principal */}
                <div className="content-wrapper">
                    <div className="container">
                        <div className="document-selector">
                            <button 
                                className={`selector-btn ${activeDocument === 'cgu' ? 'active' : ''}`}
                                onClick={() => setActiveDocument('cgu')}
                            >
                                CONDITIONS GÉNÉRALES D'UTILISATION
                            </button>
                            <button 
                                className={`selector-btn ${activeDocument === 'cgv' ? 'active' : ''}`}
                                onClick={() => setActiveDocument('cgv')}
                            >
                                CONDITIONS GÉNÉRALES DE VENTE
                            </button>
                        </div>
                        
                        <div className="pdf-viewer">
                            <iframe
                                src={`${documents[activeDocument].path}#view=FitH`}
                                title={documents[activeDocument].title}
                                className="pdf-document"
                                onError={(e) => {
                                    console.error("Erreur de chargement du PDF:", e);
                                }}
                            >
                                <div className="pdf-fallback">
                                    <p>Votre navigateur ne peut pas afficher ce PDF.</p>
                                    <a 
                                        href={documents[activeDocument].path}
                                        download
                                        className="download-btn"
                                    >
                                        Télécharger le PDF
                                    </a>
                                </div>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConditionsGenerales;