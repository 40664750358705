import React, { useState } from "react"
import { Search, UserPlus, Users, Calendar, Star, CheckCircle } from "lucide-react"

const professionals = [
  { id: 1, name: "Sophie Martin", category: "Maquillage", rating: 4.5, status: "Recruté", contact: "sophie@email.com" },
  { id: 2, name: "Jean Dupont", category: "Coiffure", rating: 3.8, status: "Repéré", contact: "jean@email.com" },
  { id: 3, name: "Alice Lemaitre", category: "Massage", rating: 5.0, status: "En attente", contact: "alice@email.com" }
]

const menuItems = [
  { title: "Trouver", icon: <Search size={20} />, id: "find" },
  { title: "Repérés", icon: <Users size={20} />, id: "spotted" },
  { title: "Recrutés", icon: <CheckCircle size={20} />, id: "hired" },
  { title: "Entretiens", icon: <Calendar size={20} />, id: "interviews" }
]

const titles = {
  find: "🔍 Rechercher un professionnel",
  spotted: "👀 Professionnels repérés",
  hired: "✅ Professionnels recrutés",
  interviews: "📅 Entretiens planifiés"
}

const BeauticianManagement = () => {
  const [activeTab, setActiveTab] = useState("find")
  const [searchTerm, setSearchTerm] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("")

  // Filtrage des professionnels selon l'onglet actif et les critères de recherche
  const filteredProfessionals = professionals.filter(p => {
    if (activeTab === "spotted" && p.status !== "Repéré") return false
    if (activeTab === "hired" && p.status !== "Recruté") return false
    if (activeTab === "interviews" && p.status !== "En attente") return false
    if (searchTerm && !p.name.toLowerCase().includes(searchTerm.toLowerCase())) return false
    if (categoryFilter && p.category !== categoryFilter) return false
    return true
  })

  return (
    <div className="p-4 md:p-8 bg-gray-100 min-h-screen">
      <h1 className="text-xl md:text-2xl border-b pb-3 font-semibold mb-4">Recrutement des professionnels</h1>

      {/* MENU - Responsive */}
      <nav className="grid grid-cols-2 md:flex gap-2 md:gap-4 mb-6">
        {menuItems.map(item => (
          <button
            key={item.id}
            className={`flex items-center justify-center md:justify-start gap-2 px-4 py-2 rounded-lg transition text-sm md:text-base 
              ${activeTab === item.id ? "bg-white shadow font-semibold" : "text-gray-600 hover:bg-gray-200"}`}
            onClick={() => setActiveTab(item.id)}
          >
            {item.icon}
            {item.title}
          </button>
        ))}
      </nav>

      {/* FORMULAIRE DE RECHERCHE */}
      <div className="bg-white p-4 rounded-lg shadow text-gray-700">
        <h2 className="text-lg font-semibold mb-4">{titles[activeTab]}</h2>

        <form className="flex flex-col md:flex-row gap-4">
          {/* Champ de recherche */}
          <div className="relative w-full">
            <Search className="absolute left-3 top-3 text-gray-500" size={20} />
            <input
              type="text"
              placeholder="Nom du professionnel..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-[#bd193b] outline-none"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Filtre par catégorie */}
          <select
            className="border py-2 px-4 rounded-lg focus:ring-2 focus:ring-[#bd193b]"
            value={categoryFilter}
            onChange={e => setCategoryFilter(e.target.value)}
          >
            <option value="">Toutes les catégories</option>
            <option value="Maquillage">Maquillage</option>
            <option value="Coiffure">Coiffure</option>
            <option value="Massage">Massage</option>
          </select>
        </form>
      </div>

      {/* LISTE DES PROFESSIONNELS - Responsive */}
      <section className="mt-6 bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">{titles[activeTab]}</h2>

        {/* TABLEAU SUR GRAND ÉCRAN */}
        <table className="hidden md:table w-full border-collapse">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 text-left">Nom</th>
              <th className="p-2 text-left">Catégorie</th>
              <th className="p-2 text-left">Note</th>
              <th className="p-2 text-left">Statut</th>
              <th className="p-2 text-left">Contact</th>
            </tr>
          </thead>
          <tbody>
            {filteredProfessionals.length > 0 ? (
              filteredProfessionals.map(pro => (
                <tr key={pro.id} className="border-t hover:bg-gray-100">
                  <td className="p-2">{pro.name}</td>
                  <td className="p-2">{pro.category}</td>
                  <td className="p-2 flex items-center gap-1">
                    <Star size={16} className="text-yellow-500" /> {pro.rating}/5
                  </td>
                  <td className="p-2">
                    <span
                      className={`px-2 py-1 text-xs rounded-full 
                        ${pro.status === "Recruté" ? "bg-green-200 text-green-800" :
                        pro.status === "Repéré" ? "bg-yellow-200 text-yellow-800" :
                        "bg-gray-200 text-gray-800"}`}
                    >
                      {pro.status}
                    </span>
                  </td>
                  <td className="p-2 text-blue-600 cursor-pointer">{pro.contact}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="p-4 text-center text-gray-500">Aucun professionnel trouvé.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* CARTES SUR MOBILE */}
        <div className="md:hidden space-y-4">
          {filteredProfessionals.length > 0 ? (
            filteredProfessionals.map(pro => (
              <div key={pro.id} className="p-4 bg-gray-50 rounded-lg shadow-md">
                <h3 className="font-semibold text-lg">{pro.name}</h3>
                <p className="text-gray-600 text-sm">{pro.category}</p>
                <p className="flex items-center gap-1 text-yellow-500 text-sm">
                  <Star size={16} /> {pro.rating}/5
                </p>
                <span
                  className={`inline-block px-2 py-1 text-xs rounded-full mt-2 
                    ${pro.status === "Recruté" ? "bg-green-200 text-green-800" :
                    pro.status === "Repéré" ? "bg-yellow-200 text-yellow-800" :
                    "bg-gray-200 text-gray-800"}`}
                >
                  {pro.status}
                </span>
                <p className="text-blue-600 text-sm mt-2">{pro.contact}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center">Aucun professionnel trouvé.</p>
          )}
        </div>
      </section>
    </div>
  )
}

export default BeauticianManagement
