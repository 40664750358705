import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TeleConseillerPortalLayout from './TeleConseillerPortalLayout';
import Messaging from '../../message/Messaging';
import TeleConseillerDashboard from '../../../components/portals/tele-conseiller/TeleConseillerDashboard';
import TeleConseillerProfile from '../../../components/portals/tele-conseiller/TeleConseillerProfile';
import TeleConseillerSettings from '../../../components/portals/tele-conseiller/TeleConseillerSettings';

const TeleConseillerPortal = () => {

  return (
    <Routes>
      <Route element={<TeleConseillerPortalLayout />}>
        {/* Route du tableau de bord */}
        <Route index element={<TeleConseillerDashboard />} />
        
        {/* Autres routes à ajouter ici */}
        <Route path="profile" element={<TeleConseillerProfile />} />
        <Route path="settings" element={<TeleConseillerSettings/>} />
        <Route path='messaging' element={<Messaging />} />
      </Route>
    </Routes>
  );

};

export default TeleConseillerPortal;