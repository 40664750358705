import React from 'react';

const Collaborateurs = ({ collaborateurs }) => {

  return <>
    <div className="about-container">
      <h2 className="about-title">Collaborateurs</h2>

      <div className='flex gap-4 flex-wrap items-center'>
        {collaborateurs.length > 0 && collaborateurs.map((c) => <div key={c.id} className='p-2 rounded border border-gray-100 shadow-sm'>
          <div className='py-4 px-5 rounded bg-[#870022] text-center text-white text-2xl'>{c.name[0].toUpperCase()}</div>
          <h5 className='mt-2 text-gray-600 text-center'>{c.name}</h5>
        </div>)}

        {collaborateurs.length === 0 && <p className='text-gray-600'>Aucun collaboraeturs à afficher</p>}

      </div>
    </div>
  </>
};

export default Collaborateurs;