import React, { useState } from 'react'

const Settings = () => {
    const [emailNotifications, setEmailNotifications] = useState(true)
    const [smsNotifications, setSmsNotifications] = useState(false)

    return (
        <div className="p-8 bg-gray-100 h-screen">
            <h1 className="text-2xl font-semibold mb-4">Paramètres</h1>

            {/* Section Compte */}
            <div className="bg-white p-4 shadow rounded mb-6">
                <h2 className="text-xl font-semibold mb-3">Compte</h2>
                <div className="flex flex-col gap-3">
                    <label>Nom :</label>
                    <input type="text" className="border p-2 rounded" placeholder="Votre nom" />
                    
                    <label>Email :</label>
                    <input type="email" className="border p-2 rounded" placeholder="email@example.com" />

                    <button className="bg-blue-500 text-white px-4 py-2 rounded mt-3">Enregistrer</button>
                </div>
            </div>

            {/* Notifications */}
            <div className="bg-white p-4 shadow rounded mb-6">
                <h2 className="text-xl font-semibold mb-3">Notifications</h2>
                <div className="flex items-center justify-between">
                    <span>Email</span>
                    <input type="checkbox" checked={emailNotifications} onChange={() => setEmailNotifications(!emailNotifications)} />
                </div>
                <div className="flex items-center justify-between mt-3">
                    <span>SMS</span>
                    <input type="checkbox" checked={smsNotifications} onChange={() => setSmsNotifications(!smsNotifications)} />
                </div>
            </div>

            {/* Sécurité */}
            <div className="bg-white p-4 shadow rounded">
                <h2 className="text-xl font-semibold mb-3">Sécurité</h2>
                <button className="bg-red-500 text-white px-4 py-2 rounded">Supprimer le compte</button>
            </div>
        </div>
    )
}

export default Settings
