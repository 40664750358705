import React, { useContext, useState, useEffect, useRef } from 'react'
import Accordion from 'react-bootstrap/Accordion';

import { dashboardContext } from '../../pages/dashboard/Dashboard';
import SiteInfoApropos from './SiteInfoApropos';
import SiteInfoReseauSociaux from './SiteInfoReseauSociaux';
import SiteInfoPrestation from './SiteInfoPrestation';
import SiteInfoBudgetCategorie from './SiteInfoBudgetCategorie';
import { sitesApi } from '../../api/sites.api';
import SiteInfoAgenda from './SiteInfoAgenda';
import SiteInfoPhotoEtablissement from './SiteInfoPhotoEtablissement';
import AboutEtabOrBeautician from './AboutEtabOrBeautician';
import DocumentsUpload from './DocumentsUpload';


const SiteInfoComponent = () => {

    const { userData, activeID, prestations, fetchPrestations, setShowPrestations } = useContext(dashboardContext);
    const [site, setSite] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useRef(false);
    const [changeTrigger, setChangeTrigger] = useState(false);

    const fetchOrCreateSite = async () => {
        if (isLoading) return; // Évite les appels multiples

        try {
            setIsLoading(true);
            const response = await sitesApi.getOrCreateSiteByBeauticianId(activeID, localStorage.getItem("token"));
            if (response.success && response.data) {
                setSite(response.data);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            fetchOrCreateSite();
            fetchPrestations();
        }
    }, []);

    useEffect(() => {
        fetchPrestations();
    }, [changeTrigger]);


    return <>
        <h1 className="text-center text-2xl my-2">Caractéristiques</h1>
        <div className='h-full overflow-y-auto'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <SiteInfoBudgetCategorie userData={userData} activeID={activeID} />
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <SiteInfoPrestation
                        setShowPrestations={setShowPrestations}
                        prestations={prestations}
                        setChangeTrigger={setChangeTrigger}
                    />
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <AboutEtabOrBeautician activeID={activeID} about_text={userData.description} />
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <SiteInfoPhotoEtablissement activeID={activeID} />
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    {site && <SiteInfoApropos site={site} activeID={activeID} />}
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    {site && <SiteInfoReseauSociaux site={site} activeID={activeID} />}
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <SiteInfoAgenda />
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <DocumentsUpload activeID={activeID} />
                </Accordion.Item>
            </Accordion>
        </div>
    </>
}

export default SiteInfoComponent;