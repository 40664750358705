import React, { useEffect, useContext } from 'react';
import RecruteurPortalLayout from './RecruteurPortalLayout';
import { Routes, Route } from 'react-router-dom';
import Profile from '../../../components/portals/recruteur/Profile';
import { AppContext } from '../../../contexts/AppContext';
import Messaging from '../../message/Messaging';
import { BASE_URL } from '../../../utils/constants';
import Dashboard from '../../../components/portals/recruteur/Dashboard';
import BeauticianManagement from '../../../components/portals/recruteur/BeauticianManagement';
import Settings from '../../../components/portals/recruteur/Settings';

const RecruteurPortal = () => {
	const { setActivePageForHeader, connectedUser } = useContext(AppContext);

	useEffect(() => {
		// Indiquer explicitement qu'on n'est pas sur la home
		setActivePageForHeader("");
		// Nettoyage au démontage du composant
		return () => {
			setActivePageForHeader("");
		};
	}, [setActivePageForHeader]);

	useEffect(() => {
		if (connectedUser) {
			if (!connectedUser.agree_terms) {
				// redirect to dashboard
				window.location.href = `${process.env.NODE_ENV === 'production' ? BASE_URL : 'http://localhost:3000'}/accept-terms`;
			}
		}
	}, [connectedUser]);

	return (
		<Routes>
			<Route element={<RecruteurPortalLayout />}>
				<Route index element={<Dashboard />} />
				<Route path='profile' element={<Profile />} />
				<Route path='beautician-management' element={<BeauticianManagement />} />
				<Route path='messaging' element={<Messaging />} />
				<Route path='settings' element={<Settings />} />
			</Route>
		</Routes>
	)
}

export default RecruteurPortal