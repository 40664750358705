import React, { useContext } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { TeleConseillerPortalContext } from '../../../../context/TeleConseillerPortalContext';
import MySpinner from '../../../loader/Spinner';
import InfoPerso from './Profile/InfoPerso';


const ProfileSettings = () => {

    const {
        profileData,
        setReloadTrigger,
        activeAccordionSetting,
        setActiveAccordionSetting
    } = useContext(TeleConseillerPortalContext);

    const handleAccordionChange = (eventKey) => {
        setActiveAccordionSetting(eventKey);
    };

    return <div className="settings-form">
        {profileData ?
            <Accordion activeKey={activeAccordionSetting} onSelect={handleAccordionChange}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Informations générales</Accordion.Header>
                    <Accordion.Body className="flex flex-col gap-3">
                        <InfoPerso profileData={profileData} setReloadTrigger={setReloadTrigger} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> :
            <MySpinner height={'60vh'} />
        }
    </div>
}

export default ProfileSettings;