import React, { useState, useEffect, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';
import { photoEtablissementApi } from '../../api/photo_etablissement.api';
import { FaPlus } from 'react-icons/fa6';
import { FaSave } from 'react-icons/fa';
import { showErrorMessage, showSuccessMessage } from '../../utils/messageHelper';
import { BASE_URL } from '../../utils/constants';

const SiteInfoPhotoEtablissement = ({activeID}) => {
    const [images, setImages] = useState({ image_list: [], image_list_saved: [] });
    const [localReload, setLocalReload] = useState(false);

    const handleImageUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const fileArray = Array.from(files);
            const imagePreviews = fileArray.map((file) => {
                const reader = new FileReader();
                return new Promise((resolve) => {
                    reader.onload = (e) => {
                        resolve({ id: Date.now() + Math.random(), src: e.target.result, file });
                    };
                    reader.readAsDataURL(file);
                });
            });

            Promise.all(imagePreviews).then((images) => {
                setImages((prev) => ({
                    ...prev,
                    image_list: [...prev.image_list, ...images],
                }));
            });
        }
    };

    const handleImageDelete = (imageId) => {
        setImages((prev) => ({
            ...prev,
            image_list: prev.image_list.filter((image) => image.id !== imageId),
        }));
    };

    const handleSavedImageDelete = async (imageId) => {
        Swal.fire({
            title: "Voullez vous vraiment supprimer cette image?",
            showCancelButton: true,
            confirmButtonText: "Oui, supprimer",
            cancelButtonText: "Non"
        }).then((result) => {
            if (result.isConfirmed) {
                const delete_image = photoEtablissementApi.deleteImageById(imageId, localStorage.getItem("token"));
                if (delete_image) {
                    setImages((prev) => ({
                        ...prev,
                        image_list_saved: prev.image_list_saved.filter((image) => image.id !== imageId),
                    }));
                    showSuccessMessage("Image supprimée avec succès!")
                } else {
                    showErrorMessage("Impossible de supprimer l'image!");
                }
            }
        });
    };

    const handleSave = async () => {

        Swal.fire({
            title: 'Chargement...',
            text: 'Veuillez patienter...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            //on enregistre l'image
            if (images.image_list.length > 0) {
                const save_image = await photoEtablissementApi.savePhotoEtablissement(activeID, localStorage.getItem('token'), images.image_list);
                if (!save_image) {
                    Swal.close();
                    setTimeout(() => {
                        showErrorMessage("Échec lors de l'enregistrement des images!");
                    }, 200);
                } else {
                    Swal.close();
                    setLocalReload((prev) => !prev);
                    setTimeout(() => {
                        showSuccessMessage("Images enregistrées avec succès!");
                    }, 200);
                }
            } else {
                Swal.close();
                setTimeout(() => {
                    showErrorMessage("Veuillez selectionner au moins un image!");
                }, 200);
            }
        } catch (error) {
            Swal.close();
            setTimeout(() => {
                showErrorMessage(error || 'Une errreur s\'est produite!')
            }, 200);
        }
    };

    // Référence pour déclencher l'input file
    const fileInputRef = useRef();

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const fetchPhotoEtablissement = async () => {
        try {
            const images_from_db = await photoEtablissementApi.getByBauticianId(activeID);
            const initialImages = {
                image_list: [],
                image_list_saved: images_from_db,
            }
            setImages(initialImages);
        } catch (error) {
            showErrorMessage(error);
        }
    }

    const getImageUrl = (imageName) => {
        return `${BASE_URL}/images/etablissements/${encodeURIComponent(imageName)}`;
    };


    useEffect(() => {
        fetchPhotoEtablissement();
    }, [localReload]);


    return (
        <React.Fragment>
            <Accordion.Header>Photos de l'établissement ou du sallon</Accordion.Header>
            <Accordion.Body className="flex flex-col gap-3">
                <div>
                    <h4 className="mb-3 text-xl">Liste des images :</h4>
                    <div className='mb-3'>
                        {images.image_list_saved.length > 0 && <p>Images enregistrées</p>}
                        {images.image_list_saved.length > 0 ?
                            <div className="flex gap-2 flex-wrap mt-2">
                                {images.image_list_saved.map((image) => (
                                    <div
                                        key={image.id}
                                        className="relative w-24 h-24 border rounded overflow-hidden"
                                    >
                                        <img
                                            src={getImageUrl(image.photo_url)}
                                            alt="preview"
                                            className="w-full h-full object-cover"
                                        />
                                        <button
                                            onClick={() => handleSavedImageDelete(image.id)}
                                            className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                            :
                            <p>Il n'y a pas d'images enregistrées</p>}
                    </div>
                    <div>
                        {images.image_list.length > 0 && <p>Nouvelles images</p>}
                        <div className="flex gap-2 flex-wrap mt-2">
                            {images.image_list.map((image) => (
                                <div
                                    key={image.id}
                                    className="relative w-24 h-24 border rounded overflow-hidden"
                                >
                                    <img
                                        src={image.src}
                                        alt="preview"
                                        className="w-full h-full object-cover"
                                    />
                                    <button
                                        onClick={() => handleImageDelete(image.id)}
                                        className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button
                        onClick={triggerFileInput}
                        className="flex mt-3 items-center gap-2 px-3 py-2 bg-gray-600 text-white rounded shadow hover:bg-blue-500"
                    >
                        <FaPlus /> Ajouter des images
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }} // Cache l'input file
                    />
                </div>
                <div className='w-full flex justify-end'>
                    <button
                        onClick={handleSave}
                        className=" flex gap-2 items-center self-start px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                        <FaSave />
                        Enregistrer la modification
                    </button>
                </div>
            </Accordion.Body>
        </React.Fragment>
    );
};

export default SiteInfoPhotoEtablissement;
