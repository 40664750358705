import { API_URL } from "../utils/constants";

const employeeApi = {

    getEmployeeById: async (token, id) => {
        const response = await fetch(`${API_URL}/employees/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    getAllEmployeesByBeauticianId: async (beautician_id) => {
        const response = await fetch(`${API_URL}/employees/${beautician_id}/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return response.json();
    },

    create: async (token, data) => {
        try {
            const response = await fetch(`${API_URL}/employees/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création de l\'employé');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur lors de la création de l\'employé : ', error);
            throw error;
        }
    },

    update: async (token, id, data) => {
        try {
            const response = await fetch(`${API_URL}/employees/${id}/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la mise à jour de l\'employé');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'employé :', error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/employees/${id}/delete`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la suppression de l\'employé');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur lors de la suppression de l\'employé : ', error);
            throw error;
        }
    },

}

export default employeeApi;