import { API_URL } from "../utils/constants";

const teleConseillerApi = {

    createProfile: async (userData) => {
        try {
            const response = await fetch(`${API_URL}/tele-conseiller/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création de l\'utilisateur beautician');
        }
    },

    getAll: async () => {
        try {
            let url = `${API_URL}/tele-conseiller/all`;
            
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching conseillères beauté:', error);
            throw error;
        }
    },

    delete: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/tele-conseiller/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression du profil: ' + error.message);
        }
    },

    update: async (token, id, updateData) => {
        try {
            const response = await fetch(`${API_URL}/tele-conseiller/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    updateAvailability: async (token, id, availabilityData) => {
        try {
            const response = await fetch(`${API_URL}/tele-conseiller/${id}/availability`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(availabilityData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la disponibilité: ' + error.message);
        }
    },

};

export default teleConseillerApi;