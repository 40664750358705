// src/contexts/MessageContext.js

import { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import {AppContext} from './AppContext.jsx';

const MessageContext = createContext();

export const useMessage = () => {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessage must be used within MessageProvider');
    }
    return context;
};

export const MessageProvider = ({ children }) => {

    const {connectedUser} = useContext(AppContext);

    const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState(null);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const [unreadByConversation, setUnreadByConversation] = useState({});
    const [uploadProgress, setUploadProgress] = useState({});

    const isProduction = process.env.NODE_ENV === 'production';

    // Configuration Socket.IO client
    const socketOptions = {
        path: '/socket.io',
        transports: ['polling', 'websocket'],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        timeout: 60000,
        autoConnect: true,
        withCredentials: true,
        forceNew: true,
        extraHeaders: {
            "my-custom-header": "true"
        }
    };

    const SOCKET_URL = isProduction
        ? window.location.origin
        : process.env.REACT_APP_SOCKET_URL;

    const fetchUnreadCounts = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json'
            };

            // Récupérer le total des messages non lus
            const totalResponse = await fetch('/api/messages/unread/total', { headers });
            const totalData = await totalResponse.json();
            setUnreadMessages(totalData.count);

            // Récupérer les messages non lus par conversation
            const byConversationResponse = await fetch('/api/messages/unread/by-conversation', { headers });
            const byConversationData = await byConversationResponse.json();
            setUnreadByConversation(byConversationData);
        } catch (error) {
            console.error('Erreur lors de la récupération des messages non lus:', error);
        }
    };

    useEffect(() => {
        // console.log('Tentative de connexion socket:', {
        //     url: SOCKET_URL,
        //     options: socketOptions,
        //     mode: isProduction ? 'production' : 'development'
        // });

        if(!connectedUser) return;

        const newSocket = io(SOCKET_URL, socketOptions);
        
        newSocket.on('connect', () => {
            // console.log('Socket connecté:', newSocket.id);
            fetchUnreadCounts();
        });

        newSocket.on('search-users-results', (results) => {
            setSearchResults(results);
            setSearchError(null);
        });

        newSocket.on('error', (error) => {
            setSearchError(error);
            setSearchResults([]);
        });

        newSocket.on('new-message', async (message) => {
            // Si nous sommes dans la conversation active, marquer comme lu immédiatement
            const activeConversationId = window.location.pathname.split('/').pop();
            if (activeConversationId === message.conversation_id) {
                await markConversationAsRead(message.conversation_id);
            } else {
                // Sinon, incrémenter les compteurs
                updateUnreadCount(message.conversation_id);
            }
            await fetchUnreadCounts();
        });
        
        newSocket.on('message-read', async ({ conversationId }) => {
            await markConversationAsRead(conversationId);
            await fetchUnreadCounts(); // Rafraîchir les compteurs après marquage
        });

        setSocket(newSocket);

        return () => {
            if (newSocket) {
                newSocket.close();
            }
        };
    }, [connectedUser]);

    const searchUsers = (searchTerm) => {
        if (socket) {
            const userId = localStorage.getItem('userId');
            socket.emit('search-users', { searchTerm, userId });
        }
    };

    const updateUnreadCount = (conversationId) => {
        setUnreadMessages(prev => prev + 1);
        setUnreadByConversation(prev => ({
            ...prev,
            [conversationId]: (prev[conversationId] || 0) + 1
        }));
    };

    const markConversationAsRead = async (conversationId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;
    
            const response = await fetch(`/api/messages/conversations/${conversationId}/unread`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
    
            if (response.ok) {
                // Mettre à jour le compteur de la conversation spécifique
                const previousCount = unreadByConversation[conversationId] || 0;
                
                // Mettre à jour le compteur global
                setUnreadMessages(prev => Math.max(0, prev - previousCount));
                
                // Mettre à jour les compteurs par conversation
                setUnreadByConversation(prev => ({
                    ...prev,
                    [conversationId]: 0
                }));
    
                // Émettre l'événement socket pour synchroniser avec les autres clients
                socket?.emit('message-read', { conversationId });
    
                // Rafraîchir tous les compteurs pour s'assurer de la synchronisation
                await fetchUnreadCounts();
            }
        } catch (error) {
            console.error('Erreur lors du marquage comme lu:', error);
        }
    };

    // Dans message.controller.js
    const sendMessage = async (conversationId, content, files = []) => {
        try {
            const formData = new FormData();
            formData.append('content', content);
            files.forEach(file => formData.append('files', file));

            const tempId = 'temp-' + Date.now();
            const tempMessage = {
                id: tempId,
                content: content,
                sender_id: localStorage.getItem('userId'),
                sender_name: localStorage.getItem('name'),
                conversation_id: conversationId,
                attachments: files.map(file => ({
                    id: Date.now() + Math.random(),
                    file_name: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    uploadProgress: 0
                })),
                created_at: new Date().toISOString()
            };

            setMessages(prev => [...prev, tempMessage]);

            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = event => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress(prev => ({ ...prev, [tempId]: progress }));
                }
            };

            xhr.open('POST', `/api/messages/conversations/${conversationId}/messages`);
            xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);

            xhr.onload = function() {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    // Émettre une seule fois via socket
                    socket?.emit('send-message', {
                        ...response,
                        conversation_id: conversationId
                    });
                } else {
                    setMessages(prev => prev.filter(msg => msg.id !== tempId));
                }
            };

            xhr.send(formData);
        } catch (error) {
            console.error('Erreur envoi message:', error);
        }
    };

    useEffect(() => {
        if (socket) {
            const handleNewMessage = (message) => {
                setMessages(prev => {
                    if (prev.some(msg => msg.id === message.id)) {
                        return prev;
                    }

                    if (message.sender_id !== localStorage.getItem('userId')) {
                        return [...prev, message];
                    }

                    return prev.map(msg => 
                        msg.id?.toString().startsWith('temp-') ? message : msg
                    );
                });
            };

            socket.on('receive-message', handleNewMessage);
            return () => socket.off('receive-message', handleNewMessage);
        }
    }, [socket]);

    const sendMessageWithAttachments = async (conversationId, content, files) => {
        try {
            const formData = new FormData();
            formData.append('content', content);
            files.forEach(file => {
                formData.append('files', file);
            });
    
            const token = localStorage.getItem('token');
            const messageId = Date.now(); // ID temporaire pour suivre le progrès
    
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    const progress = (e.loaded / e.total) * 100;
                    setUploadProgress(prev => ({
                        ...prev,
                        [messageId]: progress
                    }));
                }
            };
    
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    socket?.emit('send-message-with-attachments', response);
                    
                    // Nettoyer le progrès une fois terminé
                    setUploadProgress(prev => {
                        const newProgress = { ...prev };
                        delete newProgress[messageId];
                        return newProgress;
                    });
                }
            };
    
            xhr.open('POST', `/api/messages/conversations/${conversationId}/messages`);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.send(formData);
    
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message:', error);
            throw error;
        }
    };

    return (
        <MessageContext.Provider value={{ 
            socket, 
            messages, 
            setMessages, 
            searchUsers,
            searchResults,
            setSearchResults,
            searchError,
            setSearchError,
            unreadMessages,
            unreadByConversation,
            fetchUnreadCounts,
            markConversationAsRead,
            sendMessageWithAttachments,
            sendMessage,
            uploadProgress,
        }}>
            {children}
        </MessageContext.Provider>
    );
};