import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaTwitter, FaTiktok, FaSnapchat, FaPinterest, FaLinkedin, FaYoutube } from 'react-icons/fa6';
import Accordion from 'react-bootstrap/Accordion';
import { sitesApi } from '../../api/sites.api';

const SiteInfoReseauSociaux = ({ site, activeID, fromPortal }) => {
    const [socialLinks, setSocialLinks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    const [newLink, setNewLink] = useState({ site_id: site.id, network_type: '', network_url: '' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [localReload, setLocalReload] = useState(false);

    const networkOptions = [
        { label: "Facebook", value: 'facebook', icon: <FaFacebook className="text-blue-600" /> },
        { label: "Instagram", value: 'instagram', icon: <FaInstagram className="text-gradient-to-r from-pink-500 to-yellow-500" /> },
        { label: "Twitter", value: 'twitter', icon: <FaTwitter className="text-blue-400" /> },
        { label: "Tiktok", value: 'tiktok', icon: <FaTiktok className="text-black" /> },
        { label: "Snapchat", value: 'snapchat', icon: <FaSnapchat className="text-yellow-400" /> },
        { label: "Pinterest", value: 'pinterest', icon: <FaPinterest className="text-red-600" /> },
        { label: "Linkedin", value: 'linkedin', icon: <FaLinkedin className="text-blue-700" /> },
        { label: "Youtube", value: 'youtube', icon: <FaYoutube className="text-red-500" /> }
    ];

    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchSocialLinks = async () => {
            const site_id = site.id;
            try {
                setIsLoading(true);
                const response = await sitesApi.getSocialLinks(site_id, token);
                setSocialLinks(response);
            } catch (error) {
                Swal.fire("Erreur", "Impossible de récupérer les liens sociaux", "error");
            } finally {
                setIsLoading(false);
            }
        };

        fetchSocialLinks();
    }, [activeID, token, site.id, localReload]);

    useEffect(() => {

    }, [localReload]);

    const handleAddClick = () => {
        setNewLink({ site_id: site.id, network_type: '', network_url: '' });
        setShowModal(true);
        setEditMode(false);
    };

    const handleEditClick = (link) => {
        setNewLink(link);
        setSelectedLink(link);
        setShowModal(true);
        setEditMode(true);
    };

    const handleSaveClick = async () => {
        if (newLink.network_type && newLink.network_url) {
            try {
                let response;
                if (editMode) {
                    response = await sitesApi.saveSocialLink(newLink, token);
                    if (response.success) {
                        setSocialLinks(prev =>
                            prev.map(item =>
                                item.network_type === newLink.network_type ? newLink : item
                            )
                        );
                        Swal.fire("Succès", "Lien modifié avec succès", "success");
                    }
                } else {
                    response = await sitesApi.saveSocialLink(newLink, token);
                    if (response.success) {
                        setSocialLinks(prev => [...prev, response.data]);
                        Swal.fire("Succès", "Lien ajouté avec succès", "success");
                    }
                }
                if (!response.success) {
                    Swal.fire("Erreur", response.message || "Une erreur est survenue", "error");
                }
                if(fromPortal) {
                    setLocalReload(prev => !prev);
                }
                setShowModal(false);
            } catch (error) {
                Swal.fire("Erreur", "Impossible d'enregistrer le lien social", "error");
            }
        } else {
            Swal.fire("Attention", "Veuillez remplir tous les champs", "warning");
        }
    };

    const handleCancelClick = () => {
        setShowModal(false);
    };

    const handleDropdownSelect = (type) => {
        if (!editMode) {
            setNewLink({ ...newLink, network_type: type });
        }
        setDropdownOpen(false);
    };

    return (
        <React.Fragment>
            <Accordion.Header>Lien vers les réseaux sociaux</Accordion.Header>
            <Accordion.Body>
                {isLoading ? (
                    <p>Chargement...</p>
                ) : socialLinks.length === 0 ? (
                    <p>Pas de liens enregistrés</p>
                ) : (
                    <ul className="flex flex-wrap flex-col gap-2 p-0 w-full">
                        {socialLinks.map((link, index) => (
                            <li key={index} className="flex flex-wrap items-center justify-between gap-3 border-b border-b-gray-200">
                                <div className="flex gap-3 items-center">
                                    <span className="text-2xl">
                                        {networkOptions.find((option) => option.value === link.network_type)?.icon}
                                    </span>
                                    <p className="text-xl/2 text-gray-700">{link.network_url}</p>
                                </div>
                                <button
                                    className="p-2 text-blue-600 hover:underline"
                                    onClick={() => handleEditClick(link)}
                                >
                                    Modifier
                                </button>
                            </li>
                        ))}
                    </ul>
                )}

                <button
                    className="p-2 mt-4 bg-blue-600 text-white rounded hover:bg-blue-700"
                    onClick={handleAddClick}
                >
                    Ajouter
                </button>

                {/* Modal */}
                <Modal show={showModal} onHide={handleCancelClick}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editMode ? "Modifier le lien" : "Ajouter un lien"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex flex-col gap-3">
                            <div className="relative">
                                <button
                                    className={`p-2 border rounded flex items-center justify-between w-full ${editMode ? "cursor-not-allowed opacity-50" : ""}`}
                                    onClick={() => !editMode && setDropdownOpen(!dropdownOpen)}
                                    disabled={editMode}
                                >
                                    {newLink.network_type || "Sélectionner un type"}
                                    <span className="ml-2">▼</span>
                                </button>
                                {dropdownOpen && (
                                    <ul className="absolute left-0 w-full mt-2 bg-white border rounded shadow z-10 max-h-48 overflow-y-auto">
                                        {networkOptions.map((option, index) => {
                                            const isDisabled = socialLinks.some(
                                                (link) => link.network_type === option.value
                                            );
                                            return (
                                                <li
                                                    key={index}
                                                    className={`flex items-center gap-3 p-2 ${isDisabled ? "text-gray-400 cursor-not-allowed" : "hover:bg-gray-100 cursor-pointer"}`}
                                                    onClick={() => !isDisabled && handleDropdownSelect(option.value)}
                                                >
                                                    {option.icon}
                                                    <span>{option.label}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </div>

                            <input
                                type="url"
                                placeholder="Entrez l'URL"
                                className="p-2 border rounded"
                                value={newLink.network_url}
                                onChange={(e) => setNewLink({ ...newLink, network_url: e.target.value })}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancelClick}>
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={handleSaveClick}>
                            {editMode ? "Modifier" : "Enregistrer"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Accordion.Body>
        </React.Fragment>
    );
};

export default SiteInfoReseauSociaux;
