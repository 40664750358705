import { useEffect, useState } from "react";
import beauticianApi from "../../../api/beautician.api";
import abonnementApi from "../../../api/abonnement.api";
import { Modal, Button, Table, Form } from "react-bootstrap";
import { showSuccessMessage, showWarningMessage } from "../../../utils/messageHelper";
import offreApi from "../../../api/offre.api";
import emailApi from "../../../api/email.api";
import smsApi from "../../../api/sms.api";
import { BASE_URL } from "../../../utils/constants";

const AttributeAbonnementModal = ({ showModal, setShowModal, setReloadTrigger }) => {
    const [beauticians, setBeauticians] = useState([]);
    const [filteredBeauticians, setFilteredBeauticians] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showSubModal, setShowSubModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState("");

    const handleClose = () => {
        setReloadTrigger(prev => !prev);
        setShowModal(false);
    };

    const fetchAllBeauticians = async () => {
        const res = await beauticianApi.getAllAvailableBeauticians();
        const abonnements = await abonnementApi.getAllAbonnementByType(localStorage.getItem('token'), 'all');
        const unsubscribedUsers = [];

        if (Array.isArray(res.data)) {
            res.data.forEach(element => {
                if (!abonnements.data.some((obj) => obj.user_id === element.user_id)) {
                    unsubscribedUsers.push(element);
                }
            });
        }
        setBeauticians(unsubscribedUsers);
        setFilteredBeauticians(unsubscribedUsers);
    };

    useEffect(() => {
        fetchAllBeauticians();
    }, []);

    // Filtrer les résultats en fonction du terme de recherche
    useEffect(() => {
        const filtered = beauticians.filter(item =>
            item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBeauticians(filtered);
    }, [searchTerm, beauticians]);

    const openSubModal = (user) => {
        setSelectedUser(user);
        setSelectedPlan("");
        setShowSubModal(true);
    };

    const handleConfirmAbonnement = async () => {
        if (!selectedPlan) {
            showWarningMessage("Veuillez sélectionner la durée de l'abonnement");
            return;
        }

        const req_offre = await offreApi.getAllOffer();
        let offre_classique = Array.isArray(req_offre) ? req_offre.find((obj) => obj.name.toLowerCase() === 'classique') : null;
        if (!offre_classique) {
            showWarningMessage("L'offre est introuvable!");
            return;
        }

        await abonnementApi.attributeSubscriptionToBeautician(localStorage.getItem('token'), selectedUser.user_id, parseInt(selectedPlan), offre_classique.id);
        showSuccessMessage(`Abonnement attribué avec succès`);

        setShowSubModal(false);
        setReloadTrigger(prev => !prev);
        try {

            await emailApi.sendEmailNotification(
                selectedUser.email,
                `Abonnement de ${selectedPlan} mois attribué`,
                selectedUser.first_name + ' ' + selectedUser.last_name,
                `L'equipe Planibeauty vous informe que vous avez reçu un abonnement de <b>${selectedPlan} mois</b> sur l'offre <b>classique</b>. 
            Nous vous invitons completer votre profil : <b>ajouter vos préstations</b> et <b>gérér vos disponibilités</b>. 
            Vous pouvez accéder à votre tableau de bord en cliquant sur le lien ci-dessous:`,
                `${BASE_URL}/beautician-dashboard`,
                "Accéder au tableau de bord"
            );
        } catch (error) {
            console.log(error);
        }

        try {

            await smsApi.sendSmsNotification(
                selectedUser.phone,
                "Vous avez reçu un abonnement de " + selectedPlan + " mois sur l'offre classique. Veuillez completer votre profil (ajouter vos préstations et gérer vos disponibilités).\n\nRDV & messages sur https://www.planibeauty.fr "
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {/* Modal Principal */}
            <Modal show={showModal} size="lg" onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Attribuer un abonnement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Barre de recherche */}
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Rechercher par nom ou email..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>

                    <div className="overflow-y-auto max-h-[50vh]">

                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom et prénoms</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-auto">
                                {filteredBeauticians.length > 0 ? (
                                    filteredBeauticians.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td>{item.first_name} {item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => openSubModal(item)}>
                                                    Attribuer
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4} className="text-center">Aucun enregistrement trouvé!</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Fermer</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de sélection d'abonnement */}
            <Modal
                show={showSubModal}
                onHide={() => setShowSubModal(false)}
                backdropClassName="custom-backdrop"
                centered
                style={{ zIndex: 1056 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Attribuer un abonnement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <strong>Nous allons attribuer un abonnement sur l'offre classique à </strong>
                        {selectedUser?.first_name} {selectedUser?.last_name}
                    </p>
                    <Form.Group>
                        <Form.Label>Sélectionnez la durée :</Form.Label>
                        <Form.Select value={selectedPlan} onChange={(e) => setSelectedPlan(e.target.value)}>
                            <option value="">-- Choisissez la durée --</option>
                            <option value="1">1 mois</option>
                            <option value="2">2 mois</option>
                            <option value="6">6 mois</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSubModal(false)}>Annuler</Button>
                    <Button variant="success" onClick={handleConfirmAbonnement}>Confirmer</Button>
                </Modal.Footer>
            </Modal>

            {/* Style pour assombrir le backdrop */}
            <style jsx>{`
                .custom-backdrop {
                    background-color: rgba(0, 0, 0, 0.75) !important;
                }
            `}</style>
        </>
    );
};

export default AttributeAbonnementModal;