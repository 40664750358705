import React, { useEffect, useState } from "react"
import MySpinner from "../../../components/loader/Spinner"
import { Table, Pagination } from "react-bootstrap"
import beauticianApi from "../../../api/beautician.api"
import appointmentApi from "../../../api/appointement.api"
import ShowBeauticianPaymentStatus from "./ShowBeauticianPaymentStatus"
import { ChevronLeft, ChevronRight } from "lucide-react"

const SuiviDesProfessionnels = () => {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedBeautician, setSelectedBeautician] = useState({})

    const [filter, setFilter] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        thisWeek: false
    })
    const [pro, setPro] = useState([])
    const [limitPerPage, setLimitPerPage] = useState(10)
    const [trigger, setTrigger] = useState(false)
    const [pages, setPages] = useState(1)
    const [maxPages, setMaxPages] = useState(0)

    const status = {
        unpayed: "Non payé",
        payed: "Payé",
        upcoming: "À venir"
    }

    const fetchPro = async () => {
        setLoading(true)
        const res = await beauticianApi.getAllBeauticianWithAppointmentsInfo(localStorage.getItem('token'))
        if (res.success) {
            const pro = await Promise.all(
                res.data.map(async (p) => {
                    let filteredResponse
                    const response = await appointmentApi.getAppointmentByBeautician(p.id)
                    const beautician_payement = (await beauticianApi.getPaymentStatus(p.id)).data || []

                    const payment_status =
                        filter.month >= new Date().getMonth() + 1 && filter.year >= new Date().getFullYear() ?
                            "upcoming" :
                            beautician_payement.length > 0 &&
                            beautician_payement.filter(el => el.month === filter.month && el.year === filter.year).length > 0 ?
                                beautician_payement.filter(el => el.month === filter.month && el.year === filter.year)[0].status : "unpayed"

                    const filterDate = `${filter.year}-${filter.month < 10 ? "0" : ""}${filter.month}`

                    if (filter.thisWeek) {
                        const activeDate = new Date()
                        const monday = activeDate.getDate() - activeDate.getDay() + 1
                        const sunday = activeDate.getDate() + (7 - activeDate.getDay())

                        const week = {
                            start: new Date(`${filterDate}-${monday}T21:00:00.000Z`),
                            end: new Date(`${filterDate}-${sunday}T21:00:00.000Z`)
                        }

                        filteredResponse = response.filter(
                            (r) =>
                                r.appointment_date.startsWith(filterDate) &&
                                new Date(r.appointment_date) >= week.start &&
                                new Date(r.appointment_date) <= week.end
                        )
                    } else {
                        filteredResponse = response.filter((r) => r.appointment_date.startsWith(filterDate))
                    }

                    const all_appointment = filteredResponse.filter((r) => r.status !== "cancelled")
                    const completed_appointment = all_appointment.filter(
                        (a) =>
                            a.status === "completed" ||
                            (a.status === "confirmed" && new Date(a.appointment_date) <= new Date())
                    )
                    const upcoming_appointment = all_appointment.length - completed_appointment.length

                    const payments = all_appointment.reduce(
                        (acc, val) => {
                            if (val.payment_type === "onsite") {
                                acc.onsite += parseFloat(val.total_prices)
                            } else if (val.payment_type === "deposit") {
                                acc.acompte += parseFloat(val.payed)
                                acc.onsite += parseFloat(val.total_prices) - parseFloat(val.payed)
                            } else if (val.payment_type === "full") {
                                acc.online += parseFloat(val.payed)
                            }

                            acc.total += parseFloat(val.total_prices)

                            return acc
                        },
                        { onsite: 0, acompte: 0, online: 0, total: 0, status: payment_status, date: { month: filter.month, year: filter.year }}
                    )

                    return {
                        ...p,
                        all_appointment: all_appointment.length,
                        completed_appointment: completed_appointment.length,
                        upcoming_appointment,
                        payments
                    }
                })
            )

            setPro(pro)
            setMaxPages(Math.ceil(pro.length / limitPerPage)) // Calculer le nombre total de pages
        }

        setLoading(false)
    }

    const handleRowClick = (beautician) => {
        setShowModal(true)
        setSelectedBeautician(beautician)
    }

    useEffect(() => {
        fetchPro()
    }, [filter, trigger])

    useEffect(() => {
        setMaxPages(Math.ceil(pro.length / limitPerPage)) // Recalculer les pages si limitPerPage change
        setPages(1) // Réinitialiser la page active si la limite par page change
    }, [limitPerPage])

    // Filtrer les professionnels en fonction de la page active
    const displayedPro = pro.slice((pages - 1) * limitPerPage, pages * limitPerPage)

    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ]

    const handleFilterChange = (isYear, step) => {
        isYear ?
            setFilter(prev => ({ ...prev, year: prev.year + step })):
            setFilter(prev => {
                let nextMonth = prev.month + step
                let nextYear = prev.year

                if(nextMonth < 1) {
                    nextYear --
                    nextMonth = 12
                }
                else if(nextMonth > 12) {
                    nextYear ++
                    nextMonth = 1
                }
                
                return { year: nextYear, month: nextMonth }
            })
    }

    return (
        <main className="min-h-[72vh]">
            {loading ? (
                <div className="w-full">
                    <MySpinner height={"60vh"} />
                </div>
            ) : (
                <div>
                    <div className="p-6 bg-gray-100 rounded-lg">
                        <header>
                            <h2 className="text-2xl font-bold text-gray-800 mb-6">Suivi des professionnels</h2>
                            <div className="flex items-center gap-2">
                                <div className="flex items-center">
                                    <button className="cursor-pointer" onClick={() => handleFilterChange(false, -1)}><ChevronLeft size="24" /></button>

                                    <button>{months[filter.month - 1]}</button>

                                    <button className="cursor-pointer" onClick={() => handleFilterChange(false, 1)}><ChevronRight size="24" /></button>
                                </div>

                                <div className="flex items-center">
                                    <button className="cursor-pointer" onClick={() => handleFilterChange(true, -1)}><ChevronLeft size="24" /></button>

                                    <button>{filter.year}</button>

                                    <button className="cursor-pointer" onClick={() => handleFilterChange(true, 1)}><ChevronRight size="24" /></button>
                                </div>
                        
                                <span className="ms-2 my-2 flex flex-row items-center gap-2">
                                    <input
                                        id="thisweek"
                                        type="checkbox"
                                        checked={filter.thisWeek}
                                        onChange={(e) => setFilter({ ...filter, thisWeek: e.target.checked })}
                                    />
                                    <label htmlFor="thisweek">Cette semaine</label>
                                </span>
                            </div>
                        </header>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nom commercial</th>
                                    <th>RDV total</th>
                                    <th>RDV terminé</th>
                                    <th>RDV à venir</th>
                                    <th>Paiment en ligne</th>
                                    <th>Paiment avec acompte</th>
                                    <th>Paiment sur site</th>
                                    <th>Paiment total</th>
                                    <th>Status de paiment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedPro.map((p, index) => (
                                    <tr
                                        key={p.id}
                                        className="cursor-pointer"
                                        onClick={() => handleRowClick(p)}
                                    >
                                        <td>{(pages - 1) * limitPerPage + index + 1}</td>
                                        <td>{p.business_name}</td>
                                        <td>{p.all_appointment}</td>
                                        <td>{p.completed_appointment}</td>
                                        <td>{p.upcoming_appointment}</td>
                                        <td>{p.payments.online > 0 ? p.payments.online + " €" : 0}</td>
                                        <td>{p.payments.acompte > 0 ? p.payments.acompte + " €" : 0}</td>
                                        <td>{p.payments.onsite > 0 ? p.payments.onsite + " €" : 0}</td>
                                        <td>{p.payments.total > 0 ? p.payments.total + " €" : 0}</td>
                                        <td>{status[p.payments.status]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className="flex items-center justify-center">
                        <div className="flex items-center gap-2 pt-4 text-lg font-semibold">
                            <span>Limites par pages</span>
                            <select
                                onChange={(e) => setLimitPerPage(Number(e.target.value))}
                                defaultValue={limitPerPage}
                            >
                                {[10, 15, 20, 25, 30, 100].map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <Pagination>
                            <Pagination.First onClick={() => setPages(1)} disabled={pages === 1} />
                            <Pagination.Prev onClick={() => setPages(pages - 1)} disabled={pages === 1} />
                            {Array.from({ length: maxPages }, (_, index) => index + 1).map((item, index) => (
                                <Pagination.Item
                                    key={index}
                                    active={item === pages}
                                    onClick={() => setPages(item)}
                                >
                                    {item}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next onClick={() => setPages(pages + 1)} disabled={pages === maxPages} />
                            <Pagination.Last onClick={() => setPages(maxPages)} disabled={pages === maxPages} />
                        </Pagination>
                    </div>
                    <ShowBeauticianPaymentStatus
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        selectedBeautician={selectedBeautician}
                        setTrigger={setTrigger}
                    />
                </div>
            )}
        </main>
    )
}

export default SuiviDesProfessionnels