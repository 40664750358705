import tarif1 from '../../../../assets/tarifs/1.png';
import AbonnementCard from '../../../../pages/tarifs/AbonnementCard';

const EmptyAbonnement = () => {

    const classique = {
        title: "Formule Classic",
        price: "49",
        image: tarif1,
        features: [
            "Agenda en ligne",
            "Interface personnalisée",
            "Page professionnelle",
            "Moyen de paiement en ligne ou en espèces",
            "Possibilité d'acomptes",
            "Statistiques",
            "Priorité pour les formations"
        ],
        onClick: () => {
            window.location.href = "/beautician-dashboard/settings?active=abonnement&offre=classique";
        },
        is_dispo: true,
        m_auto: false,
        addButton: true
    };

    return (
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-6 md:space-y-0 md:space-x-6">
            {/* Carte d'abonnement */}
            <AbonnementCard {...classique} />

            {/* Conteneur vidéo locale */}
            <div className="w-full max-w-2xl">
                <div className="relative">
                    <video
                        className="w-full h-min-[60vh] rounded-lg shadow-lg"
                        src="/videos/demo.mp4" // Remplace par ton fichier vidéo
                        controls
                    />
                </div>
            </div>
        </div>
    );
};

export default EmptyAbonnement;
