import React, { useContext } from 'react';
import defaultProfile from '../../../assets/images/default-profile.png';
import { BASE_URL } from '../../../utils/constants';
import { TeleConseillerPortalContext } from '../../../context/TeleConseillerPortalContext';

import {
	MapPin,
	Mail,
	Phone,
	Edit2,
} from 'lucide-react';
import './TeleConseillerProfile.css';
import { useNavigate } from 'react-router-dom';
// import SocialLinkList from '../../common/SocialLinkList';

import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import Swal from 'sweetalert2';
import teleConseillerApi from '../../../api/tele_conseiller.api';


const renderSpecialities = () => {

	return <section className="profile-section-portal">
		<h2>Spécialités</h2>
	</section>
}

const TeleConseillerProfile = () => {
	const navigate = useNavigate();

	const { profileData, setReloadTrigger } = useContext(TeleConseillerPortalContext);
	const MySwal = withReactContent(Swal);

	const getImageUrl = (profilePhoto) => {
		if (!profilePhoto) return defaultProfile;
		try {
			return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
		} catch (error) {
			console.error('Error with image path:', error);
			return defaultProfile;
		}
	};

	const handleEditToggle = () => {
		navigate("/tele-conseiller-dashboard/settings");
	};

	const handleAvailabilityChange = (is_available, tele_conseiller_id) => {
		MySwal.fire({
			title: 'Confirmation',
			text: `Voulez-vous vraiment modifier la disponibilité ?`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Oui',
			cancelButtonText: 'Non',
		}).then(async (result) => {
			if (result.isConfirmed) {
				// Mettre à jour la disponibilité via une API ou une méthode
				await teleConseillerApi.updateAvailability(localStorage.getItem('token'), tele_conseiller_id, { is_available: !is_available });
				setReloadTrigger(prev => !prev);
			}
		});
	};

	if (!profileData) {
		return <div>Chargement du profil...</div>;
	}

	return (
		<div className="beautician-profile profile-container">
			<div className="flex justify-between w-full p-3 px-5">
				{
					<div className="availability-status">
						<ReactSwitch
							checked={profileData.is_available}
							onChange={() => handleAvailabilityChange(profileData.is_available, profileData.tele_conseiller_id)}
							offColor="#f87171" // Couleur désactivée
							onColor="#4ade80" // Couleur activée
							uncheckedIcon={false}
							checkedIcon={false}
							width={40} // Largeur du switch
							height={20} // Hauteur du switch
							handleDiameter={15}
						/> Disponibilité
					</div>}
				<button
					className="edit-profile-btn"
					onClick={handleEditToggle}
				>
					<Edit2 size={16} />
					<span>Modifier le profil</span>
				</button>
			</div>
			<div className="profile-header">
				<div className="profile-header-content">
					<div className="profile-avatar-wrapper">
						<div className="profile-avatar">
							<img
								src={getImageUrl(profileData.profile_photo)}
								alt="Profile"
								className="avatar-image"
							/>
						</div>
					</div>

					<div className="b-profile-information">
						<div className="profile-titles">
							<h1>{profileData.first_name} {profileData.last_name}</h1>
							<p>{profileData.business_name}</p>
							<p className="bio">{profileData.tele_conseiller_description}</p>
						</div>
					</div>
				</div>
			</div>

			<div className="profile-content">
				<div className="profile-main">

					{renderSpecialities()}

					<section className="profile-section-portal">
						<h2>Horaires d'ouverture</h2>
						<div className="schedule-grid">

						</div>
					</section>
				</div>

				<aside className="profile-sidebar">
					<div className="contact-info">
						<h2>Informations de contact</h2>

						<div className="contact-list">
							<div className="contact-item">
								<MapPin size={20} />
								<span>{profileData.address}</span>
							</div>
							<div className="contact-item">
								<Mail size={20} />
								<span>{profileData.email}</span>
							</div>
							<div className="contact-item">
								<Phone size={20} />
								<span>{profileData.phone}</span>
							</div>
						</div>
					</div>

					<section className="profile-section-portal">
						<h2>Liens vers mes réseaux sociaux</h2>
						{/* <SocialLinkList links={profileData.social_networks} /> */}
					</section>

					{/* <div className="availability-card">
						<h2>Catégorie de budget</h2>
						<div className="availability-status available">
							<Euro size={20} />
							<span>{profileData.budget_category === "moderate" ? "Modéré" : (profileData.budget_category === "premium" ? "Premium" : "Economique")}</span>
						</div>
					</div> */}
				</aside>
			</div>
		</div>
	);
};

export default TeleConseillerProfile;