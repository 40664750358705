import React, { useState, useRef, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ShowInfoClientModal from './ShowInfoClientModal';
import './Calendar.css';
import availabilityApi from '../../../../api/availability.api';
import blockedDatesApi from '../../../../api/blockedDates.api';
import { showErrorMessage } from '../../../../utils/messageHelper';

const CalendarComponent = ({ events, beautician_id }) => {

	const calendarRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [currentView, setCurrentView] = useState('timeGridDay');
	const [businessHours, setBusinessHours] = useState([]);
	const [backgroundEvents, setBackgroundEvents] = useState([]);
	const [slotMinTime, setSlotMinTime] = useState("07:00");
	const [slotMaxTime, setSlotMaxTime] = useState("22:00");
	const [views, setViews] = useState("timeGridDay,dayGridMonth");
	const [blockedDates, setBlockedDates] = useState([]);


	const defaultDays = [
		{ day: 1, field: "monday" },
		{ day: 2, field: "tuesday" },
		{ day: 3, field: "wednesday" },
		{ day: 4, field: "thursday" },
		{ day: 5, field: "friday" },
		{ day: 6, field: "saturday" },
		{ day: 0, field: "sunday" },
	];

	const fetchBlockedDates = async (id) => {
		try {
			const response = await blockedDatesApi.getBlockedDates(id);
			if (response && Array.isArray(response)) {

				const blockedEvents = [];

				response.forEach(block => {
					const { date_start, date_end, start_time, end_time, reason } = block;
					const startDate = date_start.split("T")[0]; // YYYY-MM-DD
					const endDate = date_end.split("T")[0];

					const isSingleDay = startDate === endDate;

					// 🔴 Cas 1 : Journées totalement fermées (start_time et end_time null)
					if (!start_time && !end_time) {
						blockedEvents.push({
							start: startDate,
							end: isSingleDay ? startDate : endDate,
							allDay: true,
							display: "background",
							classNames: ["fully-blocked-day"],
							color: "#ff4d4d",
							title: reason || "Fermé",
						});
					}

					// 🟠 Cas 2 : Heures spécifiques fermées chaque jour entre start_date et end_date
					if (start_time && end_time) {
						let currentDate = new Date(startDate);
						const finalDate = new Date(endDate);

						while (currentDate <= finalDate) {
							let start = currentDate.setHours(start_time.split(":")[0], start_time.split(":")[1]);
							let end = currentDate.setHours(end_time.split(":")[0], end_time.split(":")[1]);

							blockedEvents.push({
								start: start,
								end: end,
								display: "background",
								classNames: ["blocked-hours"],
								color: "#ffcccb",
								title: reason || "Fermé",
							});

							// Passer au jour suivant
							currentDate.setDate(currentDate.getDate() + 1);
						}
					}
				});
				setBlockedDates(blockedEvents);
			}
		} catch (error) {
			console.log(error);
			showErrorMessage("Impossible de charger les jours bloqués.");
		}
	};

	const processEventsWithColors = (events) => {
		if (!events || events.length === 0) return [];
	
		// Trier les événements par date et heure de début
		const sortedEvents = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
	
		let lastEnd = null;
		let toggleColor = false;
	
		return sortedEvents.map((event, index) => {
			toggleColor = !toggleColor;
	
			return {
				...event,
				classNames: [toggleColor ? "event-blue-dark" : "event-blue-light"],
			};
		});
	};

	const adjustTime = (time, adjustment) => {
		const [hours, minutes] = time.split(":").map(Number);
		const date = new Date();
		date.setHours(hours + adjustment, minutes, 0, 0);
	
		return date.toTimeString().slice(0, 5);
	};

	const fetchAvailability = async () => {
		const res = await availabilityApi.getBeauticianAvailability(beautician_id);
		if (res && Array.isArray(res)) {
			const availability = defaultDays.map((defaultDay) => {
				const existingData = res.find(
					(item) => item.day.toLowerCase() === defaultDay.field
				);
				return {
					...defaultDay,
					start_time: existingData?.start_time?.slice(0, 5) || null,
					break_start: existingData?.break_start?.slice(0, 5) || null,
					break_end: existingData?.break_end?.slice(0, 5) || null,
					end_time: existingData?.end_time?.slice(0, 5) || null,
				};
			});

			// Récupérer les heures min et max
			const allStartTimes = availability
				.map((day) => day.start_time)
				.filter(Boolean)
				.sort();
			const allEndTimes = availability
				.map((day) => day.end_time)
				.filter(Boolean)
				.sort();

				const minTime = allStartTimes.length > 0 ? adjustTime(allStartTimes[0], -1) : "06:00";
				const maxTime = allEndTimes.length > 0 ? adjustTime(allEndTimes[allEndTimes.length - 1], 1) : "23:00";

			setSlotMinTime(minTime);
			setSlotMaxTime(maxTime);

			setBusinessHours(
				availability
					.filter((day) => day.start_time && day.end_time)
					.map((day) => ({
						daysOfWeek: [day.day],
						startTime: day.start_time,
						endTime: day.end_time,
					}))
			);

			const background_events = availability.flatMap((day) => [
				...(day.start_time ? [{
					daysOfWeek: [day.day],
					startTime: "00:00",
					endTime: day.start_time,
					display: "background",
					rendering: "background",
					overlap: false,
					classNames: ["closed-hours"],
					color: "#d3d3d3",
				}] : []),

				...(day.end_time ? [{
					daysOfWeek: [day.day],
					startTime: day.end_time,
					endTime: "23:59",
					display: "background",
					rendering: "background",
					overlap: false,
					classNames: ["closed-hours"],
					color: "#d3d3d3",
				}] : []),

				...(day.break_start && day.break_end ? [{
					daysOfWeek: [day.day],
					startTime: day.break_start,
					endTime: day.break_end,
					display: "background",
					rendering: "background",
					overlap: false,
					classNames: ["lunch-break"],
					color: "#ffcccb",
					title: "Pause déjeuner",
				}] : []),
			]);

			setBackgroundEvents(background_events);
		}
	};


	useEffect(() => {
		fetchAvailability();
		fetchBlockedDates(beautician_id);
	}, []);

	useEffect(() => {
		const updateView = () => {

			if (window.innerWidth >= 768) {
				setViews("timeGridDay,timeGridWeek,dayGridMonth");
				setCurrentView("timeGridWeek");
			} else {
				setViews("timeGridDay,dayGridMonth");
				setCurrentView("timeGridDay");
			}

			// Vérifier si le calendrier est monté et changer la vue
			if (calendarRef.current) {
				const calendarApi = calendarRef.current.getApi();
				calendarApi.changeView(window.innerWidth >= 768 ? "timeGridWeek" : "timeGridDay");
			}
		};

		updateView();

		window.addEventListener('resize', updateView);
		return () => window.removeEventListener('resize', updateView);
	}, []);

	const renderEventContent = (eventInfo) => (
		<div className="fc-event-title">{eventInfo.event.title}</div>
	);	

	const handleEventClick = (clickInfo) => {

		if (
			clickInfo.event.classNames.includes("closed-hours") ||
			clickInfo.event.classNames.includes("lunch-break") ||
			clickInfo.event.classNames.includes("fully-blocked-day") ||
			clickInfo.event.classNames.includes("blocked-hours")
		) {
			clickInfo.jsEvent.preventDefault(); // Bloque l'action
			return;
		}

		setSelectedEvent(clickInfo.event);
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedEvent(null);
	};

	return (
		<div className="calendar-container">
			<FullCalendar
				ref={calendarRef}
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
				initialView={currentView}
				headerToolbar={{
					left: 'prev,next today',
					center: 'title',
					right: views,
				}}
				locale="fr"
				businessHours={businessHours}
				events={[...backgroundEvents, ...processEventsWithColors(events), ...blockedDates]}
				eventClassNames={(eventInfo) => eventInfo.event.classNames}
				eventClick={handleEventClick}
				height="100%"
				slotMinTime={slotMinTime}
				slotMaxTime={slotMaxTime}
				slotDuration="00:30:00"
				buttonText={{
					today: "Aujourd'hui",
					month: 'Mois',
					week: 'Semaine',
					day: 'Jour',
				}}
				allDaySlot={false}
				eventTimeFormat={{
					hour: '2-digit',
					minute: '2-digit',
					hour12: false,
				}}
				nowIndicator={true}
				eventContent={renderEventContent}
				dayMaxEvents={2}
				eventMaxStack={1}
				moreLinkContent={(args) => (
					<span className="more-events">
						+{args.num} rdv
					</span>
				)}
				slotEventOverlap={true}
				navLinks={true}
				navLinkDayClick={(date) => {
					const calendarApi = calendarRef.current?.getApi();
					if (calendarApi) {
						calendarApi.changeView("timeGridDay", date); // Navigate to the selected date in Day View
					}
				}}
			/>
			{selectedEvent && (
				<ShowInfoClientModal
					showModal={showModal}
					handleCloseModal={handleCloseModal}
					selectedEvent={selectedEvent}
				/>
			)}
		</div>
	);
};

export default CalendarComponent;