// src/utils/functions.js
import { API_URL, BASE_URL } from "./constants";
import beauticianApi from "../api/beautician.api";
import defaultProfile from '../assets/images/default-profile.png';

const tokenValid = async (token) => {
    if (!token) return false;

    try {
        const response = await fetch(`${API_URL}/users/verify-token-validity`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return false;
        }

        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

const getBeauticianIdBySlug = async (slug) => {
    try {
        const response = await beauticianApi.getBeauticianBySlug(slug, true);
        if (response.success) {
            return response.data.id;
        }
    } catch (error) {
        console.log(error);
    }
    return null;
}

const groupPrestationsByCategoryId = (data) => {
    const grouped = data.reduce((result, item) => {
        const categoryId = item.category_id;
        const categoryName = item.category_name;

        if (!result[categoryId]) {
            result[categoryId] = {
                category_id: categoryId,
                category_name: categoryName,
                items: []
            };
        }

        result[categoryId].items.push(item);

        return result;
    }, {});

    const result = Object.values(grouped).map((el) => (
        {...el, items: el.items.sort((a, b) => a.display_order - b.display_order)}
    ));

    return result;
};

function formatMoneyNumber(value, separator = ' ') {
    value = Number.parseFloat(value);
    return value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

const deleteProfileImage = async (imageName) => {
    try {
        const response = await fetch(`${API_URL}/delete-image-file`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ image_name: imageName }),
        });

        if (response.success) {
            return await response.json();
        } else {
            const error = await response.json();
            return error.message;
        }
    } catch (error) {
        console.error('Error during deleting file:', error);
        return error;
    }
};

const getProfileImageUrl = (profilePhoto) => {
    if (!profilePhoto) return defaultProfile;
    try {
        return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
    } catch (error) {
        console.error('Error with image path:', error);
        return defaultProfile;
    }
};

const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hours, minutes] = timeString.split(':').map(Number);
    return `${hours}h${minutes !== 0 ? minutes : ''}`;
}

function addMinutesToTime(time, minutesToAdd) {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setMinutes(date.getMinutes() + minutesToAdd);
    return date.toTimeString().slice(0, 5);
}

const getPaymentTypeString = (payment_type) => {
    const paymentTypes = {
        onsite: "Sur site",
        full: "Complet",
        deposit: "Accompte"
    };
    return paymentTypes[payment_type] || 'Inconnu';
}

const validEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const validatePhoneNumber = (phoneNumber) => {
    // Nettoyer le numéro en enlevant tous les caractères non numériques
    const cleanedNumber = phoneNumber.replace(/\D/g, '');
    
    // Vérifier que le numéro a entre 8 et 15 chiffres
    return /^\d{8,15}$/.test(cleanedNumber);
};

const formatFrenchPhoneNumberForBrevo = (phoneNumber) => {
    // Supprime les espaces, tirets, parenthèses et points
    let cleanedNumber = phoneNumber.replace(/[\s-.()]/g, '');

    // Vérifie si le numéro commence par "+33" (déjà en format international)
    if (/^\+33\d{9}$/.test(cleanedNumber)) {
        return cleanedNumber; // Déjà bien formaté
    }

    // Vérifie si le numéro commence par "0" suivi de 9 chiffres (ex: 0612345678)
    if (/^0[1-9]\d{8}$/.test(cleanedNumber)) {
        return "+33" + cleanedNumber.slice(1); // Remplace le "0" par "+33"
    }

    return null; // Numéro invalide
};

// Nouvelles fonctions utiles pour le calendrier moderne
const isOverlapping = (event1, event2) => {
    const start1 = new Date(event1.start).getTime();
    const end1 = new Date(event1.end).getTime();
    const start2 = new Date(event2.start).getTime();
    const end2 = new Date(event2.end).getTime();
    return start1 < end2 && start2 < end1;
}

const sortEventsByTime = (events) => {
    return events.sort((a, b) => new Date(a.start) - new Date(b.start));
}

export {
    tokenValid,
    getBeauticianIdBySlug,
    groupPrestationsByCategoryId,
    formatMoneyNumber,
    deleteProfileImage,
    getProfileImageUrl,
    formatTime,
    addMinutesToTime,
    getPaymentTypeString,
    validEmail,
    isOverlapping,
    sortEventsByTime,
    validatePhoneNumber,
    formatFrenchPhoneNumberForBrevo
};